import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { RoleDataSource } from '@people-first/data-sources';
import { FormStepTemplate } from '@people-first/templates';
import { stepsFactory } from './Role.steps';
import { useBreadcrumb } from '@people-first/hooks';
import { ContextVariant, useStaticProps } from '@people-first/shared';

const PageVariants = new ContextVariant(
    {
        new: {
            "breadcrumb.segment": "role.new_role",
            "form.title": "role.new_role",
        },
        update: {
            "breadcrumb.segment": "role.update_role",
            "form.title": "role.update_role",
        }
    }
)

export async function getStaticProps({ params }: any) {
    const { id } = params;
    const data = id !== "new"
        ? await RoleDataSource.get({ id }).catch(() => ({}))
        : {};
    return {
        variant: PageVariants.get([id, "update"]),
        steps: () => stepsFactory(
            data,
            // {
            //     "name": "Administrador",
            //     "status": true,
            //     "privileges": [
            //         "menu:*",
            //         "role:*",
            //         "organization:*",
            //         "user:*"
            //     ],
            //     "organizations": [
            //         "7dbe4bd6-f2ba-436c-bcb6-2d7a9ec70409",
            //         "dfaed803-0cde-4280-903f-34086e19bc5a",
            //         "2831118c-ac43-41f6-a4c2-57d543c90a96"
            //     ]
            // }
        )
    }
}

export function RoleForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { steps, variant } = (useStaticProps() as any)

    const gotoGrid = () => navigate('/role');

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.role'), "/role"],
        [t(variant.get("breadcrumb.segment")), ""],
        // [t('general.'), ""],
    ]);

    return (
        <>
            <FormStepTemplate
                formFactory={steps}
                width='100%'
                title={variant.get("form.title")}
                onBackNavigation={gotoGrid}
                afterSubmit={gotoGrid}
                dataSource={RoleDataSource} />
        </>
    );
}

export default {
    getStaticProps,
    component: RoleForm,
}