import Box from '../../atom/box/Box'
import IconButton from '../../atom/icon-button/IconButton'
import { } from '@mui/x-data-grid'
import { EditOutlined, LockOutlined, LockOpenOutlined } from '@mui/icons-material'


type ActionButtons = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  status?: string,
}

const ActionButtonsMenu = ({ onClick, status }: ActionButtons) => {
  return (
    <>
      <Box display='flex' justifyContent='space-evenly' >
        <Box marginX={2}>
          <IconButton
            disabled={status == '1' ? false : true}
            onClick={onClick}
            className='actionsMenu'
            color="primary"
          >
            <LockOutlined color='secondary' />
          </IconButton>
        </Box>
        <IconButton
          onClick={onClick}
          className='actionsMenu'
          disabled={status == '0' ? false : true}
          color="primary"
        >
          <LockOpenOutlined color='secondary' />
        </IconButton>
      </Box>
    </>
  );
}

export default ActionButtonsMenu

