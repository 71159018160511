
export class Classifier<T = unknown> extends Map<string, Array<T>> {
    constructor(protected selector: (item: T) => string) {
        super();
    }
    public push(item: T, ...items: Array<T>) {
        [item].concat(...items).forEach((_item) => {
            const key = this.selector(_item);
            if (!this.has(key)) this.set(key, new Array<T>());
            this.get(key)?.push(_item);
        });
    }
}