import { AvatarProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;
type TypeAvatar = {
    props: AvatarProps;
    style: Interpolation<{
        theme: Theme;
    }>;
}

const AvatarThemeVariants: TypeAvatar[] = [

]

export default AvatarThemeVariants

