import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
import type { } from '@mui/x-data-grid/themeAugmentation';
const { peopleFirst } = tokens;

const RootDataGridTheme: Components<Omit<Theme, 'components'>> = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '.MuiDataGrid-cell--withRenderer': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '.MuiDataGrid-loadingIcon': {
          opacity: 'inherit !important',
        },
        "& .MuiSvgIcon-root": {
          backgroundColor: 'none',
        },
        '.MuiDataGrid-sortIcon': {
          opacity: 'inherit !important',
        },
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible !important',
          width: 'auto !important',
        },
        '.MuiDataGrid-columnHeaderTitle ': {
          fontWeight: 600,
          fontSize: '12px',
          marginRight: '10px'
        },
        borderBlockStart: 'none',
        borderBlockEnd: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        '& .MuiDataGrid-menuIcon': {
          marginRight: '10px',
          backgroundColor: 'none',
          visibility: 'visible !important',
          width: 'auto !important'

        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none"
        },
        '.MuiDataGrid-columnHeader:focus-within, .css-zkawhz-MuiDataGrid-root .MuiDataGrid-cell:focus-within ': {
          outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
        {
          outline: 'none !important',
        },

      },
    },
  },
};

export default RootDataGridTheme;
export { RootDataGridTheme };
