import { isAllOf, isAnyOf, isOneOf } from "./helpers";
import { WithPermissionOptions } from "./interfaces";
import { PermissionProvider } from "./PermissionProvider";


const countPermissions =
  (service: Map<string, boolean>) => (
    (count: number, permission: string) => count + Number(service.has(permission))
  );

export const PermissionService = ((): PermissionProvider => {
  const permissions = new Map<string, boolean>();

  return {
    canAccess: (config: WithPermissionOptions) => {
      if (isOneOf(config)) {
        return config.oneOf.reduce(countPermissions(permissions), 0) === 1;
      } else if (isAnyOf(config)) {
        return config.anyOf.some(
          (permission: string) => permissions.has(permission)
        );
      } else if (isAllOf(config)) {
        return (
          config.allOf.reduce(countPermissions(permissions), 0) === config.allOf.length
        );
      } else {
        return false;
      }
    },
    add: (...permission: Array<string>) => {
      return permission.forEach((id) => permissions.set(id, true));
    },
    get privileges() {
      return Array.from(permissions.keys());
    },
  };
})();
