import { createTheme } from '@mui/material';
import TextTheme from './TextTheme';
import ColorTheme from './ColorTheme';
import SpacementTheme from './SpacementTheme';

import RootAvatarTheme from './theme-root-component/RootAvatarTheme';
import BreakpointsTheme from './BreakpointsTheme'
import RootAlertTheme from './theme-root-component/RootAlertTheme';
import RootAlertTitleTheme from './theme-root-component/RootAlertTitleTheme';
import RootMuiInputAdornment from './theme-root-component/RootInputAdornment';
import RootTextFieldTheme from './theme-root-component/RootTextFieldTheme';
import RootButtonTheme from './theme-root-component/RootButtonTheme';
import RootTooltipTheme from './theme-root-component/RootTooltipTheme';
import RootDialogContextText from './theme-root-component/RootDialogContextTextTheme';
import RootDialogTitle from './theme-root-component/RootDialogTitleTheme';
import RootChipTheme from './theme-root-component/RootChipTheme';
import RootDataGridTheme from './theme-root-component/RootDatagridTheme';
import RootIconButtonTheme from './theme-root-component/RootIconButtonTheme';
import RootFormControlLabel from './theme-root-component/RootFormControlLabelTheme';
import RootFormLabel from './theme-root-component/RootFormLabelTheme';
import RootTextTheme from './theme-root-component/RootTextTheme'
import RootMenuItemTheme from './theme-root-component/RootMenuItem';
const { palette } = ColorTheme;
const { typography } = TextTheme;
const { breakpoints } = BreakpointsTheme
const { MuiAlert } = RootAlertTheme;
const { MuiAlertTitle } = RootAlertTitleTheme;
const { MuiInputAdornment } = RootMuiInputAdornment;
const { MuiTextField } = RootTextFieldTheme;
const { MuiButton } = RootButtonTheme;
const { MuiTooltip } = RootTooltipTheme
const { MuiAvatar } = RootAvatarTheme;
const { MuiChip } = RootChipTheme;
const { MuiDataGrid } = RootDataGridTheme
const { MuiIconButton } = RootIconButtonTheme
const { MuiDialogContentText } = RootDialogContextText
const { MuiDialogTitle } = RootDialogTitle
const { MuiMenuItem } = RootMenuItemTheme
const { MuiFormControlLabel } = RootFormControlLabel
const { MuiFormLabel } = RootFormLabel
const { MuiTypography } = RootTextTheme

export const DefaultTheme = createTheme({
    palette,
    typography,
    breakpoints,
    components: {
        MuiAlert,
        MuiAlertTitle,
        MuiInputAdornment,
        MuiTextField,
        MuiButton,
        MuiAvatar,
        MuiTooltip,
        MuiDialogContentText,
        MuiDialogTitle,
        MuiChip,
        MuiDataGrid,
        MuiIconButton,
        MuiMenuItem,
        MuiFormControlLabel,
        MuiFormLabel,
        MuiTypography,
    },
});
