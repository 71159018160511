import { useRef } from "react";
import { ReactiveDataSource } from "./types";

export function useReactiveDataSource<T>(initialValue: Array<T>): ReactiveDataSource<T> {
    const { current } = useRef({
        value: initialValue,
        events: new EventTarget()
    })

    const next = (value: Array<T>) => {
        current.value = value;
        current.events.dispatchEvent(new CustomEvent("next", { detail: value }));
    };

    return {
        get items() { return current.value; },
        next,
        remove: async (event) => {
            const removeId = event.detail?.row.id;
            if (removeId) { next(current.value.filter(({ id }: any) => id !== removeId)) }
            return event;
        },
        find: async (options) => {
            const items = current.value;
            return {
                items,
                resultSizeEstimate: items.length
            }
        },
        subscribe: (handler: any) => {
            current.events.addEventListener("next", handler, true);
            return () => current.events.removeEventListener("next", handler, true);
        },
    };
}

