import { Button, ButtonWithPermission } from '@people-first/atoms';
import { ListPageTemplate } from '@people-first/templates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataGrid, useDataGrid } from '@people-first/organisms';
import { UserDatagridFactory } from './GridUser.model';
import { useBreadcrumb } from '@people-first/hooks';
import { toggleUserStatus } from '@people-first/store';
import { useConfirm } from '@people-first/molecules';
import { confirmDialogConfigRegistry } from '@people-first/shared';
import { ACL } from './User.resources';

const GridUser = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ConfirmDialog, confirm] = useConfirm();

    const { datagrid } = useDataGrid(() => {

        const toggleStatusHandler = async ({ detail }: any) => {
            const entity = { ...detail.row };
            return confirm(confirmDialogConfigRegistry.get(`${entity.status}`)()).then(
                () => toggleUserStatus(entity)
            )
        }

        const editHandler = async ({ detail }: any) => navigate(`/user/${detail.row.id}`);
        const detailsHandler = async ({ detail }: any) => navigate(`/user/profile/${detail.row.id}`);

        return UserDatagridFactory()
            .on("row.click", detailsHandler)
            .on("edit", editHandler)
            .on("toggle.status", toggleStatusHandler)
            .refresh()
    });

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.users'), "/user"],
    ])

    return (
        <>
            <ListPageTemplate
                button={
                    <ButtonWithPermission
                        require={ACL.create}
                        onClick={() => navigate('/user/new')}
                        variant="contained"
                    >
                        {t('user.new_user')}
                    </ButtonWithPermission>
                }
                datagrid={<DataGrid model={datagrid} />}
            />
            <ConfirmDialog />
        </>
    );
};

export default GridUser;