import { AclMap } from "@people-first/shared";

export const ACL: AclMap = {
    create: {
        anyOf: [
            "dataLoad:All",
            "dataLoad:UploadEngagementSurvey",
            "dataLoad:UploadRoster",
            "dataLoad:UploadSupplyProject",
        ]
    },
    download: {
        anyOf: [
            "dataLoad:All",
            "dataLoad:DownloadEngagementSurvey",
            "dataLoad:DownloadRoster",
            "dataLoad:DownloadSupplyProject",
        ]
    },
};