import React, { useState, useEffect } from 'react';
// import { makeRequest } from 'src/app/util';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Text, Button, InputAdornment, LoadingButton, } from '@people-first/atoms'
import { PersonOutline, ArrowBack } from '@mui/icons-material';
import { HeaderForgotPassword } from '@people-first/organisms';
import { LoginTemplate } from '@people-first/templates';
import { api } from '@people-first/api';

interface IState {
    EID: string;
    requestSend: boolean;
    requestError: boolean;
}

export const ForgotPassword = () => {

    const [values, setValues] = useState<IState>({
        EID: '',
        requestSend: false,
        requestError: false,
    });

    const [disableButton, setDisableButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation()

    const handleChange =
        (prop: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {

            if (event.target.value.trim()) {
                setDisableButton(false);
            }

            setValues({ ...values, [prop]: event.target.value.trim() });
        };

    const handlePasswordRecovery = () => {

        setIsLoading(true)

        //todo: descomentar
        api.auth.forgotPassword({ requestBody: { username: values.EID } }).then(() => {
            setIsLoading(false)
            setValues({ ...values, requestSend: true });
        })
        // makeRequest('/forgot-password', 'post', { username: values.EID }).then(() => {
        //     setIsLoading(false)
        //     setValues({ ...values, requestSend: true });

        // }).catch(() => {
        //     setIsLoading(false)
        //     setValues({ ...values, requestSend: true });
        // })
    };

    const handleGoBack = () => {
        navigate('/login')
    };

    useEffect(() => {
        if (!values.EID) {
            setDisableButton(true);
        }
    }, [disableButton, values]);

    return (
        <LoginTemplate
            header={<HeaderForgotPassword />}
            form={
                !values.requestSend ? (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexWrap="nowrap"
                            width="368px"
                        >
                            <Text variant="subtitle1" mb="0.375rem">
                                {t('login.access_recovery')}
                            </Text>
                            <Text variant="body1" mb="1.25rem">
                                {t('login.regain_your_access')}
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            width="368px"
                        >
                            <TextField
                                onKeyDown={(e) => ((e.key === 'Enter') && (!disableButton)) ? handlePasswordRecovery() : null!}
                                required
                                variant="outlined"
                                label={t("login.placeholder_eid")}
                                placeholder={t("login.placeholder_eid")}
                                sx={{
                                    width: '368px',
                                    marginBottom: '1.5rem',
                                    '& .MuiFormHelperText-root.Mui-error': {
                                        backgroundColor: '#F0F6F9 ',
                                        width: '100%',
                                        margin: '0 auto',
                                        paddingLeft: 0,
                                        borderRadius: '4px',
                                    },
                                }}
                                value={values.EID}
                                onChange={handleChange('EID')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonOutline />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                mb="20px"
                                display="flex"
                                justifyContent="center"
                                width="368px"
                            >
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={disableButton}
                                    variant="contained"
                                    size="medium"
                                    onClick={handlePasswordRecovery}
                                >
                                    {t('login.access_recovery_button')}
                                </LoadingButton>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    color="black"
                                    size="small"
                                    onClick={handleGoBack}
                                    disableRipple
                                    startIcon={
                                        <ArrowBack fontSize="small" />
                                    }
                                    sx={{ textTransform: 'none' }}
                                >
                                    <Text variant="body1"> {t('login.go_back_button')} </Text>
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : !values.requestError ? (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexWrap="nowrap"
                            width="368px"
                        >
                            <Text
                                variant="subtitle1"
                                marginBottom="0.375rem"
                            >
                                {t('login.check_your_email')}
                            </Text>
                            <Text variant="body1">
                                {t('login.we_sent_message')}
                            </Text>
                            <Text variant="body1" marginBottom="1.25rem">
                                {t('login.registered_email')}
                            </Text>
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="center"
                            width="368px"
                        >
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={handleGoBack}
                            >
                                {t('login.to_login_button')}
                            </Button>
                        </Box>
                    </>
                ) : null!
            }
        />
    )
}

