import { createReducer } from '@reduxjs/toolkit';
import { getLoadingDataAction, saveLoadingDataAction, updateLoadingDataGridAction } from '../actions';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const loadingDataReducer = createReducer<TypeDialogLogout>(initialState, {
  [getLoadingDataAction.fulfilled.type]: (state) => ({ ...state, blocked: true }),
  [saveLoadingDataAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [updateLoadingDataGridAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
})