import * as React from 'react';

import tokens from '../../theme/tokens.json';
import Avatar from '../../atom/avatar/Avatar';
import Text from '../../atom/text/Text';

import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@people-first/hooks'
import { useTranslation } from 'react-i18next';
import { handleInitialsAvatar } from './handler-initial-avatar';
import { logoutAction } from '@people-first/store';

export default function UserProfileCard() {
    const location = useLocation()
    const { peopleFirst } = tokens;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userInfo, } = useAppSelector((state) => state.session);

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(logoutAction())
    };

    const profileMenuObjArray = [
        {
            icon: <AccountCircleOutlinedIcon />,
            text: t('topBar.profile_menu_my_profile'),
            onClick: () => navigate('/user/profile/me', location),
        },
        {
            icon: <LogoutOutlinedIcon />,
            text: t('topBar.profile_menu_logout'),
            onClick: handleLogout,
        },
    ]

    return (
        <Box sx={{ cursor: 'pointer' }}>
            <Box onClick={handleMenu} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Avatar alt={userInfo?.name} src={userInfo?.picture ? userInfo?.picture : userInfo?.name}>
                        <Text variant='body1' color='#fff'>
                            {handleInitialsAvatar(userInfo?.username)}
                        </Text>
                    </Avatar>
                </Box>

                <Box ml={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <Text variant="subtitle1">{userInfo?.name}</Text>
                    <Text variant='body1'>{userInfo?.email}</Text>
                </Box>

                <Box>
                    <ExpandMoreOutlinedIcon sx={{ color: peopleFirst.custom.primary.value }} />
                </Box>
            </Box>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}

            >
                {profileMenuObjArray.map((profileMenuObjArray, index) => (

                    <MenuItem
                        key={profileMenuObjArray.text}
                        onClick={profileMenuObjArray.onClick}
                        sx={{
                            '&:hover': {
                                backgroundColor: peopleFirst.custom['accent-two'].value,
                                borderRadius: '4px',

                                '& .MuiListItemIcon-root': {
                                    color: peopleFirst.custom.primary.value,
                                },

                                '& .MuiListItemText-primary': {
                                    color: peopleFirst.custom.primary.value,
                                }
                            },
                        }}
                    >
                        <ListItemIcon>
                            {profileMenuObjArray.icon}
                        </ListItemIcon>

                        <ListItemText
                            sx={{ width: '236px' }}
                            primaryTypographyProps={{
                                color: 'secondary',
                                fontWeight: 400,
                                variant: 'body2',
                            }}
                        >
                            {profileMenuObjArray.text}
                        </ListItemText>
                    </MenuItem>

                ))}
            </Menu>
        </Box>
    );
}
