import { configureStore, ThunkAction, Action, combineReducers, } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import { dialogLogoutReducer } from './reducers/dialog-logout';
import { roleReducer, loginReducer, userReducer, loadingDataReducer } from './reducers';
import { blockSmallDevicesReducer } from './reducers/block-small-devices';
import { menuReducer } from './reducers/menu';
import { organizationsReducer } from './reducers/organization';
import { appReducer } from './reducers/app';
import { PermissionService } from '@people-first/shared';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,

}

const rootReducer = combineReducers({
	session: persistReducer(persistConfig, loginReducer),
	dialogLogout: dialogLogoutReducer,
	blockSmallDevices: blockSmallDevicesReducer,
	menus: menuReducer,
	organizations: organizationsReducer,
	roles: roleReducer,
	users: userReducer,
	app: appReducer,
	loadingData: loadingDataReducer,
})

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
})

//todo: adicionar revalidação da sessão quando o usuário ativar a tab
if (localStorage.getItem("accessToken")) PermissionService.add("authenticated");

const unsubscribe = store.subscribe(() => {
	const { userInfo } = store.getState().session;
	if (userInfo.id) {
		unsubscribe();
		PermissionService.add(...userInfo.privileges)
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

export const persistor = persistStore(store)
