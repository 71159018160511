import { createTheme } from '@mui/material';
import tokens from './tokens.json';

const { peopleFirst } = tokens;

export const SpacingText = createTheme({
    spacing: [0.4, 0.5, 0.15, 0.25, 1.25, 1.5, 2, 0.1],
});

const TextTheme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Graphik',
            color: peopleFirst.shades.black.value,
            fontStyle: 'normal',
        },
        h1: {
            fontWeight: 600,
            fontSize: '3.75rem',
            lineHeight: '4rem',
            letterSpacing: SpacingText.spacing(5),
        },
        h2: {
            fontWeight: 600,
            lineHeight: '3.375rem',
            fontSize: '3.125rem',
            letterSpacing: SpacingText.spacing(1),
        },
        h3: {
            fontWeight: 600,
            lineHeight: '3.125rem',
            fontSize: '2.875rem',
        },
        h4: {
            fontWeight: 600,
            lineHeight: '2.75rem',
            fontSize: '2.5rem',
            letterSpacing: SpacingText.spacing(3),
        },
        h5: {
            fontWeight: 600,
            lineHeight: '2.125rem',
            fontSize: '1.875rem',
        },
        h6: {
            fontWeight: 600,
            lineHeight: '1.5rem',
            letterSpacing: SpacingText.spacing(3),
        },
        caption: {
            lineHeight: '1.25rem',
            letterSpacing: SpacingText.spacing(0),
        },
        overline: {
            lineHeight: '2rem',
            letterSpacing: SpacingText.spacing(6),
        },
        body2: {
            lineHeight: '1.25rem',
            letterSpacing: SpacingText.spacing(3),
        },
        body1: {
            letterSpacing: SpacingText.spacing(1),
        },
        subtitle1: {
            fontSize: '1.125rem',
            lineHeight: '1.625rem',
            fontWeight: 500,
            letterSpacing: SpacingText.spacing(2),
        },
        button: {
            letterSpacing: SpacingText.spacing(4),
        },
    },
});

export default TextTheme;
export { TextTheme };
