import { Avatar } from "@mui/material";
import { GridActionsCellItem, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, DynamicIcon, Text } from "@people-first/atoms";
import { i18n } from "@people-first/i18n";
import { ChipStatusDataGrid } from '../../molecule/chip-status-datagrid/ChipStatusDataGrid';
import { resolvePropHandler, toPrivilegeKey, withPermission } from "@people-first/shared";

const { t } = i18n;

export const GridCellsRegistry = (() => {
    const items = new Map();

    return {
        set: (name: string, field: any) => items.set(name, field),
        get: (name: string) => items.get(name),
    };
})();

GridCellsRegistry.set("singleSelect", (params: GridRenderCellParams) => (
    <>
        <ChipStatusDataGrid params={params} />
    </>
));

GridCellsRegistry.set("item", (params: GridRenderCellParams) => {
    const { label, caption } = params.row;
    return (
        <Box display={"flex"} flexDirection={'column'}>
            <Text variant="body2">{label}</Text>
            <Text variant="caption" color="secondary">{caption}</Text>
        </Box>
    )
});

GridCellsRegistry.set("status", (params: GridRenderCellParams) => (
    <>
        <ChipStatusDataGrid params={params} />
    </>
));

GridCellsRegistry.set("icon", (params: GridRenderCellParams) => (
    <>
        <DynamicIcon iconName={params.row.icon} />
    </>
));

GridCellsRegistry.set("text.body2", (params: GridRenderCellParams) => {
    const content = (Array.isArray(params.value) ? params.value : [params.value]).join(', ')
    return (
        <>
            <Text variant="body2" className="cellContentDatagrid">{content}</Text>
        </>
    )
});

GridCellsRegistry.set("text.body2.t", (params: GridRenderCellParams) => {
    const content = (Array.isArray(params.value) ? params.value : [params.value]).map((value => i18n.t(value))).join(', ')
    return (
        <>
            <Text variant="body2" className="cellContentDatagrid">{content}</Text>
        </>
    )
});


GridCellsRegistry.set("actions", (params: GridRenderCellParams) => {
    const actions = ((params.colDef as any).actions || []).map((action: any, idx: number) => {
        let ActionCell: any = GridActionsCellItem;

        if (action.canAccess) {
            ActionCell = withPermission(ActionCell, action.canAccess);
        }

        return (<ActionCell
            key={idx}
            onClick={(event: any) => action.onClick(params.row, event)}
            className="actionsMenu"
            // disabled={status == false ? false : true}
            color={action.color}
            label={action.label || ""}
            icon={<DynamicIcon iconName={resolvePropHandler(action.icon, params.row)} color={action.color} />}
        />)
    });
    return (<>{actions}</>)
});

GridCellsRegistry.set("avatar", (params: GridRenderCellParams) => {
    return (
        <>
            <Avatar alt={params.row.name} src={params.row.picture}>
                {/* todo: rever lógica de inicias */}
                {/* <Text variant='body1' color='#fff'> {handleInitialsAvatar(params.row.username)}</Text> */}
            </Avatar>
        </>
    )
})
