import { createReducer } from '@reduxjs/toolkit';
import { pushNotificationAction, removeNotificationAction, setPageBreadcrumb, setActiveMenuAction, setMainMenuAction, toggleMainMenuAction } from '../actions';

export type TypeDialogLogout = {
    blocked: boolean;
    breadcrumb: Array<string>[];
    notifications: Array<any>[];
    mainMenu: {
        active: any;
        expanded: boolean;
        items: Array<any>;
    };
}

const initialState: TypeDialogLogout = {
    blocked: false,
    breadcrumb: new Array(),
    notifications: new Array(),
    mainMenu: {
        active: null,
        expanded: true,
        items: []
    },
}

function toMap(items: Array<any>, map: Map<string, any> = new Map()): Map<string, any> {
    items.forEach(menu => {
        map.set(menu.id, menu);
        if (menu.children) toMap(menu.children, map)
    })
    return map;
}

const setActiveMenuActionHandler = (state: any, { payload: toActive }: any) => {
    const { active, items } = state.mainMenu;

    const menuMap = toMap(items)

    // close and deactive previous menu if exists
    if (active) {
        const previous = menuMap.get(active.id);
        if (
            active.id !== toActive.id &&
            active.id !== toActive.parent
        ) { previous.open = false; }
        previous.active = false;
    }

    // active new menu item
    const menu = menuMap.get(toActive.id);
    menu.open = !menu?.open;
    menu.active = true;

    // set all parents as open if exists
    let parent = menuMap.get(menu.parent);
    while (parent) {
        parent.open = true;
        parent = menuMap.get(parent.parent);
    }

    state.mainMenu = {
        ...state.mainMenu,
        active: menu
    };
};

export const appReducer = createReducer<TypeDialogLogout>(initialState, {
    [setPageBreadcrumb.type]: (state, { payload: breadcrumb }) => {
        state.breadcrumb = breadcrumb;
    },
    [pushNotificationAction.type]: (state, { payload: notification }) => {
        state.notifications = [notification];
    },
    [removeNotificationAction.type]: (state, { payload: id }) => {
        state.notifications = state.notifications.filter((n: any) => n.id !== id);
    },
    [setMainMenuAction.type]: (state, { payload: { menu: items, path = "" } }) => {
        let menu = [...items];
        let item: any;
        let active = null;

        while (item = menu.shift()) {
            if (!item.id) item.id = crypto.randomUUID()

            // active menu 
            if (item.path === path) { active = item }

            if (item.children) menu = [
                ...item.children.map((m: any) => Object.assign(m, { parent: item.id })),
                ...menu
            ]
        }

        state = { ...state, mainMenu: { active: null, items, expanded: false } };

        if (active) { setActiveMenuActionHandler(state, { payload: active }) }

        return state;
    },
    [setActiveMenuAction.type]: setActiveMenuActionHandler,
    [toggleMainMenuAction.type]: (state) => {
        state.mainMenu.expanded = !state.mainMenu.expanded;
    }
})