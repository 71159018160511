import { SelectControl } from "./SelectControl";

export class TransferListControl<T = unknown> extends SelectControl<T> {

    public selectionLabel = '';
    public selectedLabel = '';

    constructor(protected defaultValue: any) {
        super(defaultValue);
        this.setType("transfer-list");
    }

    public setSelectionLabel(selectionLabel: any): this {
        this.selectionLabel = selectionLabel;
        return this;
    }

    public setSelectedLabel(selectedLabel: any): this {
        this.selectedLabel = selectedLabel;
        return this;
    }
}