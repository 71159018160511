import { DataGridModel } from "./DataGridModel";
import { useState } from "react"

export const useDataGrid = <T = any,>(factory: () => DataGridModel): { datagrid: DataGridModel } => {
    let [change, setChange] = useState<number>(0)
    let [datagrid, set] = useState<DataGridModel | undefined>(undefined)

    if (!datagrid) {
        datagrid = factory();
        set(datagrid);
        datagrid.control.valueChange(() => setChange(change++));
    }

    return { datagrid };
}