import { Button, Text } from '@people-first/atoms';
import { useTranslation } from 'react-i18next';
import { GlobalTemplate } from '@people-first/templates'
import { useBreadcrumb } from '@people-first/hooks';
import { pushNotification } from '@people-first/store';

const Home = () => {
    const { t } = useTranslation();

    useBreadcrumb([
        [`${t('Home')}`, ""]
    ])

    const handleClick = () => {
        pushNotification({
            content: "You're report is ready",
            align: "bottom-right",
            // duration: 5000,
        })
    };

    return (
        <>
            <GlobalTemplate>
                <>
                    {/* <Button onClick={() => handleClick()}>Teste</Button> */}
                </>
            </GlobalTemplate>
        </>
    );
};

export default Home;