import React from "react";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";

type TextFieldType = TextFieldProps & {
    sx?: SxProps<Theme>,
}

const TextField: React.FC<TextFieldType> = (props, sx) => {
    return <MuiTextField sx={[{ width: '100%' }, ...Array.isArray(sx) ? sx : [sx]]}        {...props} />;
};

export default TextField;
export { TextField };
