
import { useTheme, useMediaQuery } from '@mui/material';
import { MobileBackgroundImage } from '@people-first/assets';
import { Box, Grid } from '@people-first/atoms';

type BreakpointTemplateType = {
    header: JSX.Element;
    contents: JSX.Element;
};

const BreakPointTemplate = ({ header, contents }: BreakpointTemplateType) => {
    const theme = useTheme();
    const belowMiddleWindowSize = useMediaQuery(theme.breakpoints.down('sm'));
    const belowAndroidWindowSize = useMediaQuery(theme.breakpoints.down('android'));

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                backgroundImage: `url(${MobileBackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '65% 100%'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} mt={16}>
                    {header}
                    <Box display="flex" flexDirection='column' width={belowAndroidWindowSize ? '190px' : belowMiddleWindowSize ? '300px' : '430px'} >
                        {contents}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


export default BreakPointTemplate;
export { BreakPointTemplate };