import { api } from "@people-first/api";
import { createEntityApiAction } from "../utils";

export const [
    updateRoleGridAction,
    saveRoleAction,
    getRoleAction,
    toggleRoleStatusAction
] = createEntityApiAction(
    'Role',
    api.role,
    {
        alerts: {
            save: [
                "alert.success_message_generic",
                "role.alert_content_new",
                "alert.success_message_update",
            ]
        }
    }
)
