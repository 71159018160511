import { encodeQuery } from "@people-first/shared";
import { getOrganizationAction, saveOrganizationAction, store, updateOrganizationGridAction } from "@people-first/store";
import { createBaseDataSourceByApi, evalRespose } from "./base";
import { DataSource, DataSourceFinder } from "./types";


export const OrganizarionDataSource: DataSource<any> = createBaseDataSourceByApi('organizarion', {
    findAction: updateOrganizationGridAction,
    getAction: getOrganizationAction,
    saveAction: saveOrganizationAction,
});


export const OrganizarionSelectDataSource: DataSourceFinder<any> = {
    find({ query, page, itemsPerPage }) {
        return evalRespose(
            store.dispatch(updateOrganizationGridAction({
                page,
                itemsPerPage,
                fields: 'id,name',
                format: 'org.select',
                q: encodeQuery({ "name": { "$contains": query } }),
            }))
        ).then((resp: any) => {
            return {
                ...resp,
                items: resp.items.map((item: any) => ({
                    label: item.name,
                    caption: item.path.reverse().join(' > '),
                    value: item.id,
                    id: item.id
                }))
            }
        });
    },
}