import { FormGroup, ifEnabled, positiveNumbersValidator, SelectControl, stringRequired } from "@people-first/molecules";
import { createChildrenOrgSuggest, createParentOrgSuggest } from "@people-first/suggests";

export function formFactory(value: any = {}, isCreateMode = true) {

    const organizations = [
        ...new Array(7).fill(0).map((_, idx) => {
            const index = idx + 1;
            return new SelectControl<number>(value.parent)
                .setName(`org_${idx}`)
                .setLabel(`user.column_org${index}`)
                .setPlaceholder(`user.column_org${index}`)
        }),
    ]

    organizations.forEach((org, idx) => {
        const suggest = idx === 0
            ? createParentOrgSuggest(() => 2)
            : createChildrenOrgSuggest();

        org.setOptions(suggest);

        org.setValidate(ifEnabled(stringRequired))

        org.valueChange((organizationId) => {

            const nextControl = organizations.at(idx + 1);
            if (nextControl) {
                nextControl.setDisabled(() => org.invalid)
                if (organizationId) nextControl.updateOptionsWith(organizationId)
                nextControl.reset()
            }
        })
    });

    const org_level = new SelectControl<number>(value.org_level || 0)
        .setLabel("organization.organization_level")
        .setPlaceholder("organization.organization_level")
        .setValidate(positiveNumbersValidator)
        .setRequired()
        .setOptions([
            { label: '1', value: 1, },
            { label: '2', value: 2, },
            { label: '3', value: 3, },
            { label: '4', value: 4, },
            { label: '5', value: 5, },
            { label: '6', value: 6, },
            { label: '7', value: 7, },
        ]);

    const form = new FormGroup({ org_level });

    // always org_level change update control list of orgs
    org_level.valueChange((level) => {
        form.addControls(
            organizations.slice(0, level).reduce(
                (controls, control) => ({ ...controls, [control.name]: control }),
                {},
            )
        )
        form.removeControls(
            ...organizations.slice(level).map((control) => control.name)
        )
        organizations.at(0)?.updateOptionsWith('')
    })


    return form;
}