import { ButtonProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;

type TypeButton = {
    props: ButtonProps;
    style: Interpolation<{
        theme: Theme;
    }>;
};

const ButtonThemeVariants: TypeButton[] = [
    // text variants
    {
        props: { variant: 'text', size: 'small' },

        style: {
            height: '28px',
            ':hover': {
                background: peopleFirst.greys['lighten-4'].value,
            },
            ':active': {
                background: peopleFirst.greys['lighten-2'].value,
            },
        },
    },
    {
        props: { variant: 'text', size: 'medium' },

        style: {
            height: '36px',
            ':hover': {
                background: peopleFirst.greys['lighten-4'].value,
            },
            ':active': {
                background: peopleFirst.greys['lighten-2'].value,
            },
        },
    },
    {
        props: { variant: 'text', size: 'large' },

        style: {
            height: '44px',
            ':hover': {
                background: peopleFirst.greys['lighten-4'].value,
            },
            ':active': {
                background: peopleFirst.greys['lighten-2'].value,
            },
        },
    },

    // contained variants
    {
        props: {
            variant: 'contained',
            size: 'small',
        },
        style: {
            height: '28px',
        },
    },
    {
        props: {
            variant: 'contained',
            size: 'medium',
        },
        style: {
            height: '36px',
        },
    },
    {
        props: {
            variant: 'contained',
            size: 'large',
        },
        style: {
            height: '44px',
        },
    },

    // outlined variants
    {
        props: {
            variant: 'outlined',
            size: 'small',
        },
        style: {
            height: '28px',
        },
    },
    {
        props: {
            variant: 'outlined',
            size: 'medium',
        },
        style: {
            height: '36px',
        },
    },
    {
        props: {
            variant: 'outlined',
            size: 'large',
        },
        style: {
            height: '44px',
        },
    },
];

export default ButtonThemeVariants;
export { ButtonThemeVariants };
