import React, { useEffect, useState } from "react"
import { AlertSnackbar } from "@people-first/molecules"
import { useSelector } from "react-redux"
import { removeNotification } from "@people-first/store";

export const NotificationContainer = () => {
    const [current, setCurrent] = useState<any>(null)
    const { notifications = [] } = useSelector((state: any) => state.app);

    useEffect(() => {
        setCurrent(notifications.at(0))
    }, [notifications])

    const onCloseInternal = () => {
        setCurrent(null)
        if (current) removeNotification(current.id)
    }

    const onClickInternal = () => { }

    return (
        <>
            {
                current
                    ? <AlertSnackbar
                        key={current.id}
                        title={current.title}
                        content={current.content}
                        onClose={onCloseInternal}
                        onClick={onClickInternal}
                        closeButton={true}
                        duration={current.duration}
                        severity={current.severity}
                        icon={current.icon}
                    />
                    : null
            }
        </>
    )
}