import { api } from "@people-first/api";
import { createEntityApiAction } from "../utils";

export const [
    updateUserGridAction,
    saveUserAction,
    getUserAction,
    toggleUserStatusAction,
] = createEntityApiAction(
    'User',
    api.user,
    {
        alerts: {
            save: [
                "alert.success_message_generic",
                "user.alert_content_new",
                "alert.success_message_update",
            ]
        }
    }
)