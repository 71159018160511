import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { FormAccessor, FormView } from '@people-first/molecules';
import FormTemplate, { FormTemplateContext } from 'src/app/template/form-template/FormTemplate';
import { formFactory } from './LoadingDataCreate.form';
import { useBreadcrumb } from '@people-first/hooks';
import { LoadingDataDataSource } from '@people-first/data-sources';
import { Button } from '@people-first/atoms';
import { FileDownload } from '@mui/icons-material';
import { tact, tbread } from './LoadingDataCreate.resources';
import { useStaticProps } from '@people-first/shared';


export async function getStaticProps({ params }: any) {
  const { id } = params;
  const isNew = id === "create";
  // const data = !isNew ? await LoadingDataDataSource.get!({ id }).catch(() => ({})) : {};

  const data = {
    // competence: "02/2022",
    // name: "003_AI_01",
    // type: "roster",
  }
  return {
    form: () => formFactory(data, isNew)
  }
}

export function LoadingDataCreatePage() {
  const navigate = useNavigate();
  const { form } = (useStaticProps() as any)
  const { t } = useTranslation();
  const gotoGrid = () => navigate('/loading-data');

  useBreadcrumb([
    [t(tbread("grid")), "/loading-data"],
    [t(tbread("new_loading")), ""]
  ]);

  return (
    <>
      <FormTemplate
        formFactory={form}
        width='630px'
        title={t(tact('new_loading'))}
        onBackNavigation={gotoGrid}
        afterSubmit={gotoGrid}
        dataSource={LoadingDataDataSource}
      >
        <FormTemplateContext.Consumer>
          {({ form }) => (
            <>
              <FormView form={form}>
                <FormAccessor formControlName="type" />
                <FormAccessor formControlName="name" />
                <FormAccessor formControlName="competence" />
                <Button variant='outlined' startIcon={<FileDownload />} size='small'>
                  {t(tact('download_template'))}
                </Button>
                <FormAccessor formControlName="file" />
              </FormView>
            </>
          )}
        </FormTemplateContext.Consumer>
      </FormTemplate>
    </>
  );
}

export default {
  getStaticProps,
  component: LoadingDataCreatePage,
}
