import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { OrganizarionDataSource } from '@people-first/data-sources';
import { FormAccessor, FormView } from '@people-first/molecules';
import FormTemplate, { FormTemplateContext } from 'src/app/template/form-template/FormTemplate';
import { formFactory } from './Organization.form';
import { useBreadcrumb } from '@people-first/hooks';
import { ContextVariant, useStaticProps } from '@people-first/shared';


const PageVariants = new ContextVariant(
    {
        new: {
            "breadcrumb.segment": "organization.form_title_new_organization",
            "form.title": "organization.form_title_new_organization",
        },
        update: {
            "breadcrumb.segment": "organization.form_title_update_organization",
            "form.title": "organization.form_title_update_organization",
        }
    }
)

export async function getStaticProps({ params }: any) {
    const { id } = params;
    const isNew = id === "new";
    const data = !isNew ? await OrganizarionDataSource.get({ id }).catch(() => ({})) : {};

    if (data.parent) data.parent = data.parent.id;

    return {
        variant: PageVariants.get([id, "update"]),
        form: () => formFactory(data, isNew)
    }
}

export function OrganizationForm() {
    const navigate = useNavigate();
    const { form, variant } = (useStaticProps() as any)
    const { t } = useTranslation();
    const gotoGrid = () => navigate('/organization');

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('organization.organization_initials'), "/organization"],
        [t(variant.get("breadcrumb.segment")), ""],
    ])

    return (
        <>
            <FormTemplate
                formFactory={form}
                width='630px'
                title={t(variant.get("form.title"))}
                onBackNavigation={gotoGrid}
                afterSubmit={gotoGrid}
                dataSource={OrganizarionDataSource}
            >
                <FormTemplateContext.Consumer>
                    {({ form }) => (
                        <>
                            <FormView form={form}>
                                <FormAccessor formControlName="code" />
                                <FormAccessor formControlName="name" />
                                <FormAccessor formControlName="leader_name" />
                                <FormAccessor formControlName="leader_eid" />
                                <FormAccessor formControlName="org_level" />
                                <FormAccessor formControlName="parent" />
                            </FormView>
                        </>
                    )}
                </FormTemplateContext.Consumer>
            </FormTemplate>
        </>
    );
}

export default {
    getStaticProps,
    component: OrganizationForm,
}