import {
    FormControl,
    FormGroup,
    ifEnabled,
    numberRequiredValidator,
    SelectControl,
    stringRequired,
    stringRequiredValidator,
} from "@people-first/molecules";
import { createParentOrgSuggest } from "@people-first/suggests";

export function formFactory(value: any = {}, isCreateMode = true) {

    const org_level = new SelectControl<number>(value.org_level || 7)
        .setLabel("organization.organization_level")
        .setPlaceholder("organization.organization_level")
        .setValidate(numberRequiredValidator)
        .setRequired()
        .setOptions([
            { label: '1', value: 1, },
            { label: '2', value: 2, },
            { label: '3', value: 3, },
            { label: '4', value: 4, },
            { label: '5', value: 5, },
            { label: '6', value: 6, },
            { label: '7', value: 7, },
        ]);

    const parentOrgSuggest = createParentOrgSuggest(() => org_level.value);

    const parent = new SelectControl(value.parent)
        // .setType("autocomplete")
        .setLabel("organization.organization_superior")
        .setPlaceholder("organization.organization_superior")
        .setValidate(ifEnabled(stringRequired))
        .setDisabled(() => org_level.value <= 1)
        .setRequired(() => org_level.value > 1)
        .setOptions(parentOrgSuggest);

    const code = new FormControl(value.code)
        .setLabel("organization.organization_code")
        .setPlaceholder("organization.organization_code")
        .setValidate(stringRequiredValidator)
        .setType('number')
        .setRequired();

    const name = new FormControl(value.name)
        .setLabel("organization.organization_name")
        .setPlaceholder("organization.organization_name")
        .setValidate(stringRequiredValidator)
        .setType('text')
        .setRequired();

    const leader_name = new FormControl(value.leader_name)
        .setLabel("organization.organization_leader")
        .setPlaceholder("organization.organization_leader")
        .setType('text');

    const leader_eid = new FormControl(value.leader_eid)
        .setLabel("organization.eid_leader")
        .setPlaceholder("organization.eid_leader")
        .setType('text');

    const form = new FormGroup({
        code,
        name,
        leader_name,
        leader_eid,
        org_level,
        parent,
    });

    const updateParentOrgSuggest = () => {
        if (org_level.value > 1) parentOrgSuggest.updateWith('')
    }

    if (!isCreateMode) form.addControls({ id: new FormControl(value.id) })

    org_level.valueChange((level) => {
        parent.setValue('')
        updateParentOrgSuggest()
    })

    setTimeout(() => updateParentOrgSuggest());

    return form;
}