import React from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";
import { withPermission } from "@people-first/shared";

export type ButtonType = ButtonProps & {
    sx?: SxProps<Theme>,
    label?: string;
}

const Button: React.FC<ButtonType> = (props, sx) => {
    return (
        <MuiButton sx={[...Array.isArray(sx) ? sx : [sx]]} {...props} >
            {props.label || props.children}
        </MuiButton>
    );
};

export const ButtonContained: React.FC<ButtonType> = (props) => {
    return (
        <Button {...props} variant="contained" />
    );
};
const ButtonWithPermission = withPermission<ButtonType>(Button);

export default Button;
export { Button, ButtonWithPermission, };