import { CheckboxesTags } from "@people-first/atoms";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { InputContainer } from "./InputContainer";

export function CheckboxesTagsField({ control }: any) {
    const { t } = useTranslation();
    const [options, setOptions] = useState<any>([])

    useEffect(() => {
        console.log("effect do change")
        const unsubscription = control.optionsChange((_options: any) => setOptions(_options.items))
        return () => { unsubscription() }
    }, [])

    useEffect(() => {
        console.log("effect de init")
        control.options("").then((_options: any) => setOptions(_options));
    }, [options])

    const hasError = control.dirty && Boolean(control.erros);

    return (
        <InputContainer>
            <CheckboxesTags
                required={control.required}
                label={t(control.label)}
                placeholder={t(control.placeholder)}
                error={hasError}
                options={options}
                onChange={(_: any, values: any) => control.setValue(values)}
                onInputChange={(_: any, term: string) => control.updateOptionsWith(term)}
            />
        </InputContainer>
    );
}