import * as Icons from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';
import React from 'react';
export type IconNames = keyof typeof Icons; // use this in other components

export interface DynamicIconsType extends React.HTMLAttributes<any> {
    iconName: IconNames;
    color?: "error" | "success" | "inherit" | "disabled" | "action" | "secondary" | "primary" | "info" | "warning"
    sx?: SxProps<Theme>,
};

export const DynamicIcon: React.FC<DynamicIconsType> = ({ className, iconName, color, sx }): JSX.Element => {
    const Icon = Icons[iconName];
    return Icon
        ? (<Icon className={className} color={color} sx={sx} />)
        : (<></>)
};

export default DynamicIcon

export { DynamicIcon as Icon }