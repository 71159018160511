export const handleInitialsAvatar = (username: any) => {
    let initialsName = ""

    if (username) {
        initialsName = username.match(/\b(\w)/gi).join('').toUpperCase()
        initialsName = initialsName.substr(0, 1) + initialsName.substr(-1, 1)
    }

    return initialsName
};
