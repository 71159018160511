import Box from '../../atom/box/Box';
import Text from '../../atom/text/Text';
import PeopleFirstLogotype from '../../../asset/logotype/Logo_People_First.svg';
import { useTranslation } from 'react-i18next';

const HeaderForgotPassword = () => {
    const { t } = useTranslation();
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                width="368px"
            >
                <Text variant="body1" color="secondary" marginBottom="0.375rem">
                    {t('login.welcome')}
                </Text>
                <Box
                    width="288px"
                    component="img"
                    sx={{ marginBottom: '1.625rem' }}
                    src={PeopleFirstLogotype}
                    alt={t('logotype.alt_text')}
                />
                <Text
                    variant="body1"
                    color="secondary"
                    width="260px"
                    marginBottom="2.125rem"
                >
                    {t('login.header_our_platform')}
                </Text>
            </Box>
        </>
    );
};
export default HeaderForgotPassword;
export { HeaderForgotPassword };
