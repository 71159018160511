import RowProperty from '../../molecule/row-property/RowProperty'
import React from 'react'
import { List, Box } from '@people-first/atoms'

const ListProperty: React.FC<any> = ({ items = [] }) => {
  return (
    <List>
      <Box>
        {items?.map(([label, value]: any) => (
          <RowProperty key={label} label={label} fieldContent={value} />
        ))}
      </Box>
    </List>
  )
}

export default ListProperty