import { Box } from '@people-first/atoms'
import GlobalTemplate from '../global/GlobalTemplate'
import { useTheme, useMediaQuery } from '@mui/material'

type TypeDatagridTemplate = {
    button?: JSX.Element;
    datagrid?: JSX.Element;
};

const DatagridTemplate = ({ button, datagrid }: TypeDatagridTemplate) => {
    const theme = useTheme();
    const belowExtraLargeWindowSize = useMediaQuery(theme.breakpoints.down('fullHd'));

    return (
        <GlobalTemplate paddingRight={belowExtraLargeWindowSize ? '8px' : '64px'}>
            <Box width='100%' display="flex" flexDirection="column" >
                <>
                    {button &&
                        <Box>
                            {button}
                        </Box>
                    }

                    {datagrid &&
                        <Box width='100%'
                            height='61.27vh'
                        >
                            {datagrid}
                        </Box>
                    }
                </>
            </Box>
        </GlobalTemplate>
    );
};
export default DatagridTemplate;

const ListPageTemplate = DatagridTemplate;

export { ListPageTemplate }
