import { Components, Theme } from '@mui/material';
import TextFieldThemeVariants from '../../atom/text-field/TextFieldThemeVariants';

const RootTextFieldTheme: Components<Omit<Theme, 'components'>> = {
    MuiTextField: {
        variants: TextFieldThemeVariants,

        styleOverrides: {
            root: {
                borderRadius: '4px',
            },
        },
    },
};

export default RootTextFieldTheme;
export { RootTextFieldTheme };
