import React from "react";
import { SxProps, Theme } from "@mui/material";
import { TextField } from '../text-field/TextField';
import MuiAutocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import Box from "../box/Box";
import Text from "../text/Text";

type TypeAutocompleteProps = {
    props?: any,
    sx?: SxProps<Theme>,
    // value: any,
    defaultValue?: any,
    options: any[],
    placeholder?: string,
    label?: string,
    helperText?: string,
    inputValue?: string,
    disabled?: boolean,
    error?: boolean,
    multiple?: boolean,
    required?: boolean,
    onChange?: Function,
    onInputChange?: Function,
    /**
    * Render the option, use `getOptionLabel` by default.
    *
    * @param {object} props The props to apply on the li element.
    * @param {T} option The option to render.
    * @param {object} state The state of the component.
    * @returns {ReactNode}
    */
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
}

const defaultRenderOptions = (props: any, option: any, { selected }: any) => (
    <li {...props} key={option.value}>
        <Box display={'flex'} flexDirection={'column'}>
            <Text variant="body2">{option.label}</Text>
            <Text variant="caption" color="secondary">{option.caption}</Text>
        </Box>
    </li>
)

export const Autocomplete = ({
    required,
    label,
    placeholder,
    disabled,
    error,
    options = [],
    onChange,
    onInputChange,
    renderOption = defaultRenderOptions,
    multiple,
    // inputValue,
}: TypeAutocompleteProps) => {

    return (
        <MuiAutocomplete
            // limitTags={0}
            multiple={multiple}
            options={options}
            disabled={disabled}
            // inputValue={inputValue}
            disableCloseOnSelect={multiple}
            // clearOnBlur={false}
            onChange={(event, newValue: Array<any> | any | null, reason) => {
                const _value = Array.isArray(newValue)
                    ? newValue.map((item) => item.value)
                    : newValue?.value
                onChange!(event, _value, newValue)
            }}
            onInputChange={(event, value: string | null) => onInputChange!(event, value)}
            getOptionLabel={(option: any) => option.label}
            renderOption={renderOption}
            style={{ width: '100%' }}
            renderInput={(params) => (
                <TextField
                    variant="outlined"
                    {...params}
                    error={error}
                    disabled={disabled}
                    required={required}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    )
}
    // const [value, setValue] = useState(defaultValue)
    // const [inputValue, setInputValue] = useState(defaultValue)

    // function handleChange(event: React.SyntheticEvent, newValue: any) {
    //     console.log('handleChange event', event, newValue)
    //     if (onChange) onChange(newValue.value);
    //     setValue(newValue.label)
    // }

    // return <MuiAutocomplete
    //     disablePortal
    //     sx={[
    //         { width: '100%' },
    //         ...Array.isArray(sx) ? sx : [sx]
    //     ]}
    //     value={value}
    //     onChange={handleChange}
    //     onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
    //     inputValue={inputValue}
    //     options={options}
    //     // isOptionEqualToValue={(option, value) => option.label === value}
    //     renderInput={(params: any) => (
    //         <TextField
    //             variant="outlined"
    //             {...params}
    //             placeholder={placeholder}
    //             label={label}
    //             disabled={disabled}
    //             error={error}
    //             required={required}
    //             helperText={helperText}
    //         />
    //     )}
    // />;