import { tokens } from "@people-first/design/tokens";

const { peopleFirst } = tokens;

export const menuItemSytle = (() => {
    const backgroundRegularColor = peopleFirst.custom['accent-two'].value;
    const backgroundSystemColor = peopleFirst.greys['lighten-2'].value;
    const colorRegular = peopleFirst.custom.primary.value;
    const black = peopleFirst.shades.black.value;
    const itemHover = {
        backgroundColor: backgroundRegularColor,
        borderRadius: '4px',
        color: colorRegular,
        '& .MuiListItemIcon-root': { color: colorRegular, },
        '& .MuiListItemText-primary': { color: colorRegular },
        '& .MuiSvgIcon-root': { color: colorRegular, },
        ".SideMenu__icon": { color: colorRegular, },
    };

    const systemHover = {
        backgroundColor: backgroundSystemColor,
        color: black,
        '& .MuiListItemIcon-root': { color: black, },
        '& .MuiListItemText-primary': { color: black },
        '& .MuiSvgIcon-root': { color: black, },
    };
    return (
        {

            '& .MuiDrawer-paper, .MuiList-root': {
                background: peopleFirst.greys['lighten-4'].value,
                border: 'none'
            },
            "& .SideMenu__Drawer": {
                ".SideMenu__submenu": {
                    minHeight: '0px',
                    height: '0px',
                    // overflow: 'auto',

                    "&--expanded": {
                        height: 'auto',
                        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                },
                ".SideMenu__text": {
                    fontWeight: 400,
                    color: black,
                    opacity: 0,
                },
                ".SideMenu__item": {
                    minHeight: 50,
                    px: 2.5,
                    justifyContent: "center",
                    '&:hover:not(.SideMenu__item--system)': itemHover,
                    "&--active:not(.SideMenu__item--system)": itemHover,
                    ".SideMenu__icon": {
                        minWidth: 0,
                        justifyContent: "center",
                        color: black,
                        mr: 'auto',
                    },
                    "&--system": {
                        color: black,
                        "&:hover": systemHover,
                        ".SideMenu__icon": {
                            color: black
                        },
                        "&.SideMenu__item--active": systemHover,
                    },
                },
                "&--expanded": {
                    ".SideMenu__text": {
                        opacity: 1,
                        ml: 2
                    },
                    ".SideMenu__item": {
                        justifyContent: "initial",
                    },
                }
            }
        }
    )
})()
