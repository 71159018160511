import MuiBox, { BoxTypeMap } from "@mui/material/Box";
import { OverridableComponent } from "@mui/material/OverridableComponent";


const Box: OverridableComponent<BoxTypeMap<{}, "div">> = (props: any) => {
    return <MuiBox  {...props} />;
};

export default Box;
export { Box };
