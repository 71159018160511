import React from "react";
import MuiStepLabel, { StepLabelProps, } from '@mui/material/StepLabel';

const StepLabel: React.FC<StepLabelProps> = (props) => {
    return <MuiStepLabel {...props} />;
};

export default StepLabel;
export { StepLabel };




