/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoleDto } from '../models/CreateRoleDto';
import type { PrivilegeListRespose } from '../models/PrivilegeListRespose';
import type { UpdateRoleDto } from '../models/UpdateRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RoleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Cria um novo perfil
     * @returns CreateRoleDto
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateRoleDto,
    }): CancelablePromise<CreateRoleDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Lista todos os perfis
     * @returns any
     * @throws ApiError
     */
    public list({
        q,
        format,
        page,
        itemsPerPage,
        sort,
        fields,
    }: {
        q?: string,
        /**
         * ex:selection
         */
        format?: string,
        /**
         * Número da página. Default é 1
         */
        page?: number,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
        sort?: string,
        fields?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role',
            query: {
                'q': q,
                'format': format,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
                'fields': fields,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns PrivilegeListRespose
     * @throws ApiError
     */
    public getPrivileges({
        q,
        page,
        itemsPerPage,
    }: {
        q?: string,
        /**
         * Número da página. Default é 1
         */
        page?: number,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
    }): CancelablePromise<PrivilegeListRespose> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role/privileges',
            query: {
                'q': q,
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }

    /**
     * Retorna um perfil identificado pelo id
     * @returns any
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Atualiza um perfil
     * @returns any
     * @throws ApiError
     */
    public update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateRoleDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/role/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove um perfil
     * @returns any
     * @throws ApiError
     */
    public delete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/role/{id}',
            path: {
                'id': id,
            },
        });
    }

}
