import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PTBR from './locales/pt/pt-br.json';
import ENUS from './locales/en/en-us.json';

const resources = {
    'pt-BR': PTBR,
    'en-US': ENUS,
};

i18n.use(initReactI18next).init({
    fallbackLng: 'pt-BR',
    // debug:true,
    resources,

    lng: navigator.language,
    interpolation: {
        scapeValue: false,
    },
});

const t = i18n.t.bind(i18n);

export { i18n, t };

export const tPrefix = (prefix) => (key) => `${prefix}${key}`