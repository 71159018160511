import { TextFieldProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;

type TypeTextField = {
    props: TextFieldProps;
    style: Interpolation<{
        theme: Theme;
    }>;
};

const TextFieldThemeVariants: TypeTextField[] = [
    {
        props: { variant: 'outlined' },
        style: {
            backgroundColor: peopleFirst.shades.white.value,
            '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                    borderColor: peopleFirst.greys.grey.value,
                },
            },

        },
    },
    {
        props: { variant: 'standard', disabled: true },
        style: {
            "& .MuiInputBase-root.Mui-disabled:before": {
                borderBottom: `solid 1px  ${peopleFirst.greys['lighten-2'].value}`,
            },
            "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                fontSize: '14px',
                marginBottom: '24px',
                marginTop: '6px'
            },
        },
    },
];

export default TextFieldThemeVariants;
export { TextFieldThemeVariants };
