import { ListProperty } from '@people-first/organisms';
import { AvatarUploadField, ChipStatus, FileControl } from '@people-first/molecules';
import { Text } from '@people-first/atoms';
import { OrganizationAccess } from './UserOrganizationAccess.grid';
import { useTranslation } from 'react-i18next';
import { useReactiveDataSource } from '@people-first/data-sources';

const UserDetail = ({ details, readonly = false }: any) => {
    const { t } = useTranslation()
    let {
        picture = '',
        name = '',
        email = '',
        username = '',
        pid = '',
        status = false,
        role = '',
        organizations = [],
    } = details;

    const datasource = useReactiveDataSource(organizations)

    const pictureControl = new FileControl(picture)
        .setType('avatar-upload')
        .setReadonly()

    const userData: any = [
        [t('profile.label_name'), name],
        [t('profile.label_email'), email],
        [t('profile.label_eid'), username],
        [t('profile.label_pid'), pid],
        [t('profile.label_status'), <ChipStatus status={status} />],
        [t('profile.label_profile'), role],
    ];

    return (
        <>
            <AvatarUploadField control={pictureControl} />
            <ListProperty items={userData} />
            <Text my={4} variant='h6'>{t("user.access_details")}</Text>
            <OrganizationAccess dataSource={datasource} readonly />
        </>
    );
};

export default UserDetail;