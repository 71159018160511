import { CircularProgress, Grid } from '../../atom'

type TypeStepperForm = {
	height?: string | number,
};

export default function DisplayLoading({
	height,
}: TypeStepperForm) {
	return (
		<Grid
			item xs={12}
			display='flex'
			justifyContent='center'
			alignItems='center'
			height={height || '67vh'}
		>
			<CircularProgress />
		</Grid>

	)
}
