import MuiLink, { LinkTypeMap } from "@mui/material/Link";
import { OverridableComponent } from "@mui/material/OverridableComponent";


const Link: OverridableComponent<LinkTypeMap<{}, "div">> = (props: any) => {
    return <MuiLink  {...props} />;
};

export default Link;
export { Link };
