import MuiList, { ListTypeMap } from '@mui/material/List';
import { OverridableComponent } from "@mui/material/OverridableComponent";


const List: OverridableComponent<ListTypeMap<{}, "ul">> = (props: any) => {
    return <MuiList  {...props} />;
};

export default List;
export { List };
