import { SxProps, Theme, } from '@mui/material/styles'
import Select from '../../atom/select/Select';
import InputLabel from '../../atom/input-label/InputLabel';
import { InputLabelProps } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '../../atom/form-control/FormControl';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { tokens } from '@people-first/design/tokens';
import { i18n } from '@people-first/i18n';
import { Box, Text } from '@people-first/atoms';
const { peopleFirst } = tokens;

type DropDownType = {
    onChange: SelectInputProps['onChange'],
    placeholder?: InputLabelProps | string | number,
    value: string | number | readonly string[],
    options?: any,
    displayField: string,
    fieldExtracted?: string,
    error?: boolean,
    disabled?: boolean,
    sx?: SxProps<Theme>,
    required?: boolean,
    parentKeyTranslation?: string,
};

export default function DropDown({
    onChange,
    value = '',
    placeholder,
    options,
    displayField,
    fieldExtracted = 'id',
    error = false,
    disabled = false,
    sx,
    required,
}: DropDownType) {

    const _placeholder = i18n.t(`${placeholder}`)

    return (
        <FormControl
            sx={[
                { width: `100%` },
                ...Array.isArray(sx) ? sx : [sx]
            ]}
            required={required}
        >
            <InputLabel placeholder={_placeholder} disabled={disabled} error={error}>{_placeholder}</InputLabel>
            <Select
                MenuProps={{
                    PaperProps: {
                        elevation: 2,
                        sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                                backgroundColor: "transparent !important",
                                color: peopleFirst.custom.primary.value,
                                fontWeight: 600
                            },
                            "& .MuiMenuItem-root": {
                                fontWeight: 400,
                                marginY: '5px'
                            }
                        }
                    }
                }}
                disabled={disabled}
                error={error}
                required={required}
                value={value}
                label={_placeholder}
                onChange={onChange}
            >
                {options.map((option: any) => {
                    const value = option[fieldExtracted];
                    return (
                        <MenuItem key={value} value={value}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Text variant="body2">{`${i18n.t(`${option[displayField]}`)}`}</Text>
                                <Text variant="caption" color="secondary">
                                    {option.caption
                                        ? (<div>{`${i18n.t(`${option.caption}`)}`}</div>)
                                        : null}
                                </Text>
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export { DropDown }