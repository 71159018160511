import { i18n } from "@people-first/i18n";
import { formFactory } from './User.form';
import { BallotOutlined, CheckCircleOutline } from '@mui/icons-material';
import UserDetail from "./UserDetail.component";
import { lookupLabel } from "@people-first/molecules";

export function stepsFactory(initialValue?: any) {
    return {
        form: () => formFactory(initialValue),
        context: {},
        steps: [
            {
                title: i18n.t('general.data'),
                key: 'data',
                icon: <CheckCircleOutline />,
                select: ['picture', 'name', 'username', 'email', 'pid', 'role'],
            },
            {
                title: i18n.t('user.org_level'),
                key: 'organizations',
                icon: <BallotOutlined />,
                select: ['organizations'],
            },
            {
                title: i18n.t('general.revision'),
                key: 'revision',
                icon: <CheckCircleOutline />,
                content: ({ control, context }: any) => {
                    const details = {
                        ...control.value,
                        role: lookupLabel(control.get('role')),
                        organizations: context.data.organizations,
                    };
                    return <UserDetail details={details} readonly />
                },
            },
        ]
    };
}