import { FormFields } from "./FormFields";
import { FormContext } from "./FormContext";

export function FormAccessor({ formControlName }: any) {
  return (
    <>
      <FormContext.Consumer>
        {(form) => FormFields.render(form.get(formControlName))}
      </FormContext.Consumer>
    </>
  );
}
