import { Box } from '../../atom/box/Box';
import { UploadAvatarDescription } from '../../molecule/upload-avatar/UploadAvatarDescription';
import { UploadAvatar } from '../../molecule/upload-avatar/UploadAvatar'

import React, { MutableRefObject } from "react";

type InfoAvatarUploadProps = {
	inputRef?: MutableRefObject<HTMLInputElement | null>;
	infoTextTitle: string;
	infoText: string;
	iconColor: string;
	widthTextPx: string;
	tooltipTitle?: string;
	tooltipContent?: string[];
	photoState: string;
	children?: JSX.Element;
	onUpload: (event: React.SyntheticEvent & { currentTarget: HTMLInputElement }) => void;
};
const InfoAvatarUpload: React.FC<InfoAvatarUploadProps> = ({ infoTextTitle, infoText, iconColor, widthTextPx, photoState, onUpload, inputRef, children, tooltipTitle, tooltipContent }) => {
	return (
		<></>
		// <Box display="flex" alignItems={"center"}>
		// 	<Box marginRight={"32px"}>
		// 		<UploadAvatar photoState={photoState} onUpload={onUpload} inputRef={inputRef} children={children} />
		// 	</Box>
		// 	<InfoText
		// 		iconColor={iconColor}
		// 		infoText={infoText}
		// 		infoTextTitle={infoTextTitle}
		// 		widthPx={widthTextPx}
		// 		tooltipTitle={tooltipTitle}
		// 		tooltipContent={tooltipContent}

		// 	/>
		// </Box>
	);
};

export default InfoAvatarUpload;
export { InfoAvatarUpload };
