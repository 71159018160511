import { i18n } from "@people-first/i18n";
import { useState } from "react";
import { useEffect } from "react";
import { RadioForm as MoleculeRadioForm } from "../../radio-form/RadioForm";
import { RadioGroupControl } from "../RadioGroupControl";
import { InputContainer } from "./InputContainer";

export function RadioGroupField({ control }: { control: RadioGroupControl<any> }) {
  const [options, setOptions] = useState<Array<any>>([]);

  useEffect(() => {
    const unsubscription = control.optionsChange((_options: any) => setOptions(_options.items))
    return () => { unsubscription() }
  }, [])

  useEffect(() => { control.options("").then(_options => setOptions(_options)); }, [options])

  const hasError = control.dirty && Boolean(control.erros);

  return (
    <InputContainer>
      <MoleculeRadioForm
        required={control.required}
        titleMessage={i18n.t(control.label)}
        data={options}
        disabled={control.disabled}
        value={control.value || ""}
        name={control.name}
        key={control.name}
        error={hasError}
        onChange={(event: any) => control.setValue(event.target.value)}
        sx={{ width: '100%' }}
      />
    </InputContainer>
  );
}
