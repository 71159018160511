/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { Organization } from '../models/Organization';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Cria um novo perfil
     * @returns CreateOrganizationDto
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateOrganizationDto,
    }): CancelablePromise<CreateOrganizationDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Lista todos os perfis
     * @returns any
     * @throws ApiError
     */
    public list({
        q,
        format,
        page,
        itemsPerPage,
        sort,
        fields,
    }: {
        q?: string,
        /**
         * ex:selection
         */
        format?: 'org.select',
        /**
         * Número da página. Default é 1
         */
        page?: number,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
        sort?: string,
        fields?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organization',
            query: {
                'q': q,
                'format': format,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
                'fields': fields,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public query(): CancelablePromise<Array<Organization>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organization/find',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateOrganizationDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public delete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

}
