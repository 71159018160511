import { PermissionsContext } from "./Context";
import { WithPermissionOptions } from "./interfaces";
import React from "react";


export function withPermission<T = any>(Component: any, config?: WithPermissionOptions) {
  return (props: { require: WithPermissionOptions } & T) => {
    const _config = (config || props.require)
    return (
      <>
        <PermissionsContext.Consumer>
          {(service) => {
            if (service.canAccess(_config)) {
              return <Component {...props} />;
            } else if (_config?.onDenied) {
              return _config.onDenied(_config);
            } else {
              return null;
            }
          }}
        </PermissionsContext.Consumer>
      </>
    );
  };
}

type WithAccessProps = {
  canAccess: WithPermissionOptions;
  children?: unknown;
};

export const WithAccess: React.FC<WithAccessProps> = (props) => {
  const wrapperContent = () => <>{props.children}</>;
  const Component = withPermission(wrapperContent, props.canAccess);
  return <Component />;
};
