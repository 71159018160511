import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { RoleDataSource } from '@people-first/data-sources';
import { GlobalTemplate } from '@people-first/templates';
import { useStaticProps } from '@people-first/shared';
import { useBreadcrumb } from '@people-first/hooks';
import { Box } from '@mui/material';
import RoleDetail from './RoleDetails.component';

export async function getStaticProps({ params }: any) {
    const { id } = params;
    const role = await RoleDataSource.get({ id }).catch(() => ({}));
    return { role }
}

export function RoleDetailPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const gotoGrid = () => navigate('/role');

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.role'), "/role"],
        [t('role.summary_role_title'), ""],
    ])

    return (
        <>
            <GlobalTemplate>
                <Box width='100%' display="flex" flexDirection="column" >
                    <RoleDetail role={(useStaticProps() as any).role} />
                </Box>
            </GlobalTemplate>
        </>
    );
}

export default {
    getStaticProps,
    component: RoleDetailPage,
}