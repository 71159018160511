import { Suggest } from "src/app/shared";
import { RoleDataSource } from "@people-first/data-sources";
import { i18n } from "@people-first/i18n";

export const createRoleSuggest = () => {
    return new Suggest(
        async (term: string) => {
            const query = { items: [['name', 'contains', term]] };
            const options = {
                query,
                fields: "id,name",
                page: 0,
                itemsPerPage: 20,
            };
            return RoleDataSource.find(options)
                .catch(() => {
                    return { items: [], resultSizeEstimate: 0, }
                })
                .then(({ items, resultSizeEstimate }) => {
                    return {
                        items: items.map((item: any) => ({ label: i18n.t(item.name), value: item.id })),
                        resultSizeEstimate,
                    }
                });
        }, { fetch: "once" }
    );
}
