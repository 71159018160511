import { useState } from "react";
import { useEffect } from "react";
import { DropDown as AtomDropDown } from "../../dropdown/DropDown";
import { InputContainer } from "./InputContainer";
import { SelectControl } from "../SelectControl";

export function DropDownField({ control }: { control: SelectControl<any> }) {
  const [options, setOptions] = useState<any>([])

  useEffect(() => {
    const unsubscription = control.optionsChange((_options: any) => setOptions(_options.items))
    return () => { unsubscription() }
  }, [])

  useEffect(() => {
    control.options("").then(_options => setOptions(_options));
  }, [false])

  const hasError = control.dirty && Boolean(control.erros);

  return (
    <InputContainer>
      <AtomDropDown
        required={control.required}
        key={control.name}
        displayField="label"
        fieldExtracted="value"
        options={options}
        error={hasError}
        placeholder={control.placeholder}
        onChange={(event: any) => control.setValue(event.target.value)}
        disabled={control.disabled}
        value={options.length ? control.value : undefined}
      />
    </InputContainer>
  );
}