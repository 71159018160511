import { useState, useEffect, } from 'react';
import validator from 'validator';
import { useNavigate, useLocation, } from 'react-router-dom';
import { loginAction, cleanLoginErrorAction } from '@people-first/store';
import { useTranslation } from 'react-i18next'

import { LoginTemplate } from '@people-first/templates';
import { Box, TextField, Text, InputAdornment, LoadingButton } from '@people-first/atoms'
import { HeaderLogin, FooterLogin, } from '@people-first/organisms'

import { VisibilityOutlined, VisibilityOffOutlined, PersonOutline } from '@mui/icons-material/';
import { useAppDispatch, useAppSelector } from '@people-first/hooks'
import { tokens } from '@people-first/design/tokens';
import { formLoginFactory } from './Login.form';
import { useForm } from '@people-first/molecules';

type TypeLocation = {
    from: {
        pathname: string,
    }
};

type IState = {
    EID: string;
    password: string;
    showPassword: boolean;
};

const { peopleFirst } = tokens;

export default function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation()
    const { form: loginControls } = useForm(formLoginFactory)
    const { loading } = useAppSelector((state) => state.session)
    const [isLoading, setIsLoading] = useState(loading);

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            const from = (location.state as TypeLocation)?.from?.pathname || '/'
            navigate(from, { replace: true })
        }
    }, [navigate, loginControls.value])

    const handleLogin = () => {
        setIsLoading(true);
        dispatch(loginAction(loginControls.value))
            .finally(() => setIsLoading(false))
    };

    const handleGoPasswordRecovery = () => {
        navigate('/forgot-password');
        dispatch(cleanLoginErrorAction())
    };

    const dispatchLoginIfValid = ({ key }: any) => {
        if ((key === 'Enter') && (loginControls.valid)) handleLogin()
    };

    const { EID, password, displayPassword } = loginControls.getControls()

    return (
        <>
            <LoginTemplate
                header={<HeaderLogin />}
                footer={<FooterLogin />}
                form={
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="368px"
                        >
                            <TextField
                                onKeyDown={dispatchLoginIfValid}
                                disabled={isLoading}
                                required
                                variant="outlined"
                                label={t("login.placeholder_eid")}
                                placeholder={t("login.placeholder_eid")}
                                sx={{
                                    width: '368px',
                                    marginBottom: '32px',
                                    '& .MuiFormHelperText-root.Mui-error': {
                                        backgroundColor: '#F0F6F9 ',
                                        width: '100%',
                                        margin: '0 auto',
                                        paddingLeft: 0,
                                        borderRadius: '4px',
                                    },
                                }}
                                value={EID.value}
                                onChange={(event) => EID.setValue(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonOutline />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                onKeyDown={dispatchLoginIfValid}
                                disabled={isLoading}
                                required
                                variant="outlined"
                                label={t("login.placeholder_password")}
                                placeholder={t("login.placeholder_password")}
                                value={password.value}
                                type={displayPassword.value ? "text" : "password"}
                                onChange={(event) => password.setValue(event.target.value)}
                                sx={{
                                    maxlength: 16,
                                    width: '368px',
                                    '& .MuiFormHelperText-root.Mui-error': {
                                        backgroundColor: '#F0F6F9 ',
                                        width: '100%',
                                        margin: '0 auto',
                                        paddingLeft: 0,
                                        borderRadius: '4px',
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            {displayPassword.value ? (
                                                <VisibilityOutlined onClick={() => displayPassword.setValue(false)} />
                                            ) : (
                                                <VisibilityOffOutlined onClick={() => displayPassword.setValue(true)} />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            width="368px"
                            mt="10px"
                            mb="34px"
                        >
                            <Text
                                variant="caption"
                                color="primary"
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={handleGoPasswordRecovery}
                            >
                                {t('login.forgot_password')}
                            </Text>
                        </Box>
                        <Box
                            mb="26px"
                            display="flex"
                            justifyContent="center"
                            width="368px"
                        >
                            <LoadingButton
                                loading={isLoading}
                                disabled={loginControls.invalid}
                                variant="contained"
                                size="medium"
                                sx={{
                                    width: '193px',
                                    '	.MuiLoadingButton-loadingIndicator': {
                                        color: peopleFirst.custom.primary.value,
                                    },
                                }}
                                onClick={handleLogin}
                            >
                                {t('login.button_access')}
                            </LoadingButton>
                        </Box>
                    </>
                }
            />
        </>
    );
}