import Box from '../../atom/box/Box';
import Text from '../../atom/text/Text';
import AccentureLogo from '../../../asset/logotype/Acc_Logo_Black_Purple_RGB.svg';
import {useTranslation} from 'react-i18next'


export default function ContentBreakpoint() {
    const { t } = useTranslation()
    return (
        <>
              <Box mb={3} width='100%'>
                <Text variant="body1" color="secondary"  flexWrap='wrap'>
                    {t('breakpoint.mobile_version')}
                </Text>
                </Box>

                <Box mb={8}>
                <Text variant="body1" color="secondary" flexWrap='wrap'>
                  {t('breakpoint.right_device_access')} 
                </Text>
                </Box>
                 <Box
                    display="flex"
                    flexDirection="column"
                    component="img"
                    width="105.13px"
                    sx={{ marginBottom: '50px', marginRight: '74.6px' }}
                    src={AccentureLogo}
                    alt={t("logotype.alt_text")}
                /> 
           
           </>
    );
}
