import { GlobalTemplate } from '@people-first/templates';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '@people-first/hooks';

export default function NotFound() {
    const { t } = useTranslation();

    useBreadcrumb([
        [`${t('not_found.page_not_exists')}`, ""]
    ])

    return (
        <GlobalTemplate>
            <></>
        </GlobalTemplate>
    );
}
