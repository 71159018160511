import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    android: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    fullHd: true;

  }
}

const BreakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      android: 380,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      fullHd: 1920
    },
  },
});

export default BreakpointsTheme;
export { BreakpointsTheme };
