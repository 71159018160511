import { createReducer } from '@reduxjs/toolkit';
import { getOrganizationAction, saveOrganizationAction, updateOrganizationGridAction } from '../actions';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const organizationsReducer = createReducer<TypeDialogLogout>(initialState, {
  [getOrganizationAction.fulfilled.type]: (state) => ({ ...state, blocked: true }),
  [saveOrganizationAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [updateOrganizationGridAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
})