import { createReducer } from '@reduxjs/toolkit';
import { getMenuAction, saveMenuAction, updateMenuGridAction } from '../actions';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const menuReducer = createReducer<TypeDialogLogout>(initialState, {
  [getMenuAction.fulfilled.type]: (state) => ({ ...state, blocked: true }),
  [saveMenuAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [updateMenuGridAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
})