import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { UserDataSource } from '@people-first/data-sources';
import { FormStepTemplate } from '@people-first/templates';
import { stepsFactory } from './User.steps';
import { useBreadcrumb } from '@people-first/hooks';
import { ContextVariant, useStaticProps } from '@people-first/shared';


const PageVariants = new ContextVariant(
    {
        new: {
            "breadcrumb.segment": "user.new_user",
            "form.title": "menu.new_user",
        },
        update: {
            "breadcrumb.segment": "user.update_user",
            "form.title": "user.update_user",
        }
    }
)

export async function getStaticProps({ params }: any) {
    const { id } = params;
    const isUpdate = id !== "new";
    const data = isUpdate
        ? await UserDataSource.get({ id }).catch(() => ({}))
        : {};
    return {
        variant: PageVariants.get([id, "update"]),
        steps: () => stepsFactory(data)
    }
}

export function UserForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { steps, variant } = (useStaticProps() as any)
    const gotoGrid = () => navigate('/user');

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.users'), "/user"],
        [t(variant.get("breadcrumb.segment")), ""],
    ])

    return (
        <>
            <FormStepTemplate
                formFactory={steps}
                width='100%'
                title={variant.get("form.title")}
                onBackNavigation={gotoGrid}
                afterSubmit={gotoGrid}
                dataSource={UserDataSource}
            />
        </>
    );
}

export default {
    getStaticProps,
    component: UserForm,
}