import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Autocomplete as AtomAutocomplete } from "../../../atom/autocomplete/Autocomplete";
import { SelectControl } from "../SelectControl";
import { InputContainer } from "./InputContainer";

export function AutocompleteField({ control }: { control: SelectControl<any> }) {
    const [options, setOptions] = useState<any>([])

    useEffect(() => {
        const unsubscription = control.optionsChange((_options: any) => setOptions(_options.items))
        return () => { unsubscription() }
    }, [])

    useEffect(() => { control.options("").then(_options => setOptions(_options)); }, [options])

    const hasError = control.dirty && Boolean(control.erros);

    return (
        <InputContainer>
            <AtomAutocomplete
                // value={control.value}
                // inputValue=""
                options={options}
                required={control.required}
                key={control.name}
                // name={control.name}
                error={hasError}
                // helperText={helperText}
                // variant={'outlined'}
                multiple={control.multiple}
                label={t(control.label)}
                placeholder={t(control.placeholder)}
                defaultValue={control.value}
                onChange={(_: any, value: any) => control.setValue(value)}
                onInputChange={(_: any, term: string) => control.updateOptionsWith(term)}
                disabled={control.disabled}
            />
        </InputContainer>
    );
};