import { createReducer } from '@reduxjs/toolkit';
import { closeLogoutDialog, openLogoutDialog } from '../actions/dialog-logout';

export type TypeDialogLogout = {
  isOpen: boolean,
}

const initialState: TypeDialogLogout = {
  isOpen: false
}

export const dialogLogoutReducer = createReducer<TypeDialogLogout>(initialState, {
  [openLogoutDialog.type]: (state) => ({ ...state, isOpen: true }),
  [closeLogoutDialog.type]: (state) => ({ ...state, isOpen: false }),
})