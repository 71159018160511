import Checkbox from '@mui/material/Checkbox';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { SxProps, Theme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type CheckboxesTagsType = {
    onChange?: Function,
    onInputChange?: Function,
    label: string,
    value?: string | number | readonly string[],
    displayField?: string,
    options: any,
    placeholder?: string,
    fieldExtracted?: string,
    error?: boolean,
    disabled?: boolean,
    sx?: SxProps<Theme>,
    required?: boolean,
    parentKeyTranslation?: string,
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function CheckboxesTags(props: CheckboxesTagsType) {
    return (
        <Autocomplete
            {...props}
            multiple={true}
            renderOption={(props: any, option: any, { selected }: any) => (
                <li {...props} key={option.value}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
        />
    );
}