import { FormControl } from "./FormControl";

export class FileControl extends FormControl {
    public multiple = false;

    public accept?: string

    public uploadHandler!: (files: FileList) => any;

    /**
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
     * @param allowedTypes 
     */
    public setAccept(allowedTypes: string): this {
        this.accept = allowedTypes;
        return this;
    }

    public setMultiple(multiple = true): this {
        this.multiple = multiple;
        return this;
    }

    public setUploadHandler(handler: any): this {
        this.uploadHandler = handler;
        return this;
    }
}