import { Suggest } from "src/app/shared";

const types: Array<any> = [
    { label: 'Roster', value: 'roster' }
];

export const createLoadingDataTypesSuggest = () => {
    return new Suggest(
        async (term: string) => {
            const items = types.filter(({ label }: any) => label.includes(term));
            return {
                items,
                resultSizeEstimate: items.length
            }
        },
        { fetch: "once" }
    );
}