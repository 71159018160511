import { createReducer } from '@reduxjs/toolkit';
import { blockForSmallDevicesAction, unblockForSmallDevicesAction } from '../actions/block-small-devices';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const blockSmallDevicesReducer = createReducer<TypeDialogLogout>(initialState, {
  [blockForSmallDevicesAction.type]: (state) => ({ ...state, blocked: true }),
  [unblockForSmallDevicesAction.type]: (state) => ({ ...state, blocked: false }),
})