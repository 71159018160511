import { Components, Theme } from '@mui/material';
import AlertThemeVariants from '../../atom/alert/AlertThemeVariants';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootAlertTheme: Components<Omit<Theme, 'components'>> = {
    MuiAlert: {
        variants: AlertThemeVariants,
        styleOverrides: {
            root: {
                color: peopleFirst.shades.white.value,
                letterSpacing: '0.1px',
                lineHeight: '1.375rem',
                fontWeight: 400,
                width: '470px',
                borderRadius: '4px',
                '& .MuiAlert-icon': {
                    fontSize: '24px',
                },
                '& button': {
                    ':hover': {
                        background: 'transparent',
                    },
                    ':active': {
                        background: 'transparent',
                    },
                    marginTop: '-5px',
                    marginRight: '20px',
                },
            },
        },
    },
};

export default RootAlertTheme;
export { RootAlertTheme };
