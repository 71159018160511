import { Box, Grid, Step, StepConnector, StepLabel, Stepper } from '@people-first/atoms';
import { FormGroup, DisplayLoading, useStepForm } from '@people-first/molecules';
import { GlobalTemplate } from '../global/GlobalTemplate';
import { Avatar, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack, CheckCircleOutline, CreateOutlined } from '@mui/icons-material';
import React, { MouseEventHandler, useState } from 'react';
import { DataSource } from '@people-first/data-sources';
import { tokens } from '@people-first/design/tokens';
import { i18n, t } from '@people-first/i18n';
import { stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material';

export type FormTemplateType = {
    // children: JSX.Element;
    title?: string;
    goBackButton?: JSX.Element;
    saveButton?: JSX.Element;
    isLoading?: boolean;
    width: string; // sempre ajustar de acordo com a largura dos inputs
    // todo: tornar obrigatório
    formFactory?: () => FormGroup<any, any>;
    onBackNavigation?: () => void;
    dataSource?: DataSource<unknown>
    afterSubmit?: () => void
};

export const FormStepContext = React.createContext<any>(null);

const { peopleFirst } = tokens;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: peopleFirst.custom.primary.value,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: peopleFirst.custom.primary.value,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: peopleFirst.greys['lighten-2'].value,
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const Round = (icon: any, active = false) => {
    return (
        <Avatar
            sx={{
                background: active ? peopleFirst.custom.primary.value : peopleFirst.greys['lighten-2'].value
            }}
        >
            {icon}
        </Avatar>
    );
}
const StepsBar = ({ steps = [], active = 0 }: any) => {
    return <Stepper
        activeStep={active}
        // nonLinear={nonLinear}
        connector={<QontoConnector />}
        sx={{
            // position: 'absolute',
            boxShadow: 4,
            padding: '24px',
            marginY: '32px',
            // zIndex: 999999,
            backgroundColor: '#fff',
            "& .Mui-disabled .MuiStepIcon-root": {
                color: peopleFirst.greys['lighten-2'].value,
            },
            ".Mui-disabled .MuiStepIcon-text": {
                fill: peopleFirst.greys['lighten-1'].value
            },
            // zIndex: 'modal',
        }}
    >
        {steps.map(({ title, icon }: any, index: number) => {
            if (index === active) { icon = <CreateOutlined /> }
            else if (index < active) { icon = <CheckCircleOutline /> }
            const iconActive = index === active || index < active;
            return (
                <Step key={title}>
                    <StepLabel icon={Round(icon, iconActive)} >{title}</StepLabel>
                </Step>
            );
        })}
    </Stepper>
}

const backButtonFactory = (onClick?: MouseEventHandler) => (
    <Button sx={{ marginTop: 0 }} startIcon={<ArrowBack />} onClick={onClick}>{t<string>('button.go_back')}</Button>
)


const FormStepTemplate = ({
    formFactory,
    title,
    goBackButton,
    saveButton,
    width,
    // children,
    onBackNavigation,
    afterSubmit,
    dataSource,
}: FormTemplateType) => {
    const context = useStepForm(formFactory!);
    const [isLoading, setIsLoading] = useState(false);

    const {
        steps,
        nextStep,
        prevStep,
        control,
        active,
        namespace,
        isFirstStep,
        isLastStep,
        content,
        destroy,
    } = context;

    const backButton = isFirstStep
        ? (onBackNavigation ? backButtonFactory(onBackNavigation) : null)
        : backButtonFactory(prevStep)


    const submitForm = () => {
        setIsLoading(true);
        dataSource?.save!(context.form.value)
            .then(() => afterSubmit!())
            .finally(() => {
                destroy()
                setIsLoading(false)
            })
    }
    let nextAction;
    let nextActionLabel;

    if (isLastStep) {
        nextAction = submitForm;
        nextActionLabel = "general.form_step_save"
    } else {
        nextAction = nextStep;
        nextActionLabel = "general.form_step_next"
    }

    return (
        <GlobalTemplate>
            {isLoading ? (
                <DisplayLoading />
            ) : (
                <>
                    <FormStepContext.Provider value={context}>
                        <Box display="flex" flexDirection="column" width={width} mb="110px">
                            <Box display="flex" flexDirection="column" width={width} zIndex={1}>
                                <StepsBar active={active} steps={steps} />
                                {content()}
                            </Box>
                        </Box>
                        <Grid
                            item
                            xs={11}
                            width="100%"
                            display="flex"
                            position="fixed"
                            justifyContent={backButton ? "space-between" : "end"}
                            alignItems="flex-start"
                            paddingRight="64px"
                            paddingBottom="56px"
                            paddingTop="16px"
                            sx={{
                                top: 'auto',
                                bottom: 0,
                                backgroundColor: '#fff',
                                zIndex: 2,
                            }}
                        >
                            {backButton}
                            <LoadingButton
                                // loading={isLoadingButton}
                                sx={{ marginTop: 0 }}
                                disabled={!(control.dirty && control.valid)}
                                variant='contained'
                                onClick={nextAction}
                            >
                                {t<string>(nextActionLabel)}
                            </LoadingButton>
                        </Grid>
                    </FormStepContext.Provider>
                </>
            )}
        </GlobalTemplate>
    );
};

export { FormStepTemplate };