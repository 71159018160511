import { Components, Theme } from '@mui/material';
import AvatarThemeVariants from '../../atom/avatar/AvatarThemeVariants';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootAvatarTheme: Components<Omit<Theme, 'components'>> = {
    MuiAvatar: {
        variants: AvatarThemeVariants,
        styleOverrides: {
            root: {
                background: peopleFirst.custom.primary.value,
                width: '36px',
                height: '36px',
            }

        }
    }
}

export default RootAvatarTheme