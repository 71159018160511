import { InputContainer } from "./InputContainer";
import { UploadAvatar } from "../../upload-avatar/UploadAvatar";

export const AvatarUploadField: React.FC<any> = ({ control }: any) => {
    const { erros } = control;
    const hasError = control.dirty && Boolean(erros);

    return (
        <InputContainer>
            <UploadAvatar
                error={hasError}
                value={control.value}
                label={control.label}
                description={control.description}
                doUpload={control.uploadHandler?.bind(control)}
                onChange={(value: any) => control.setValue(value)}
            />
        </InputContainer>
    );
};