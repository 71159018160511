import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert ref={ref} variant="filled" {...props} />;
});

export default Alert;
export { Alert };

// https://mui.com/material-ui/api/alert/
