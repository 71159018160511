
import { Components, Theme } from '@mui/material';

const RootMenuItemTheme: Components<Omit<Theme, 'components'>> = {
    MuiMenuItem: {
        styleOverrides: {
        },
    },
};

export default RootMenuItemTheme;
export { RootMenuItemTheme };