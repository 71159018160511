/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMenuDto } from '../models/CreateMenuDto';
import type { UpdateMenuDto } from '../models/UpdateMenuDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MenuService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create new menu
     * @returns CreateMenuDto
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateMenuDto,
    }): CancelablePromise<CreateMenuDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/menu',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List all menus
     * @returns any
     * @throws ApiError
     */
    public list({
        q,
        format,
        page,
        itemsPerPage,
        sort,
        fields,
    }: {
        q?: string,
        /**
         * ex:selection
         */
        format?: string,
        /**
         * Número da página. Default é 1
         */
        page?: number,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
        sort?: string,
        fields?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/menu',
            query: {
                'q': q,
                'format': format,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
                'fields': fields,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retorna um menu identificado pelo id
     * @returns any
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/menu/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Atualiza um menu
     * @returns any
     * @throws ApiError
     */
    public update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateMenuDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/menu/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove um menu
     * @returns any
     * @throws ApiError
     */
    public delete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/menu/{id}',
            path: {
                'id': id,
            },
        });
    }

}
