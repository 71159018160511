
import * as yup from "yup";
import { AbstractControl } from "./AbstractControl";

export const yupResolve = (rule: yup.BaseSchema) => {
    return (value: unknown): Error | string | null => {
        try {
            rule.validateSync(value);
            return null;
        } catch (error: unknown) {
            return (error as yup.ValidationError).errors.join("");
        }
    };
};

export function ifRequired(rule: yup.BaseSchema) {
    return (value: any, control: any) => {
        return (control.required) ? yupResolve(rule)(value) : null;
    }
}

export function ifEnabled(rule: yup.BaseSchema) {
    return (value: any, control: AbstractControl<any, any, any>) => {
        return (control.disabled) ? null : yupResolve(rule)(value);
    }
}

export function ifDisabled(rule: yup.BaseSchema) {
    return (value: any, control: AbstractControl<any, any, any>) => {
        return (control.disabled) ? yupResolve(rule)(value) : null
    }
}

export const numberRequired = yup.number().required("input_required")
export const numberRequiredValidator = yupResolve(numberRequired)

export const positiveNumbers = yup.number().positive("positive_number").required("input_required")
export const positiveNumbersValidator = yupResolve(positiveNumbers)

export const stringRequired = yup.string().required("input_required")

export const stringRequiredValidator = yupResolve(stringRequired)

export const booleanRequired = yup.boolean().required("required")
export const booleanRequiredValidator = yupResolve(booleanRequired)