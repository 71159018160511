import * as React from 'react';
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { PeopleFirstLogo } from "@people-first/assets";
import { UserProfileCard } from '@people-first/molecules';
import { tokens } from '@people-first/design/tokens';
import { Icon } from '@people-first/atoms';
import { PermissionService } from '@people-first/shared';
import clsx from 'clsx';
import { menuItemSytle } from './SideMenuTemplate.style';
import { setActiveMenuAction, store, toggleMainMenuAction } from '@people-first/store';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 250;

const { peopleFirst } = tokens;

const filterMenu = (menus: any) => {
    return menus.map((menu: any) => {
        let children;

        if (menu.type === "divider") return menu;
        if (menu.children) children = filterMenu(menu.children);

        return PermissionService.canAccess(menu.withAccess) || Boolean(children?.length)
            ? { ...menu, children }
            : null;

    }).filter(Boolean)
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `65px`,
    [theme.breakpoints.up("sm")]: {
        width: `60px`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function SideMenu() {
    const { mainMenu } = useSelector((state: any) => state.app);
    const menu = filterMenu(mainMenu.items);
    return (
        <Box display={'flex'} sx={menuItemSytle} >
            <AppBar position="fixed" variant='outlined' color="inherit" elevation={0}>
                <Toolbar disableGutters>
                    <Box
                        display="flex"
                        width="100%"
                        height="70px"
                        alignItems="center"
                    >
                        <Box ml="25px" mt="28.5px" mb="28.5px">
                            <IconButton
                                sx={{ color: peopleFirst.shades.black.value }}
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => store.dispatch(toggleMainMenuAction())}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{ ml: 1, flexGrow: 1 }}
                            display="flex"
                            justifyContent="flex-start"
                        >
                            <img src={PeopleFirstLogo} width="153" />
                        </Box>
                        <Box mr="64px">
                            <UserProfileCard />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                open={mainMenu.expanded}
                className={clsx('SideMenu__Drawer', { 'SideMenu__Drawer--expanded': mainMenu.expanded })}
            >
                <DrawerHeader>
                    <IconButton />
                </DrawerHeader>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <MenuList items={menu} expanded={mainMenu.expanded} />
                </List>
            </Drawer>
        </Box >
    );
}

const MenuList: React.FC<any> = ({ items, expanded }: any) => {
    return items?.map((menu: any, idx: number) => {
        if (menu.type === "divider") {
            // avoid to render divider twice
            return (idx > 0 && items.at(idx - 1)?.type !== "divider")
                ? (<Divider key={menu.id} sx={{ my: '14px', mx: '12px' }} />)
                : null;
        } else {
            return (<MenuItem key={menu.id} menu={menu} expanded={expanded} />)
        }
    });
};

const MenuItem: React.FC<any> = ({ menu, expanded }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handlerClick = () => {
        store.dispatch(setActiveMenuAction(menu))
        store.dispatch(toggleMainMenuAction())
        if (menu.path) navigate(menu.path);
    }
    return (
        <>
            <ListItemButton
                onClick={handlerClick}
                className={clsx('SideMenu__item', {
                    'SideMenu__item--active': menu.active,
                    'SideMenu__item--system': menu.theme === 'system',
                })}
            >
                {
                    menu.icon
                        ? (<Icon className={clsx('SideMenu__icon')} iconName={menu.icon} />)
                        : null
                }

                <ListItemText
                    primary={t(menu.title)}
                    className={clsx('SideMenu__text')}
                    primaryTypographyProps={{ variant: 'body2' }}
                />
                {(
                    menu.children && expanded
                        ? (menu.open
                            ? <ExpandLess />
                            : <ExpandMore />
                        )
                        : null
                )}
            </ListItemButton>
            <List
                component="div"
                disablePadding
                className={clsx('SideMenu__submenu', { 'SideMenu__submenu--expanded': menu.open })}
            >
                <MenuList items={menu.children} />
            </List>
        </>
    )
}