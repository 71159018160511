
import Home from "./home/Home";
import GridOrganizationPage from './organization/GridOrganization.page';
import NewOrganizationPage from './organization/Organization.page';
import FormRolePage from './role/Role.page'

import GridRole from './role/GridRole.page';
import GridUser from './user/GridUser.page';
import NotFound from './not-found/NotFound';

import { Layout } from "src/app/template/layout/LayoutTemplate";
import { AuthGuardRoutes } from "./login/AuthGuardRoutes";
import UserForm from "./user/User.page";
import UserDetailPage from "./user/UserDetails.page";
import RoleDetailPage from "./role/RoleDetails.page";
import DataLoadPage from "./loading-data/LoadingDataGrid.page";
import LoadingDataCreatePage from "./loading-data/LoadingDataCreate.page";
import { OnAccessDeniedHandler, WithPermissionOptions } from "@people-first/shared";
import { NoAccessPage } from "./no-access/NoAccess.page";

// todo:mover pra outro arquivo
export interface Route {
    title?: string;
    component?: React.FC | (() => JSX.Element) | { getStaticProps: (params: any) => any, component: any };
    laod?: () => Promise<any>;
    withAccess?: WithPermissionOptions
    children?: Routes;
}
// todo:mover pra outro arquivo
export type Routes = { [key: string]: Route }
export interface PageExtras { onAccessDenied: OnAccessDeniedHandler };
// todo:mover pra outro arquivo
export class Pages {
    protected pages = new Map()
    constructor(public routes: Routes, public extra: PageExtras) {
        this.list()
    }

    public list(): Array<{ title: string, path: string }> {
        const flatRoutes = (routes: any, base: string) => {
            return Object.entries(routes).reduce((list: any, [segment, route]: any) => {
                const { title, children, withAccess } = route;
                if (title) {
                    const page = {
                        title,
                        withAccess,
                        path: [base, segment].filter(Boolean).join('/')
                    };
                    list = [...list, page]
                    this.pages.set(title, page)
                }
                if (children) list = [...list, ...flatRoutes(children, segment)]
                return list
            }, [])
        }
        return flatRoutes(this.routes, "")
    }

    get(id: string) {
        return this.pages.get(id);
    }
}

export const pages = new Pages(
    {
        // Exemplo de rota lazyload
        // "/sandbox": {
        //     title: 'page.sandbox',
        //     laod: () => import("./sandbox/Index"),
        //     withAccess: { anyOf: ["sandbox:*"] }
        // },
        "": {
            component: AuthGuardRoutes,
            children: {
                "": {
                    component: Layout,
                    children: {
                        "/": {
                            title: 'page.home',
                            component: Home,
                            withAccess: {
                                anyOf: ["home:All"]
                            }
                        },
                        "/organization": {
                            title: 'page.organization',
                            component: GridOrganizationPage,
                            withAccess: {
                                anyOf: [
                                    "configuration.organization:All",
                                    "configuration.organization:Read"
                                ]
                            }
                        },
                        "/organization/:id": {
                            title: 'page.organization_form',
                            component: NewOrganizationPage,
                            withAccess: {
                                anyOf: [
                                    "configuration.organization:All",
                                    "configuration.organization:Create",
                                    "configuration.organization:Edit"
                                ]
                            }
                        },
                        "/role": {
                            title: 'page.role',
                            component: GridRole,
                            withAccess: {
                                anyOf: [
                                    "configuration.profile:All",
                                    "configuration.profile:Read"
                                ]
                            }
                        },
                        "/role/:id": {
                            title: 'page.role_form',
                            component: FormRolePage,
                            withAccess: {
                                anyOf: [
                                    "configuration.profile:All",
                                    "configuration.profile:Create",
                                    "configuration.profile:Edit",
                                ]
                            },
                        },
                        "/role/details/:id": {
                            title: 'page.role_details',
                            component: RoleDetailPage,
                            withAccess: {
                                anyOf: [
                                    "configuration.profile:All",
                                    "configuration.profile:Read",
                                ]
                            },
                        },
                        "/user": {
                            title: 'page.user',
                            component: GridUser,
                            withAccess: {
                                anyOf: [
                                    "configuration.user:All",
                                    "configuration.user:Read",
                                ]
                            },
                        },
                        "/user/:id": {
                            title: 'page.user_form',
                            component: UserForm,
                            withAccess: {
                                anyOf: [
                                    "configuration.user:All",
                                    "configuration.user:Create",
                                    "configuration.user:Edit",
                                ]
                            },
                        },
                        "/user/profile/:id": {
                            title: 'page.user_profile',
                            component: UserDetailPage,
                            withAccess: {
                                anyOf: [
                                    "configuration.user:All",
                                    "configuration.user:Read",
                                ]
                            },
                        },
                        "/loading-data": {
                            title: 'page.loading_data',
                            component: DataLoadPage,
                            withAccess: {
                                anyOf: [
                                    "dataLoad:All",
                                    "dataLoad:Read",
                                ]
                            },
                        },
                        "/loading-data/:id": {
                            title: 'page.loading_data_create',
                            component: LoadingDataCreatePage,
                            withAccess: {
                                anyOf: [
                                    "dataLoad:All",
                                    "dataLoad:UploadRoster",
                                    "dataLoad:UploadSupplyProject",
                                    "dataLoad:UploadEngagementSurvey",
                                ]
                            },
                        },
                        "*": { component: NotFound },
                    }
                }
            }
        },
        "*": { component: NotFound },
    },
    {
        onAccessDenied: () => <NoAccessPage />
    }
)
