import React from "react";
import { GridFilterModel, GridSortDirection } from "@mui/x-data-grid";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { GridColumns } from "@mui/x-data-grid-pro";
import { DataGridModel } from "./DataGridModel";
import { DataGridEvent } from "./DataGridEvent";


export class DataGrid extends React.Component<{ model: DataGridModel, persistPagination?: string }> {
    protected model: DataGridModel;
    constructor(props: any) {
        super(props);
        this.model = props.model.bind(this);
        // when set page action is emited
        this.model.on("page.change", (event) => this.model.gotoPage(event.detail));

        // when set query action is emited
        this.model.on("query.change", (event: DataGridEvent<GridFilterModel>) => {
            try {
                this.model.setQuery(
                    {
                        items: event.detail?.items.map(({ id, columnField, operatorValue, value }) => [
                            columnField,
                            operatorValue,
                            value,
                        ])
                    }
                )
            } catch (error) {
                console.error(error)
            }
        });
        // when set sort action is emited
        this.model.on(
            "sort.change",
            ({
                detail,
            }: DataGridEvent<Array<{ field: string; sort: GridSortDirection }>>) => {
                if (detail)
                    this.model.setSort(
                        Object.fromEntries(detail!.map(({ field, sort }) => [field, sort]))
                    );
            }
        );
        this.model.on(
            "columns.change",
            ({ detail }: DataGridEvent<{ [key: string]: boolean }>) => {
                if (detail) this.model.setActiveColumns(detail);
            }
        );
    }
    render() {
        const { rows, resultSizeEstimate, visibleColumns, loading, emit } = this.model;
        const { pagination } = this.model;
        const sortModel = Object.entries(pagination.sort as any).map(
            ([field, sort]: any) => ({ field, sort })
        );

        return (
            <>
                <MuiDataGrid
                    loading={loading}
                    rows={rows}
                    columns={visibleColumns as GridColumns}
                    disableSelectionOnClick
                    rowCount={resultSizeEstimate}
                    pagination={true}
                    rowHeight={48}
                    autoHeight={false}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    sortModel={sortModel}
                    density={'standard'}
                    keepNonExistentRowsSelected
                    checkboxSelection={this.model.checkboxSelection}
                    // selectionModel={this.model.selectection}
                    onSelectionModelChange={(event) => emit("selection.change", event)}
                    onRowClick={(event) => emit("row.click", event)}
                    onFilterModelChange={(filter) => emit("query.change", filter)}
                    onPageChange={(page) => emit("page.change", page)}
                    onSortModelChange={(sort) => emit("sort.change", sort)}
                    sortingMode="server"
                    paginationMode="server"
                    filterMode="server"
                    hideFooterSelectedRowCount
                    onColumnVisibilityModelChange={(columns) =>
                        emit("columns.change", columns)
                    }
                    // components={{
                    //   NoRowsOverlay: CustomNoRowsOverlay,
                    // }}
                    // componentsProps={componentsProps}
                    initialState={{

                        sorting: { sortModel },
                        // filter: {
                        //   filterModel
                        // },
                        pagination: {
                            page: pagination.page as number,
                        },
                    }}
                />
            </>
        );
    }
}



// export function DataGrid(props: any) => {
//     const screenWidth = useScreenWidth();
//     return <DataGridComponent width={screenWidth} {...props} />;
// }