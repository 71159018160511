import { Components, Theme } from '@mui/material';
import IconButtonThemeVariants from '../../atom/icon-button/IconButtonThemeVariants';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootIconButtonTheme: Components<Omit<Theme, 'components'>> = {
    MuiIconButton: {
        variants: IconButtonThemeVariants,
        styleOverrides: {
            root: {
            },
        },
    }
}

export default RootIconButtonTheme;
export { RootIconButtonTheme };
