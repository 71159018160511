import React from "react";
import { FormSetValueOptions } from "./FormSetValueOptions";

export abstract class AbstractControl<T, R, Err> {
  //todo:checar todos as tipagens
  public parent: AbstractControl<any, any, unknown> | undefined; //FormArray<T>

  public label = "";
  public description = "";
  public placeholder = "";
  public type = "text";
  public name = "";
  public readonly: boolean = false;
  public renderComponent: React.FC<any> | null = null;
  public get erros(): Err | null {
    return this._erros;
  }
  public set erros(value: Err | null) {
    this._erros = value;
  }

  protected _valid = true;


  protected _dirty = false;

  protected handlers: {
    change: Array<(value: any) => void>;
  } = {
      change: [],
    };

  protected notify = {
    change: (ref: any, extras: FormSetValueOptions = {}) => {
      const { emitEvent, onlySelf } = {
        emitEvent: true,
        onlySelf: false,
        ...extras,
      };
      if (!emitEvent) return;
      const value = this.value;
      this.handlers.change.forEach((f) => f(value));
      if (!onlySelf && ref.parent) ref.parent.notify.change(ref.parent);
    },
  };

  public bind(comp: any): this {
    this.valueChange(() => comp.setState((prev: any) => ({ ...prev })));
    return this;
  }

  public renderAs(component: React.FC<any>): this {
    this.renderComponent = component;
    return this;
  }


  public disabled = false;

  public disable(): void {
    this.disabled = true;
  }

  public enable(): void {
    this.disabled = false;
  }

  public get dirty() {
    return this._dirty
  }

  public markAsDirty(): this {
    return this.setDirty()
  }

  public setDirty(dirty = true): this {
    this._dirty = dirty;
    return this;
  }

  public setReadonly(readonly = true): this {
    this.readonly = readonly;
    return this;
  }

  public setDisabled(handler: (value: T) => boolean): this {
    Object.defineProperty(this, 'disabled', {
      get() { return handler(this.value) },
      set() { }
    })
    return this;
  }

  public abstract get value(): R;

  public setErros(erros: Err | null) {
    this._valid = !(this.erros = erros);
  }

  public abstract setValue(value: unknown, extras?: FormSetValueOptions): void;

  public abstract patchValue(
    value: unknown,
    extras?: FormSetValueOptions
  ): void;

  public valueChange(handler: (value: any) => void): this {
    this.handlers.change.unshift(handler);
    return this;
  }


  public setName(name: string): this {
    this.name = name;
    return this;
  }
  public setLabel(label: string): this {
    this.label = label;
    return this;
  }
  public setDescription(description: string): this {
    this.description = description;
    return this;
  }

  public setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder;
    return this;
  }
  public setType(type: string): this {
    this.type = type;
    return this;
  }


  public abstract reset(): boolean;

  public get valid() {
    return this._valid;
  }

  public set valid(valud) {
    this._valid = valud;
  }

  public get invalid() {
    return !this.valid;
  }

  private _erros: Err | null = null;
}
