import { InputContainer } from "./InputContainer";
import { FormField } from "../types";
import { Box, Text, TextField } from "@people-first/atoms";
import { useTranslation } from "react-i18next";
import { DataGrid, DataGridModel, useDataGrid } from "@people-first/organisms";
import { i18n } from "@people-first/i18n";
import { DataSource } from "@people-first/data-sources";
import { useEffect, useState } from "react";
import { ListControl } from "../ListControl";

function DatagridFactory(dataSource: Partial<DataSource>) {
    console.log("DatagridFactory", dataSource)
    const options = {
        sort: { name: 'asc' },
        checkboxSelection: true,
        query: "",
    };

    return new DataGridModel(dataSource as DataSource, options).setColumns([
        {
            field: 'label',
            type: 'item',
            headerName: `${i18n.t('design.atom.field.list_with_autocomplete_grid_label')}`,
            flex: 1.7,
        }
    ])
}

export const ListWitHAutocompleteField: React.FC<any> = ({ control }: FormField<ListControl>) => {
    const { t } = useTranslation();

    const { datagrid } = useDataGrid(() => (
        DatagridFactory(control.dataSource!)
            .selectedRows(control.value as any)
            .on("selection.change", ({ detail }) => {
                const ids = detail.reduce((result: object, id: string) => ({ ...result, [id]: true }), {})
                control.pushHistory(datagrid.rows.filter(({ id }) => (id in ids)))
                control.setValue(detail)
            })
            .refresh()
    ));

    return (
        <InputContainer>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}
            >
                <Text variant='subtitle1'>{t(control.label)}</Text>
                <Box style={{ width: '100%', marginTop: '8px', }}>
                    <Box style={{ width: '100%', }}>
                        <TextField
                            variant="outlined"
                            placeholder={t(control.placeholder)}
                            onChange={({ target }) => datagrid.setQuery(target.value)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        marginTop: '32px',
                        height: '350px',
                    }}
                >
                    <DataGrid model={datagrid} />
                </Box>
            </Box>
        </InputContainer>
    );
};