/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AuthService } from './services/AuthService';
import { FileService } from './services/FileService';
import { LoadingDataService } from './services/LoadingDataService';
import { MenuService } from './services/MenuService';
import { OrganizationService } from './services/OrganizationService';
import { RoleService } from './services/RoleService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly auth: AuthService;
    public readonly file: FileService;
    public readonly loadingData: LoadingDataService;
    public readonly menu: MenuService;
    public readonly organization: OrganizationService;
    public readonly role: RoleService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.auth = new AuthService(this.request);
        this.file = new FileService(this.request);
        this.loadingData = new LoadingDataService(this.request);
        this.menu = new MenuService(this.request);
        this.organization = new OrganizationService(this.request);
        this.role = new RoleService(this.request);
        this.user = new UserService(this.request);
    }
}

