import { Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';
import { TypographyProps } from "@mui/material/Typography";

const { peopleFirst } = tokens;

type TypeTextField = {
    props: TypographyProps;
    style: Interpolation<{
        theme: Theme;
    }>;
};

const TextThemeVariants: TypeTextField[] = [
    {
        props: { className: 'breadcrumbItem' },
        style: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: peopleFirst.greys['darken-2'].value,
        },
    },
    {
        props: { className: 'breadcrumbFinalItem' },
        style: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: peopleFirst.shades.black.value,
        },
    },
    {
        props: { className: 'transferListLabel' },
        style: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '26px',
            letterSpacing: '0.15px',
            color: peopleFirst.custom.primary.value,
        },
    },
    {
        props: { className: 'cellContentDatagrid' },
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
];

export default TextThemeVariants;
export { TextThemeVariants };
