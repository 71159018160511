import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from "react";

const Checkbox: React.FC<CheckboxProps> = (props) => {
    return <MuiCheckbox {...props} />;
};

export default Checkbox;
export { Checkbox };

