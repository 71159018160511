import React from "react";
import MuiSlide, { SlideProps } from "@mui/material/Slide";

const Slide: React.FC<SlideProps> = (props) => {
    return <MuiSlide {...props} />;
};

export default Slide;
export { Slide };


