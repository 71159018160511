import React, { useEffect, useState, useCallback, } from 'react';
// import { makeRequest } from 'src/app/util';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import validator from 'validator';

import { Box, TextField, Text, InputAdornment, LoadingButton, Grid, Tooltip, } from '@people-first/atoms';
import { AlertSnackbar } from '@people-first/molecules';

import { VisibilityOutlined, VisibilityOffOutlined, InfoOutlined } from '@mui/icons-material';
import { IconButton, AlertColor, } from '@mui/material';
import { FooterLogin, HeaderForgotPassword } from '@people-first/organisms';
import { AccentureLogo, BackgroundImage } from '@people-first/assets';

interface IState {
    newPassword: string;
    newPasswordConfirmation: string;
    showNewPassword: boolean;
    showNewPasswordConfirmation: boolean;
}

type TypeAlert = {
    isOpen: boolean;
    title?: string;
    message?: string;
    severity?: AlertColor;
};

type TypeSnackBar = {
    alertValue: TypeAlert,
    setAlertValue: any,
    setIsLoading: any,
    navigate: any,
};

const ResetPasswordAlert = ({ alertValue, setAlertValue, setIsLoading, navigate }: TypeSnackBar) => {
    const handleGoBack = () => {
        navigate('/login');
        setIsLoading(false)
    };

    const onCloseSnack = useCallback((reason: any) => {
        if (!reason) {
            if (alertValue.severity == 'success') {
                handleGoBack()
            }
            else {
                setAlertValue({
                    isOpen: false,
                })
            }
        }
    }, [alertValue])

    return <AlertSnackbar
        duration={4000}
        title={alertValue.title}
        content={alertValue.message}
        severity={alertValue.severity}
        onClose={onCloseSnack}
    />
}

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errorNewPasswordConfirmation, setErrorNewPasswordConfirmation] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [passwordData, setPasswordData] = useState<IState>({
        newPassword: '',
        newPasswordConfirmation: '',
        showNewPassword: false,
        showNewPasswordConfirmation: false,
    });

    const [alertValue, setAlertValue] = useState<TypeAlert>({
        isOpen: false,
        title: '',
        message: '',
        severity: 'warning',
    });

    const handleClickShowNewPassword = () => {
        setPasswordData({
            ...passwordData,
            showNewPassword: !passwordData.showNewPassword,
        });
    };

    const handleClickShowNewPasswordConfirmation = () => {
        setPasswordData({
            ...passwordData,
            showNewPasswordConfirmation: !passwordData.showNewPasswordConfirmation,
        });
    };
    const handleChange = (prop: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {

        setErrorNewPasswordConfirmation(false);
        setHasError(false)

        if (!event.target.value) {
            setDisableButton(true);
        }

        setPasswordData({ ...passwordData, [prop]: event.target.value.trim() });
    };

    const handleErrorsInputs = (value: boolean) => {
        setErrorNewPasswordConfirmation(value);
        setDisableButton(value);
    };

    const passwordsTests = (newPassword: string) => {
        const isStrong = validator.isStrongPassword(newPassword, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
            returnScore: false,
        });
        const maxLength = validator.isLength(newPassword, { min: 8, max: 16 });

        if (!isStrong || !maxLength) {
            setHasError(true);
            setAlertValue({
                isOpen: true,
                title: t('alert.error_title_password_requirements'),
                message: t('alert.error_message_password_requirements'),
                severity: 'error'
            })

            return false;
        } else {
            return true;
        }
    };

    const { token } = useParams();

    const handlePasswordReset = () => {
        if (passwordsTests(passwordData.newPassword)) {
            setIsLoading(true)
            // todo: descomentar
            // makeRequest(`/reset-password/${token}`, 'patch', {
            //     password: passwordData.newPassword,
            //     passwordConfirmation: passwordData.newPasswordConfirmation,
            // })
            //     .then((resp: any) => {
            //         setAlertValue({
            //             isOpen: true,
            //             title: t('alert.success_title_reset_password'),
            //             message: t('alert.success_message_reset_password'),
            //             severity: 'success'
            //         })
            //     })
            //     .catch((err) => {
            //         setIsLoading(false)
            //         setHasError(true)
            //         setAlertValue({
            //             isOpen: true,
            //             title: t('alert.error_title_password_requirements'),
            //             message: t('alert.error_message_password_requirements'),
            //             severity: 'error'
            //         })
            //     });
        }
    };


    useEffect(() => {
        if (passwordData.newPassword && passwordData.newPasswordConfirmation) {
            if (
                !validator.equals(
                    passwordData.newPassword,
                    passwordData.newPasswordConfirmation
                )
            ) {
                handleErrorsInputs(true);
            } else if (!passwordData.newPassword || !passwordData.newPasswordConfirmation) {
                handleErrorsInputs(false);
                setDisableButton(true);
            } else {
                handleErrorsInputs(false);
            }
        }
    }, [passwordData, disableButton]);

    const errorMessage = (
        <Text variant="caption" color="error">
            {t('login.same_password')}
        </Text>
    );
    return (
        <Box
            px={2}
            sx={{
                minHeight: '100vh',
                display: 'flex',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={3} mt={16}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        flexWrap="nowrap"
                        width="368px"
                        mb="24px"
                    >
                        <ResetPasswordAlert
                            alertValue={alertValue}
                            setAlertValue={setAlertValue}
                            setIsLoading={setIsLoading}
                            navigate={navigate}
                        />

                        <HeaderForgotPassword />
                        <Box>
                            <Box width='368px' mb={-1}>
                                <Text
                                    variant="subtitle1"
                                    color="secondary"
                                    mr="19px"
                                >
                                    {t('login.reset_password')}
                                </Text>


                                <Tooltip
                                    color="primary"
                                    placement="right"
                                    arrow
                                    title={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="baseline"
                                        >
                                            <Text
                                                variant="body2"
                                                mb="12px"
                                                color="primary"
                                                fontWeight="400"
                                            >
                                                {t('login.password_content')}
                                            </Text>
                                            <Text variant="body2" fontWeight="400">

                                                {t('login.password_content_numbers')}
                                            </Text>
                                            <Text variant="body2" fontWeight="400">

                                                {t('login.password_content_uppercase_lowercase')}
                                            </Text>
                                            <Text variant="body2" fontWeight="400">

                                                {t('login.password_content_special_characters')}
                                            </Text>
                                            <Text variant="body2" fontWeight="400">

                                                {t('login.password_content_minimum_characters')}
                                            </Text>
                                            <Text variant="body2" fontWeight="400">

                                                {t('login.password_content_maximum_characters')}
                                            </Text>
                                        </Box>
                                    }
                                >
                                    <IconButton>
                                        <InfoOutlined color="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width="368px"
                        mb="12px"
                    >
                        <TextField
                            onKeyDown={(e) => ((e.key === 'Enter') && (!disableButton)) ? handlePasswordReset() : null!}
                            disabled={isLoading}
                            required
                            error={hasError}
                            variant="outlined"
                            label={t('login.enter_new_password')}
                            type={passwordData.showNewPassword ? 'text' : 'password'}
                            sx={{
                                width: '368px',
                                marginBottom: '32px',
                            }}
                            placeholder={t('login.enter_new_password')}
                            value={passwordData.newPassword}
                            onChange={handleChange('newPassword')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {passwordData.showNewPassword ? (
                                            <VisibilityOutlined
                                                onClick={
                                                    handleClickShowNewPassword
                                                }
                                            />
                                        ) : (
                                            <VisibilityOffOutlined
                                                onClick={
                                                    handleClickShowNewPassword
                                                }
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            onKeyDown={(e) => ((e.key === 'Enter') && (!disableButton)) ? handlePasswordReset() : null!}
                            disabled={isLoading}
                            required
                            error={errorNewPasswordConfirmation || hasError}
                            helperText={
                                errorNewPasswordConfirmation ? errorMessage : ''
                            }
                            variant="outlined"
                            label={t('login.enter_new_password_again')}
                            type={
                                passwordData.showNewPasswordConfirmation
                                    ? 'text'
                                    : 'password'
                            }
                            sx={{
                                width: '368px',
                                marginBottom: '20px',
                                '& .MuiFormHelperText-root.Mui-error': {
                                    position: 'fixed',
                                    backgroundColor: '#F0F6F9 ',
                                    width: '368px',
                                    margin: '0 auto',
                                    paddingLeft: 0,
                                    borderRadius: '4px',
                                    marginTop: '60px',
                                },
                            }}
                            placeholder={t('login.enter_new_password_again')}
                            value={passwordData.newPasswordConfirmation}
                            onChange={handleChange('newPasswordConfirmation')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {passwordData.showNewPasswordConfirmation ? (
                                            <VisibilityOutlined
                                                onClick={
                                                    handleClickShowNewPasswordConfirmation
                                                }
                                            />
                                        ) : (
                                            <VisibilityOffOutlined
                                                onClick={
                                                    handleClickShowNewPasswordConfirmation
                                                }
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box
                        mb="20px"
                        display="flex"
                        justifyContent="center"
                        width="368px"
                    >
                        <LoadingButton
                            loading={isLoading}
                            disabled={disableButton}
                            variant="contained"
                            size="medium"
                            onClick={handlePasswordReset}
                        >
                            {t('login.save_password_button')}
                        </LoadingButton>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width="368px"
                    >
                        < FooterLogin />
                        {/* <Text
                            variant="body1"
                            color="secondary"
                            width="368px"
                            textAlign="center"
                        >
                            Precisa de acesso?
                        </Text>
                        <Text
                            variant="body1"
                            width="368px"
                            textAlign="center"
                            color="secondary"
                        >
                            Entre em contato com a equipe E.D.O
                        </Text>
                        <Text
                            variant="body1"
                            width="368px"
                            color="primary"
                            textAlign="center"
                        >
                            <Text variant="body1" color="secondary">
                                pelo email
                                <Link ml="5px" sx={{ textDecoration: 'none' }}>
                                    edo.build@accenture.com
                                </Link>
                            </Text>
                        </Text> */}
                    </Box>
                </Grid>
            </Grid>
            <Box
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                alignSelf="flex-end"
                component="img"
                width="163.4px"
                sx={{ marginBottom: '50px', marginRight: '74.6px' }}
                src={AccentureLogo}
                alt={t('logotype.alt_text')}
            />
        </Box>
    );
};
