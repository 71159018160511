import MuiInputAdornment, { InputAdornmentTypeMap } from "@mui/material/InputAdornment";
import { OverridableComponent } from "@mui/material/OverridableComponent";


const InputAdornment: OverridableComponent<InputAdornmentTypeMap<{}, "div">> = (props: any) => {
    return <MuiInputAdornment  {...props} />;
};

export default InputAdornment;
export { InputAdornment };
