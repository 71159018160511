import { InputContainer } from "./InputContainer";
import { UploadFile } from "../../upload-file/UploadFile";
import { FileControl } from "../FileControl";
import { FormField } from "../types";


export const FileUploadField: React.FC<any> = ({ control }: FormField<FileControl>) => {
    const { erros } = control;
    const hasError = control.dirty && Boolean(erros);

    return (
        <InputContainer>
            <UploadFile
                error={hasError}
                // value={control.value}
                label={control.label}
                accept={control.accept}
                multiple={control.multiple}
                description={control.description}
                doUpload={control.uploadHandler.bind(control)}
                onChange={(value: any) => control.setValue(value)}
            />
        </InputContainer>
    );
};
