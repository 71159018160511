import { Box } from "@people-first/atoms";
import { DataSource } from "@people-first/data-sources";
import { i18n } from "@people-first/i18n";
import { DataGrid, DataGridModel, useDataGrid } from "@people-first/organisms";

function DatagridFactory(dataSource?: Partial<DataSource>, options: any = {}) {

    const columns: Array<any> = [
        {
            field: 'org_level',
            type: 'text',
            flex: 1,
            headerName: `${i18n.t('user.column_org_level_access')}`,
        },
        ...new Array(7).fill(0).map((_, idx) => (
            {
                field: `org_${idx}`,
                type: 'text',
                flex: 1,
                headerName: `${i18n.t(`user.column_org${idx + 1}`)}`,
            }
        )),
    ];
    if (!options.readonly) {
        columns.push({
            field: 'actions',
            type: 'actions',
            headerName: `${i18n.t('resource.column_actions')}`,
            flex: 1,
            actions: [
                {
                    type: "btn.icon",
                    icon: "DeleteOutlined",
                    emit: "ds.remove",
                }
            ]
        })
    }
    return new DataGridModel(dataSource, options).setColumns(columns);
}

export function OrganizationAccess({ dataSource, readonly }: any) {

    const { datagrid } = useDataGrid(() => DatagridFactory(dataSource, { readonly }).refresh());

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginTop: '32px',
            minHeight: '350px',
            height: '350px',
        }}
        >
            <DataGrid model={datagrid} />
        </Box>
    )
}