import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { UserDataSource } from '@people-first/data-sources';
import { GlobalTemplate } from '@people-first/templates';
import { useStaticProps } from '@people-first/shared';
import { useBreadcrumb } from '@people-first/hooks';
import { Box } from '@mui/material';
import UserDetail from './UserDetail.component';
import { store } from '@people-first/store';

function convertOrgsToGridFormat(organizations: Array<any>) {
    return (organizations || [])?.map((org: any) => ({
        id: org.id,
        ...org.path
            .reverse()
            .concat([org])
            .map(({ name }: any) => name)
            .reduce(
                (orgs: any, name: string, idx: number) => ({
                    ...orgs,
                    [`org_${idx}`]: name,
                }),
                {},
            )
    }));
}

export async function getStaticProps({ params }: any) {
    let { id } = params;

    if (id === "me") { id = store.getState().session.userInfo.id }

    const organization: any = await UserDataSource.get({
        id,
        includeOrganizations: true,
    }).catch(() => ({}));

    return {
        details: {
            ...organization,
            role: organization?.role?.name,
            organizations: convertOrgsToGridFormat(organization?.organizations)
        }
    }
}

export function UserDetailPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const gotoGrid = () => navigate('/user');

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.users'), "/user"],
        [t('user.form_profile'), ""],
    ])

    return (
        <>
            <GlobalTemplate>
                <Box width='100%' display="flex" flexDirection="column" >
                    <UserDetail details={(useStaticProps() as any).details} />
                </Box>
            </GlobalTemplate>
        </>
    );
}

export default {
    getStaticProps,
    component: UserDetailPage,
}