import { api } from "@people-first/api";
import { createEntityApiAction } from "../utils";

export const [
    updateMenuGridAction,
    saveMenuAction,
    getMenuAction,
    toggleMenuStatusAction
] = createEntityApiAction(
    'Menu',
    api.menu,
    {
        alerts: {
            save: [
                "alert.success_message_generic",
                "menu.alert_content_new",
                "alert.success_message_update",
            ]
        }
    }
)
