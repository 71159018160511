import { Components, Theme } from '@mui/material';

import ChipThemeVariants from '../../atom/chip/ChipThemeVariants';

const RootChipTheme: Components<Omit<Theme, 'components'>> = {
    MuiChip: {
        variants: ChipThemeVariants,
        styleOverrides: {
            root: {
                padding: '6px, 12px, 6px, 12px',
                fontSize: '14px',
                lineHeight: '20px'
            },
        },
    },
};

export default RootChipTheme;
export { RootChipTheme };
