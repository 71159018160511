
export class Suggest<T, Term = string> {

  protected _data: { items: Array<T>; resultSizeEstimate: number } | undefined;
  protected _term!: Term;

  protected history = new Map<string, any>()
  protected listeners = new Map();

  constructor(protected fetcher: (term: Term) => Promise<{ items: Array<T>, resultSizeEstimate: number, }>, protected options: { fetch: 'once' | 'always', preload?: boolean }) { }

  public items(term: Term): Promise<Array<T>> {
    return new Promise<Array<T>>(async (resolve) => {
      if (this.options.fetch === "always" || term !== this._term || !this._data) {
        this._term = term;
        await this.updateWith(term);
      }
      resolve(this._data!.items)
    })
  }

  public get resultSizeEstimate() {
    return this._data?.resultSizeEstimate || 0;
  }
  public async updateWith(term: Term): Promise<void> {
    this._data = await (this.fetcher(term))
    this._data.items.forEach((item: any) => this.history.set(item.value, item))

    Array.from(this.listeners.values()).forEach((fn => fn(this._data)))
  }

  public get currentItems() {
    return this._data?.items || [];
  }

  public change(optionsChangefn: Function) {
    const id = crypto.randomUUID();
    this.listeners.set(id, optionsChangefn)
    return () => this.listeners.delete(id)
  }


  public lookup(...values: Array<any>) {
    return values.map(value => this.history.get(value))
  }
}
