import { GridRenderCellParams } from '@mui/x-data-grid';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../atom/box/Box';
import Chip from '../../atom/chip/Chip';

type ChipStatusType = {
    params: GridRenderCellParams;
};

const ChipStatusDataGrid = ({ params }: ChipStatusType) => {
    const { t } = useTranslation();
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
            >
                {params.row.status == true ? (
                    <Chip
                        variant="filled"
                        className="active"
                        label={t('chip.active')}

                    />
                ) : (
                    <Chip
                        variant="filled"
                        className="inactive"
                        label={t('chip.inactive')}
                    />
                )}
            </Box>
        </>
    );
};
export default ChipStatusDataGrid
export { ChipStatusDataGrid }
