import { api } from "@people-first/api";
import { t } from "i18next";
import { createEntityApiAction } from "../utils";

export const [
    updateOrganizationGridAction,
    saveOrganizationAction,
    getOrganizationAction,
    toggleOrganizationStatusAction
] = createEntityApiAction(
    'Organization',
    api.organization,
    {
        alerts: {
            save: [
                "alert.success_message_generic",
                "organization.alert_content_new",
                "alert.success_message_update",
            ]
        }
    }
)
