import MuiGrid, { GridTypeMap } from "@mui/material/Grid";
import { OverridableComponent } from "@mui/material/OverridableComponent";


const Grid: OverridableComponent<GridTypeMap<{}, "div">> = (props: any) => {
    return <MuiGrid  {...props} />;
};

export default Grid;
export { Grid };
