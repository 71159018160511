/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Cria um novo usuário
     * @returns any
     * @returns CreateUserDto
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateUserDto,
    }): CancelablePromise<any | CreateUserDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Lista todos os usuários
     * @returns any
     * @throws ApiError
     */
    public list({
        format,
        fields,
        sort,
        itemsPerPage,
        page,
        q,
    }: {
        format?: boolean,
        fields?: string,
        sort?: string,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
        /**
         * Número da página. Default é 1
         */
        page?: number,
        q?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            query: {
                'format': format,
                'fields': fields,
                'sort': sort,
                'itemsPerPage': itemsPerPage,
                'page': page,
                'q': q,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retorna um usuário identificado pelo id
     * @returns any
     * @throws ApiError
     */
    public get({
        id,
        includeOrganizations,
        includePrivileges,
    }: {
        id: string,
        includeOrganizations?: boolean,
        includePrivileges?: boolean,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            query: {
                'includeOrganizations': includeOrganizations,
                'includePrivileges': includePrivileges,
            },
        });
    }

    /**
     * Atualiza um usuário
     * @returns any
     * @throws ApiError
     */
    public update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateUserDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove um usuário
     * @returns any
     * @throws ApiError
     */
    public delete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public updateAll({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateUserDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
