import { useAppSelector } from "src/app/hook";
import { Navigate, Outlet, useLocation } from "react-router";

export const AuthGuardRoutes: React.FC = () => {
    const location = useLocation()
    const { userInfo, loading } = useAppSelector((state) => state.session)

    if (loading) {
        return null
    }

    const isAuthenticated = localStorage.getItem("accessToken") && userInfo?.id;

    if (isAuthenticated) {
        return <Outlet />
    } else {
        return <Navigate replace to='/login' state={{ from: location }} />
    }
}