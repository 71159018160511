import { Button, ButtonWithPermission } from '@people-first/atoms';
import { ListPageTemplate } from '@people-first/templates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataGrid, useDataGrid } from '@people-first/organisms';
import { OrganizationDatagridFactory } from './GridOrganization.model';
import { useBreadcrumb } from '@people-first/hooks';
import { toggleOrganizationStatus } from '@people-first/store';
import { useConfirm } from '@people-first/molecules';
import { confirmDialogConfigRegistry } from '@people-first/shared';
import { ACL } from './Organization.resources';

const GridOrganization = () => {
    const navigate = useNavigate();
    const [ConfirmDialog, confirm] = useConfirm();

    const { t } = useTranslation();

    const { datagrid } = useDataGrid(() => {

        const toggleStatusHandler = async ({ detail }: any) => {
            const entity = { ...detail.row };
            return confirm(confirmDialogConfigRegistry.get(`${entity.status}`)()).then(
                () => toggleOrganizationStatus(entity)
            )
        }

        const editHandler = async ({ detail }: any) => navigate(`/organization/${detail.row.id}`);

        return OrganizationDatagridFactory()
            .on("edit", editHandler)
            .on("toggle.status", toggleStatusHandler)
            .refresh();
    });

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('organization.organization_initials'), "/organization"]
    ])

    return (
        <>
            <ListPageTemplate
                datagrid={<DataGrid model={datagrid} />}
                button={
                    <ButtonWithPermission
                        require={ACL.create}
                        onClick={() => navigate('/organization/new')}
                        variant="contained"
                    >
                        {t<string>("organization.new_organization")}
                    </ButtonWithPermission>
                }
            />
            <ConfirmDialog />
        </>
    );
};

export default GridOrganization;