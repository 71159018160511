import { AbstractControl } from "./AbstractControl";

export class FormArray<T = unknown> extends AbstractControl<
  T,
  unknown,
  Array<Array<Error> | null>
> {

  protected factory: (() => AbstractControl<any, any, any>) | undefined;

  constructor(public controls = new Array<AbstractControl<T, unknown, unknown>>()) {
    super()
  }

  public setValue(value: Array<unknown>, extras?: any): boolean {
    if (value.length !== this.controls.length) {
      throw new Error("Values and controls have different sizes");
    }
    return this.patchValue(value, extras);
  }

  public patchValue(values: Array<unknown>, extras?: any): boolean {
    return values.slice(0, this.controls.length).map((value, index) => this.controls.at(index)?.patchValue(value)).some(Boolean)
  }

  public get value(): Array<unknown> {
    return this.controls.map(({ value }) => value);
  }

  public reset(): boolean {
    throw new Error("Method not implemented.");
  }

  public itemFactory(handler: () => AbstractControl<any, any, any>): this {
    this.factory = handler;
    return this;
  }
  public creteItem(index: number): any {
    if (!this.factory) throw new Error('FormArray item factory is undefined. Use itemFactory to set one')
    const item = this.factory();
    if (index >= this.controls.length) {
      this.controls.push(item);
    } else {
      this.controls = [
        ...this.controls.slice(0, index - 1),
        item,
        ...this.controls.slice(index)
      ]
    }
    return item;
  }

  public get valid() {
    return !this.invalid;
  }

  public get invalid(): boolean {
    return this.controls.some(({ invalid }) => invalid);
  }

  public slice(start: number, end: number = this.controls.length): FormArray<any> {
    return new FormArray(this.controls.slice(start, end));
  }
}