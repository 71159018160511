import { i18n } from "@people-first/i18n";
import { formFactory } from './Role.form';
import {
    BallotOutlined,
    CheckCircleOutline,
} from '@mui/icons-material';
import RoleDetail from "./RoleDetails.component";

export function stepsFactory(initialValue?: any) {
    return {
        form: () => formFactory(initialValue),
        steps: [
            {
                title: i18n.t('general.data'),
                key: 'data',
                icon: <CheckCircleOutline />,
                select: ['name'],
            },
            {
                title: i18n.t('general.privileges'),
                key: 'privileges',
                icon: <BallotOutlined />,
                select: ['privileges'],
            },
            {
                title: i18n.t('general.revision'),
                key: 'revision',
                icon: <CheckCircleOutline />,
                content: (context: any) => (
                    <RoleDetail role={context.control.value} />
                ),
            }
        ]
    };
}