import { api } from "@people-first/api";
import { encodeQuery } from "@people-first/shared";
import { getRoleAction, saveRoleAction, store, updateRoleGridAction } from "@people-first/store";
import { createBaseDataSourceByApi, evalRespose } from "./base";
import { DataSource, DataSourceFinder } from "./types";

export const RoleDataSource: DataSource = createBaseDataSourceByApi('role', {
    findAction: updateRoleGridAction,
    getAction: getRoleAction,
    saveAction: saveRoleAction,
})

export const PrivilegesSelectionDataSource: DataSourceFinder<any> = {
    find({ query, page, itemsPerPage }) {
        return api.role.getPrivileges(
            {
                page,
                itemsPerPage,
                q: encodeQuery({ "name": { "$contains": query } }),
            })
            .then(({ privileges }) => ({ items: privileges, resultSizeEstimate: privileges.length }))
            .catch(() => ({ items: [], resultSizeEstimate: 0 }))
            .then(({ items, resultSizeEstimate }) => ({
                items: items.map((value: any) => ({
                    id: value,
                    label: value,
                    value,
                })),
                resultSizeEstimate
            }));
    },
}

export const RolesSelectionDataSource: DataSourceFinder<any> = {
    find({ query, page, itemsPerPage }) {
        return (
            evalRespose(
                store.dispatch(
                    updateRoleGridAction({
                        page,
                        itemsPerPage,
                        fields: 'id,name',
                        format: '',
                        q: encodeQuery({ "name": { "$contains": query } }),
                    })
                )
            ).then((resp: any) => {
                return {
                    ...resp,
                    items: resp.items.map((item: any) => ({
                        label: item.name, value: item.id, id: item.id
                    }))
                }
            })
        )
    },
}