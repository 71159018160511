import { OrganizarionDataSource } from "src/app/data-source";
import { DataGridModel, dataGridPaginationSearch } from "@people-first/organisms";
import { i18n } from "@people-first/i18n";
import { ACL } from "./Organization.resources";

export function OrganizationDatagridFactory(): DataGridModel {
    const options = { sort: { name: 'asc' }, };

    return new DataGridModel(OrganizarionDataSource, options)
        .persistPagination(dataGridPaginationSearch('p'))
        .setColumns([
            {
                field: 'name',
                sortingOrder: ['asc', 'desc'],
                type: 'text.body2',
                headerName: `${i18n.t('organization.column_name')}`,
                flex: 1.4,
            },
            {
                field: 'leader_name',
                type: 'text.body2',
                sortingOrder: ['asc', 'desc'],
                headerName: `${i18n.t('organization.column_leader')}`,
                flex: 1.4
            },
            {
                field: 'org_level',
                type: 'text.body2',
                sortingOrder: ['asc', 'desc'],
                headerName: `${i18n.t('organization.column_level')}`,
                flex: 0.8,
            },
            {
                field: 'status',
                headerName: `${i18n.t('organization.column_status')}`,
                flex: 0.6,
                sortingOrder: ['asc', 'desc'],
                type: 'singleSelect',
                valueOptions: [
                    { label: i18n.t('chip.active'), value: true },
                    { label: i18n.t('chip.inactive'), value: false }
                ],
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: `${i18n.t('organization.column_actions')}`,
                flex: 1.4,
                actions: [
                    {
                        type: "btn.icon",
                        icon: "EditOutlined",
                        emit: "edit",
                        canAccess: ACL.edit,
                    },
                    {
                        type: "btn.status.toggle",
                        icon: (row: any) => row.status ? "LockOutlined" : "LockOpenOutlined",
                        emit: "toggle.status",
                        canAccess: ACL.edit,
                    }
                ]
            },
        ])
}