import { AbstractControl } from "./AbstractControl";
import { FormSetValueOptions } from "./FormSetValueOptions";


export type RequiredValue = boolean | ((value: any) => boolean);
export class FormControl<T = unknown> extends AbstractControl<
  T,
  unknown,
  Array<Error>
> {

  public value: T;
  public id = "";
  public extras: { [key: string]: unknown } = {};
  public validate = new Array<any>();
  public required: boolean = false;
  public transform: ((value: any) => any) | undefined;

  constructor(protected defaultValue: T) {
    super();
    this.value = defaultValue;
  }

  public setValue(value: any, extras?: FormSetValueOptions): boolean {
    return this.patchValue(value, extras);
  }

  public patchValue(value: T, extras?: FormSetValueOptions): boolean {
    if (this.transform) value = this.transform(value)

    this.updateControlValidStatus(value);

    const change = this.value !== value;

    if (change) {
      this.value = value;
      this.notify.change(this, extras);
    }

    this._dirty = true;

    return change;
  }

  public reset(): boolean {
    this.setErros(null);
    this.setDirty(false);
    this.setErros(null);
    return this.setValue(this.defaultValue);
  }


  public setID(id: string): this {
    this.id = id;
    return this;
  }

  public setExtra(extra: any): this {
    this.extras = extra;
    return this;
  }

  public setValidate(...validate: Array<any>): this {
    this.validate = validate
    this.updateControlValidStatus(this.value)
    return this;
  }

  public addValidate(...validate: Array<any>): this {
    return this.setValidate(this.validate.concat(validate));
  }

  public setRequired(required: RequiredValue = true): this {
    if (required instanceof Function) {
      Object.defineProperty(this, 'required', {
        get() { return required(this.value) },
        set() { /*todo add warning quando usar esse tipo de estrategia*/ }
      })
    } else {
      this.required = required;
    }
    return this;
  }

  public setTranform(transform: (value: any) => any): this {
    this.transform = transform;
    return this;
  }

  protected updateControlValidStatus(value: unknown) {
    let [valid, errors] = this._isValid(value);
    this.setErros(errors);
    return valid;
  }

  protected _isValid(value: unknown): [boolean, Array<any> | null] {
    const errors = new Array();
    const hasError = this.validate.some((isValid) => {
      const error = isValid(value, this);
      if (error) errors.push(error);
      return Boolean(error);
    });
    return [!hasError, hasError ? errors : null];
  }
}