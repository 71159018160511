import React from "react";
import MuiStepConnector, { StepConnectorProps } from '@mui/material/StepConnector';

const StepConnector: React.FC<StepConnectorProps> = (props) => {
    return <MuiStepConnector {...props} />;
};

export default StepConnector;
export { StepConnector };




