import { UpdateUserDto } from "@people-first/api";
import { FileControl, FormControl, FormGroup, ListControl, SelectControl, stringRequiredValidator } from "@people-first/molecules";
import { FileDataSource, RolesSelectionDataSource } from "@people-first/data-sources";
import { createRoleSuggest } from "@people-first/suggests";
import UserOrganizationAccess from "./UserOrganizationAccess.component";

export function formFactory(value?: any) {
    const picture = new FileControl(value.picture)
        .setLabel("user.form_photo_info_title")
        .setDescription("user.form_photo_info")
        .setType('avatar-upload')
        .setUploadHandler((files: FileList) => {
            const avatar = files.item(0)!;
            return FileDataSource.create(avatar).then(async ({ urlToUpload, url }: any) => {
                await FileDataSource.upload(urlToUpload, avatar);
                return url;
            });
        });

    const pid = new FormControl(value.pid)
        .setLabel("user.form_pid")
        .setPlaceholder("user.form_pid")
        .setType("number")
        .setValidate(stringRequiredValidator)
        .setRequired()

    const name = new FormControl(value.name)
        .setLabel("user.form_name")
        .setPlaceholder("user.form_name")
        .setValidate(stringRequiredValidator)
        .setRequired()

    const email = new FormControl(value.email)
        .setLabel("user.form_email")
        .setPlaceholder("user.form_email")
        .setValidate(stringRequiredValidator)
        .setRequired()

    const username = new FormControl(value.username)
        .setLabel("user.form_eid")
        .setPlaceholder("user.form_eid")
        .setValidate(stringRequiredValidator)
        .setRequired()

    const role = new SelectControl(value.role?.id)
        .setLabel("user.form_roles")
        .setPlaceholder("user.form_roles")
        .setOptions(createRoleSuggest())
        .setValidate(stringRequiredValidator)
        .setRequired()

    const organizations = new FormControl(value.organizations || [])
        .renderAs(UserOrganizationAccess)
        .setValidate((value: any) => Boolean(value.length) ? null : "array_empty")

    const status = new FormControl(true);

    const form = new FormGroup<any, UpdateUserDto>({
        pid,
        username,
        status,
        picture,
        name,
        email,
        role,
        organizations,
    });

    if (value.id) form.addControls({ id: new FormControl(value.id) });

    return form;
}