import { t } from "@people-first/i18n";

export const confirmDialogConfigRegistry = new Map<string, any>([
    ['true', () => (
        {
            title: t('dialog.title_inactivate_register'),
            content: t('dialog.message_inactivate_register'),
            actions: [
                { label: t('dialog.button_cancel'), target: "reject" },
                { label: t('dialog.button_inactivate_register'), target: "accept" },
            ]
        }
    )],

    ['false', () => (
        {
            title: t('dialog.title_reactivate_register'),
            content: t('dialog.message_activate_register'),
            actions: [
                { label: t('dialog.button_cancel'), target: "reject" },
                { label: t('dialog.button_reactivate_register'), target: "accept" },
            ]
        }
    )],
])