import React from "react";
import AppRoutes from "./routes";
import { Provider } from 'react-redux'
import { openLogoutDialog, setMainMenuAction, store } from '@people-first/store'
import { pages } from "@people-first/pages";
import { api } from "@people-first/api";
import { SnackbarManager } from "@people-first/organisms";
import { menu } from "./menu";
import { useLocation } from "react-router";

function App() {
  api.onError(err => {
    if (!store.getState().session.userToken) return;
    if (err.status === 401) store.dispatch(openLogoutDialog())
  })

  store.dispatch(setMainMenuAction({ menu, path: window.location.pathname }))

  return (
    <Provider store={store}>
      <SnackbarManager />
      <AppRoutes pages={pages} />
    </Provider>
  );
}
export default App;
