import { useState, } from 'react';
import { Box, Button, Stepper, Step, StepLabel, StepConnector, Grid } from '../../atom'
import { Breadcrumb } from '../breadcrumb/Breadcrumb'
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system'
import { ArrowBack, Create, CheckCircleOutline, } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';

import { tokens } from '@people-first/design/tokens';
const { peopleFirst } = tokens;

type TypeSteps = {
    title: string,
    content: JSX.Element,
}

type TypeStepperForm = {
    steps: TypeSteps[],
    nonLinear?: boolean,
    onFinish?: any,
    onNext?: any,
    onBack?: any,
    backButton?: any,
    nextButton?: any,
    breadcrumbData?: string[],
    isNextButtonDisabled?: boolean,
};

export default function StepperForm({
    steps,
    nonLinear,
    onFinish,
    onNext,
    onBack,
    backButton,
    nextButton,
    breadcrumbData,
    isNextButtonDisabled,
}: TypeStepperForm) {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>({});

    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = false;
        setCompleted(newCompleted);

        if (typeof onBack === 'function') {
            onBack(activeStep - 1)
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        if (activeStep === steps.length - 1) {
            if (typeof onFinish === 'function') {
                onFinish()
            }
        }
        else {
            if (typeof onNext === 'function') {
                onNext(activeStep + 1)
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const ActiveStepIcon = () => {
        return (
            <Avatar style={{ width: 24, height: 24, fontSize: '5px' }}>
                <Create sx={{ fontSize: '16px' }} />
            </Avatar>
        );
    }

    const CompletedStepIcon = () => {
        return (
            <Avatar style={{ width: 24, height: 24, fontSize: '5px' }}>
                <CheckCircleOutline sx={{ fontSize: '16px' }} />
            </Avatar>
        );
    }

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: peopleFirst.custom.primary.value,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: peopleFirst.custom.primary.value,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: peopleFirst.greys['lighten-2'].value,
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    return (
        <Box
            display="flex"
            paddingBottom='150px'
            paddingTop='160px'
            width='100%'
        >
            <Grid
                item
                xs={11}
                width="100%"
                display="flex"
                position="fixed"
                paddingRight="64px"
                direction="column"
                container
                sx={{
                    top: '70px',
                    bottom: 'auto',
                    backgroundColor: '#fff',
                    width: '100%',
                    zIndex: 100,
                }}
            >
                {breadcrumbData &&
                    <Breadcrumb data={breadcrumbData} />
                }

                <Stepper
                    activeStep={activeStep}
                    nonLinear={nonLinear}
                    connector={<QontoConnector />}
                    sx={{
                        // position: 'absolute',
                        boxShadow: 4,
                        padding: '24px',
                        marginTop: '32px',
                        // zIndex: 999999,
                        backgroundColor: '#fff',
                        "& .Mui-disabled .MuiStepIcon-root": {
                            color: peopleFirst.greys['lighten-2'].value,
                        },
                        ".Mui-disabled .MuiStepIcon-text": {
                            fill: peopleFirst.greys['lighten-1'].value
                        },
                        // zIndex: 'modal',
                    }}
                >
                    {steps.map(({ content, title }, index) => {
                        const stepProps: { completed?: boolean } = {}
                        let labelProps = {}

                        if (index === activeStep) {
                            labelProps = {
                                ...labelProps,
                                icon: <ActiveStepIcon />,
                            }
                        }
                        else if (completed[index]) {
                            labelProps = {
                                ...labelProps,
                                icon: <CompletedStepIcon />,
                            }
                        }

                        return (
                            <Step key={title} {...stepProps}>
                                <StepLabel {...labelProps} >{title}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>


            {steps[activeStep]?.content}

            <Grid item xs={11}
                width="100%"
                display="flex"
                position="fixed"
                justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}
                alignItems="flex-start"
                paddingRight="64px"
                paddingBottom='56px'
                paddingTop='16px'
                sx={{
                    top: 'auto',
                    bottom: 0,
                    backgroundColor: '#fff',
                    zIndex: 2,
                }}
            >
                {activeStep !== 0
                    ?
                    backButton
                        ?
                        backButton(handleBack)
                        :
                        <Button
                            onClick={handleBack}
                            sx={{ marginTop: 0 }}
                            startIcon={<ArrowBack />}
                        >
                            {t('button.go_back')}
                        </Button>
                    :
                    <></>
                }
                {nextButton
                    ?
                    nextButton(handleNext)
                    :
                    <Button
                        onClick={handleNext}
                        sx={{ marginTop: 0 }}
                        variant='contained'
                        disabled={isNextButtonDisabled}
                    >
                        {activeStep !== steps.length - 1 ?
                            t('button.go_next')
                            :
                            t('button.save')
                        }
                    </Button>
                }
            </Grid>
        </Box >
    );
}