import { api } from "src/api";
import data from "./data.json";
import { DataSource } from "./types";


// api.organization.organizationControllerFindAll().then((value) => {
//   console.log(value)
// }).catch(err => console.error(err))

const roles = data.data.roles;
export const SanboxDataSource: DataSource<any> = {
  async remove() { },
  async get() { },
  async save() { },
  async find({ query, page, itemsPerPage, sort }) {
    return new Promise((resolve) => {
      const skip = page * itemsPerPage;
      setTimeout(() => {
        resolve({
          items: roles.slice(skip, skip + itemsPerPage),
          resultSizeEstimate: roles.length,
        });
      }, 500);
    });
  },
};