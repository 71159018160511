import Text from '../../atom/text/Text';
import Link from '../../atom/link/Link';
import {useTranslation} from 'react-i18next'


export default function FooterLogin() {
    const { t } = useTranslation()
    return(
    <>
        <Text
            variant="body1"
            color="secondary"
            width="368px"
            textAlign="center"
        >
            {t('login.footer_need_access')}
        </Text>
        <Text
            variant="body1"
            width="368px"
            textAlign="center"
            color="secondary"
        >
            {t('login.footer_contact')}
        </Text>
        <Text variant="body1" width="368px" color="primary" textAlign="center">
            <Text variant="body1" color="secondary">
                {t('login.footer_contact_email')}
                <Link ml="5px" sx={{ textDecoration: 'none' }}>
                    edo.build@accenture.com
                </Link>
            </Text>
        </Text>
    </>
    )
}
