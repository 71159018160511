import { FormControl, FormGroup } from '../../molecule';
import { GridSortDirection } from "@mui/x-data-grid";
import { DataGridEvent, DataGridEventHandler } from "./DataGridEvent";
import { DataSource } from "src/app/data-source";
import { GridCellsRegistry } from "./DataGridCells";
import { Buffer } from 'buffer'
import { Classifier, WithPermissionOptions } from '@people-first/shared';

export interface SortMap {
    [key: string]: GridSortDirection;
}

export interface VisibleColumns {
    [key: string]: boolean;
}

export interface Column {
    field: string;
    headerName: string;
    type: string;
    flex?: number;
    sortingOrder?: string[];
    valueOptions?: { label: any; value: boolean }[];
    actions?: Array<{
        type: string | ((row: any) => string);
        icon?: string | ((row: any) => string);
        emit: string | ((row: any) => string);
        canAccess?: WithPermissionOptions | ((row: any) => WithPermissionOptions);
    }>
}

export type Columns = Array<Column>;
export type SelectedRows = Array<string | number> | string | number;

export class DataGridModel {


    public id = crypto.randomUUID();
    public persistPaginationOnSearchParams = false;
    protected _page = new FormControl<number>(0);
    protected _loading = new FormControl(false);
    protected _change = new FormControl(null);
    protected _sort = new FormControl<SortMap>({});
    protected _query = new FormControl<{ [key: string]: any }>({});
    protected _columns = new FormControl<Columns>([]);
    protected _visibleColumns = new FormControl<VisibleColumns>({});
    protected _rows = new FormControl<Array<any>>([]);
    protected _itemsPerPage = new FormControl<number>(10);
    protected _resultSizeEstimate = new FormControl<number>(0);
    protected _checkboxSelection = new FormControl<boolean>(false);
    protected _selectedRows = new FormControl<SelectedRows>([]);
    protected _events = new Classifier<DataGridEventHandler>(
        (event) => event.name
    );
    protected dataSource: Partial<DataSource> | undefined;

    protected _pagination = new FormGroup({
        sort: this._sort,
        page: this._page,
        query: this._query,
        itemsPerPage: this._itemsPerPage,
    });

    protected _control = new FormGroup({
        loading: this._loading,
        change: this._change,
        columns: this._columns,
        checkboxSelection: this._checkboxSelection,
        activeColumns: this._visibleColumns,
        rows: this._rows,
        resultSizeEstimate: this._resultSizeEstimate,
    });

    public persistPaginationAdapter: { persist: (pagination: any) => void; restore: () => any; } | null = null;

    constructor(dataSource?: Partial<DataSource>, options = {}) {
        if (dataSource) this.setDataSource(dataSource);

        this._pagination.patchValue(options);
        this._control.patchValue(options);

        this._pagination.valueChange((value) => {
            this.refresh();
            this.persistPaginationAdapter?.persist(value)
            this.emit("pagination.change", value);
        });
    }

    public setPagination(pagination: any) {
        this._pagination.patchValue(pagination, { emitEvent: false });
    }

    public selectedRows(value: SelectedRows): this {
        this._selectedRows.setValue(value);
        return this;
    }

    public setColumns(columns: Columns = []): this {
        this._columns.setValue(
            columns.map((column) => ({
                ...column,
                // todo:fazer o mapa correto de tradução dos tipos
                type: column.type.match(/^(text|icon)/) ? 'string' : column.type,
                actions: (column.actions || []).map((action: any) => {
                    if (action.emit) action.onClick = (row: any, sourceEvent: any) => {
                        this.emit(action.emit, { row, sourceEvent })
                    }
                    return action;
                }),
                renderCell: GridCellsRegistry.get(column.type),
            }))
        );
        return this;
    }

    public get selectection(): SelectedRows {
        return this._selectedRows.value;
    }

    public get columns(): Columns {
        return this._columns.value;
    }

    public get checkboxSelection(): boolean {
        return this._checkboxSelection.value;
    }

    public get resultSizeEstimate(): number {
        return this._resultSizeEstimate.value;
    }


    public get rows(): Array<any> {
        return this._rows.value;
    }

    public get loading(): boolean {
        return this._loading.value;
    }

    public get pagination() {
        return this._pagination.value;
    }

    public get visibleColumns(): Columns {
        const visibleColumns = this._visibleColumns.value;
        const columns = this._columns.value;

        return Object.keys(visibleColumns).length
            ? columns.filter(({ field }) => visibleColumns[field])
            : columns;
    }

    public persistPagination(adapter: { persist: (pagination: any) => void; restore: () => any; }): this {
        this.persistPaginationAdapter = adapter;
        const pagination = adapter.restore()
        if (pagination) this._pagination.patchValue(pagination, { emitEvent: false })
        return this;
    }

    public get emit() {
        return (name: string, detail: any) => {
            const event = new DataGridEvent(name, detail);
            this._events.get(name)?.forEach(({ handler }) => {
                let result = handler(event);
                if (result === true) result = Promise.resolve(true);
                if (result instanceof Promise) result
                    .then((change) => { if (change) this.refresh(); })
                    .catch(err => { })
            });
        };
    }

    public refresh() {
        this._control.patchValue({ loading: true });
        const pagination = this._pagination.value as any;
        pagination.page += 1;
        this.dataSource?.find!(pagination)
            .then(({ items: rows, resultSizeEstimate }: any) => {
                this._control.patchValue({ rows, resultSizeEstimate, loading: false });
            });
        return this;
    }

    public setActiveColumns(columns: { [key: string]: boolean }): this {
        this._visibleColumns.setValue(columns);
        return this;
    }

    public on<T = unknown>(name: string, handler: (defail: any) => void): this {
        this._events.push({ name, handler });
        return this;
    }

    public prevPage(): this {
        return this.gotoPage(this._page.value - 1);
    }

    public nextPage(): this {
        return this.gotoPage(this._page.value + 1);
    }

    public setQuery(query: any): this {
        this._query.setValue(query);
        return this;
    }

    public gotoPage(page: number): this {
        this._page.setValue(Math.min(Math.max(page, 0), this.resultSizeEstimate));
        return this;
    }

    public setDataSource(dataSource: Partial<DataSource>): this {

        this.dataSource = dataSource;

        if (dataSource?.subscribe) {
            dataSource.subscribe(() => this.refresh());
        }


        this.on("ds.remove", (detail) => this.dataSource?.remove?.(detail))
        return this;
    }

    public setSort(sort: SortMap) {
        this._sort.setValue(sort);
        return this;
    }

    public bind(comp: any): this {
        this._control.valueChange(() => {
            comp.setState((prev: any) => ({ ...prev }));
        });
        return this;
    }

    public get control() {
        return this._control
    }
}

export function dataGridPaginationSearch(name: string = 'p') {
    return {
        persist: (pagination: any) => {
            console.log('persist', pagination)
            const url = new URL(window.location.toString());
            url.searchParams.set(name, encodeQuery(pagination));
            window.history.pushState({}, '', url);
        },
        restore: () => {
            const url = new URL(window.location.toString());
            const paginationString = url.searchParams.get(name)
            console.log('restore', paginationString ? decodeQuery(paginationString) : null)
            return paginationString ? decodeQuery(paginationString) : null
        }
    }
}

export const encodeQuery = (query: any): string => {
    return Buffer.from(JSON.stringify(query)).toString('base64')
}

export const decodeQuery = (query: string): any => {
    return JSON.parse(Buffer.from(query, 'base64').toString())
}