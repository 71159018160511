import { pushNotificationAction, removeNotificationAction } from "../actions";
import { store } from "../store";

export function pushNotification(options: any = {}) {
    store.dispatch(pushNotificationAction({
        id: crypto.randomUUID(),
        ...options,
    }));
}

export function removeNotification(id: string) {
    id && store.dispatch(removeNotificationAction(id));
}