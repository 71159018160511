import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';

const { peopleFirst } = tokens;

const RootDialogTitle: Components<Omit<Theme, 'components'>> = {
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                fontSize: '20px',
                lineHeight: '2rem',
                fontWeight: 500,
                // letterSpacing: SpacingText.spacing(2),
                color: peopleFirst.shades.black.value,
            },
        },
    },
};

export default RootDialogTitle;
export { RootDialogTitle };
