import React from "react";
import Box from "../../atom/box/Box";
import { Text } from '../../atom/text/Text'
import { ListItem } from '../../atom/list-item/ListItem'
import { ChipStatus } from '../chip-status/ChipStatus'
import { Divider } from "@mui/material";

type RowPropertyProps = {
  fieldId?: string;
  label?: string;
  fieldContent?: string | boolean | number;
  isStatus?: boolean
};

const RowProperty: React.FC<RowPropertyProps> = ({ label, fieldContent, isStatus }) => {

  return (
    <>
      <Box minHeight='64px' width="35%" minWidth={'400px'} display="flex" alignItems="center" justifyContent={'left'}>
        <ListItem>
          <Box minWidth={'30%'}>
            <Text variant="body2">{label}</Text>
          </Box>
          <Box>
            {typeof fieldContent === 'boolean'
              ? (<ChipStatus status={fieldContent} />)
              : (
                Array.isArray(fieldContent)
                  ? (
                    <Box display={"flex"} flexDirection={"column"}>
                      {
                        fieldContent.map(field => (
                          <Text key={field} variant="body1" minWidth={'100%'}>{field}</Text>
                        ))
                      }
                    </Box>
                  )
                  : (<Text variant="body1" minWidth={'100%'}>{fieldContent}</Text>)
              )
            }
          </Box>
        </ListItem>
      </Box>
      <Divider style={{ margin: '0px' }} />
    </>
  );
};

export default RowProperty;
export { RowProperty };
