import { RoleDataSource } from "src/app/data-source";
import { DataGridModel, dataGridPaginationSearch } from "@people-first/organisms";
import { i18n } from "@people-first/i18n";
import { ACL } from "./Role.resources";

export function RoleDatagridFactory(): DataGridModel {

    const options = { sort: { name: 'asc' }, };

    return new DataGridModel(RoleDataSource, options)
        .persistPagination(dataGridPaginationSearch('p'))
        .setColumns([
            {
                field: 'name',
                type: 'text.body2',
                headerName: `${i18n.t('role.column_role_name')}`,
                flex: 9,
                sortingOrder: ['asc', 'desc'],
            },
            // {
            //     field: 'privileges',
            //     type: 'items--abbreviation',
            //     sortingOrder: ['asc', 'desc'],
            //     headerName: `${i18n.t('role.column_linked_privileges')}`,
            //     flex: 1,
            // },
            {
                field: 'status',
                type: 'status',
                headerName: `${i18n.t('role.column_status')}`,
                flex: 2,
                valueOptions: [
                    { label: i18n.t('chip.active'), value: true },
                    { label: i18n.t('chip.inactive'), value: false }
                ],
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: `${i18n.t('resource.column_actions')}`,
                flex: 1,
                actions: [
                    {
                        type: "btn.icon",
                        icon: "EditOutlined",
                        emit: "edit",
                        canAccess: ACL.edit,
                    },
                    {
                        type: "btn.status.toggle",
                        icon: (row: any) => row.status ? "LockOutlined" : "LockOpenOutlined",
                        emit: "toggle.status",
                        canAccess: ACL.edit,
                    }
                ]
            },
        ])
}