import { Box, ButtonContained } from '@people-first/atoms';
import { useTranslation } from 'react-i18next';
import { OrganizationAccess } from './UserOrganizationAccess.grid';
import { FormView, lookupLabel, SelectControl, useForm } from '@people-first/molecules';
import { formFactory } from './UserOrganizationAccess.form';
import { useReactiveDataSource } from '@people-first/data-sources';
import { useEffect } from 'react';
import { FormStepContext } from '@people-first/templates';

const getOrganizationName = (control: any) => lookupLabel(control)?.replace(/ORG\s+\d+\s+-\s+/gim, '')

const UserOrganizationAccess = ({ control }: { control: SelectControl }) => {
    const { t } = useTranslation();
    const { form } = useForm(formFactory)
    const datasource = useReactiveDataSource([])


    useEffect(() => datasource.subscribe(() => {
        control.setValue(datasource.items.map(({ id }) => id))
    }))

    const organizationLevel = form.get('org_level')

    const addOrganizationHandler = () => {
        const organization: any = {
            id: form.get(`org_${organizationLevel.value - 1}`).value
        };

        Object.entries(form.value).forEach(([prop, orgId]) => {
            if (orgId) organization[prop] = getOrganizationName(form.get(prop))
        })

        datasource.next([...datasource.items, organization]);

        organizationLevel.reset()
    }

    return (
        <FormStepContext.Consumer>
            {(context) => {
                // update organizationsList in formstep context
                // this data will be used in the summary screen
                context.data.organizations = datasource.items;
                return (
                    <Box style={{ flexDirection: 'column' }}>
                        <Box sx={{ maxWidth: '450px' }}>
                            <FormView form={form}></FormView>
                            <ButtonContained disabled={form.invalid} label={t("user.add")} onClick={addOrganizationHandler} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                marginTop: '32px',
                                minHeight: '350px',
                                height: '350px',
                            }}
                        >
                            <OrganizationAccess dataSource={datasource} />
                        </Box>
                    </Box>
                )
            }}
        </FormStepContext.Consumer>
    );
};

export default UserOrganizationAccess;

