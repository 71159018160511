import { ApiError } from "@people-first/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { pushNotification } from "./dispatch";

const errorStatusMap: { [key: number]: Array<string> } = {
    500: ['alert.error_title_500', 'alert.error_message_500'],
    400: ['alert.error_title_400', 'alert.error_message_400'],
    409: ['alert.error_title_unspecific', 'alert.error_message_409'],
    401: ['alert.error_title_unspecific', 'alert.error_message_401']
}

export const handlerErrorRespose = (err: unknown) => {
    if (err instanceof ApiError) {
        if (err.status in errorStatusMap) {
            const [title, message] = errorStatusMap[err.status]!;
            pushNotification({
                title: t(title),
                content: t(message),
                severity: 'error',
            })
        }
    } else {
        console.dir(err)
    }
}

export const createEntityApiAction = (name: string, api: any, extra: any) => {

    const listAction = createAsyncThunk(
        `update${name}GridAction`,
        async (params: any, { rejectWithValue }) => {
            try {
                return api
                    .list(params)
                    .then(({ data, resultSizeEstimate = 0 }: any): any => {
                        const items = (data || [])
                        return { items, resultSizeEstimate };
                    })
            } catch (err) {
                handlerErrorRespose(err);
                return rejectWithValue(err)
            }
        }
    );

    const saveAction = createAsyncThunk(
        `save${name}Action`,
        async ({ entity: requestBody }: any, { rejectWithValue }) => {
            try {

                Object.keys(requestBody).forEach(prop => {
                    const value = requestBody[prop];
                    if (value === null || value === undefined) delete requestBody[prop];
                })

                const { id } = requestBody;
                const isUpdate = Boolean(id);

                const result = await (
                    isUpdate
                        ? api.update({ id, requestBody })
                        : api.create({ requestBody })
                );

                const [title, createText, updateText] = extra.alerts.save
                pushNotification({
                    title: t(title),
                    content: isUpdate ? t(updateText) : t(createText),
                })

                return result;
            } catch (err) {
                handlerErrorRespose(err);
                return rejectWithValue(err)
            }
        }
    );

    const getAction = createAsyncThunk(
        `get${name}Action`,
        async (x: any, { rejectWithValue }) => {
            console.log(x)
            const { id, ...params } = x;
            try {
                return await api
                    .get({ id, ...params })
                    .then(({ data }: any) => data)
            } catch (err) {
                handlerErrorRespose(err);
                return rejectWithValue(err)
            }
        }
    );

    const toggleStatusAction = createAsyncThunk(
        `toggle${name}Action`,
        async (entity: any, { rejectWithValue }) => {
            try {
                const { id } = entity;
                const status = !entity.status;
                const requestBody = { ...entity, status };
                return api
                    .update({ id, requestBody })
                    .then(({ data }: any): any => {
                        pushNotification({
                            title: t(`action_alert.toggle_title`),
                            content: status
                                ? t(`action_alert.toggle_active`)
                                : t(`action_alert.toggle_deactive`)
                        });
                        return { data };
                    })
            } catch (err) {
                handlerErrorRespose(err);
                return rejectWithValue(err)
            }
        }
    )

    return [
        listAction,
        saveAction,
        getAction,
        toggleStatusAction,
    ]
}