import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';

const { peopleFirst } = tokens;

const RootDialogContextText: Components<Omit<Theme, 'components'>> = {
    MuiDialogContentText: {
        styleOverrides: {
            root: {
                fontSize: '14px',
                lineHeight: '1.375rem',
                fontWeight: 400,
                color: peopleFirst.shades.black.value,
                letterSpacing: '0.1px'
            },
        },
    },
};

export default RootDialogContextText;
export { RootDialogContextText };
