import { Suggest } from "src/app/shared";
import { FormControl } from "./FormControl";

export type SelectOption = { value: string | number, label: string | number }
export type SelectOptions<T> = Array<T> | Suggest<T>

export class SelectControl<T = unknown, OptionType = unknown,> extends FormControl<T> {
  public _options: Suggest<OptionType> = new Suggest(async () => ({ items: [], resultSizeEstimate: 0 }), { fetch: "once" });

  public listeners = new Map<string, Function>()
  public multiple = false;

  constructor(protected defaultValue: any) {
    super(defaultValue);
    this.setType("select");
  }

  public get suggest() {
    return this._options;
  }

  public get resultSizeEstimate() {
    return this._options.resultSizeEstimate;
  }

  public setMultiple(value = true): this {
    this.multiple = value;
    return this;
  }

  public setOptions(options: SelectOptions<OptionType>): this {
    if (!(options instanceof Suggest)) {
      this._options = new Suggest<OptionType>(async () => ({ items: options, resultSizeEstimate: options.length }), { fetch: 'once' })
    } else {
      this._options = options as Suggest<OptionType>;
    }

    this._options.change((options: any) => {
      Array.from(this.listeners.values()).forEach((fn => fn(options)))
    })

    return this;
  }

  public options(term: string = ""): Promise<Array<OptionType>> {
    return this._options.items(term);
  }

  public optionsChange(optionsChangefn: Function) {
    const id = crypto.randomUUID();
    this.listeners.set(id, optionsChangefn)
    return () => this.listeners.delete(id)
  }

  public updateOptionsWith(term: string): void {
    this._options.updateWith(term)
  }

  public get items(): Array<OptionType> {
    return this.suggest.currentItems;
  }

  public lookup(...values: Array<any>) {
    return this.suggest.lookup(...values);
  }

}