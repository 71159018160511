import { FormControl } from "../FormControl";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { InputContainer } from "./InputContainer";
import { FormControlLabel } from "@mui/material";


export function SwitchField({ control }: { control: FormControl<boolean> }) {
    const { i18nNS = "" } = control.extras;
    const { t } = useTranslation([i18nNS as string]);

    const { erros } = control;
    const hasError = control.dirty && Boolean(erros);

    // const helperText = hasError ? (erros ? t(erros.join(", ") || "") : "") : "";
    // console.log(hasError, erros, helperText);

    const helperText = t(control.description);
    return (
        <InputContainer>
            <FormControlLabel
                sx={{ width: '100%' }}
                label={t(control.label)}
                disabled={control.disabled}
                control={
                    <Switch
                        required={control.required}
                        key={control.name}
                        // type={control.type}
                        name={control.name}
                        // sx={{ width: '%' }}
                        // error={hasError}
                        // helperText={helperText}
                        // variant="outlined"
                        placeholder={t(control.placeholder)}
                        checked={control.value || false}
                        onChange={(event) => control.setValue(event.target.value)}
                    />
                }
            />
        </InputContainer>
    );
};
