import { Suggest } from "src/app/shared";
import { createOrganizationHandlerByQuery } from "./organization.suggest";

export const createParentOrgSuggest = (parentLevel: () => number) => {
    return new Suggest(
        createOrganizationHandlerByQuery(
            (term) => [
                ['org_level', 'equals', Math.max(parentLevel() - 1, 1)],
                ['name', 'contains', term]
            ]
        ),
        { fetch: "once" }
    );
}