import Radio from '../../atom/radio-button/RadioButton';
import RadioGroup from '../../atom/radio-group/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '../../atom/form-control/FormControl';
import FormLabel from '../../atom/form-label/FormLabel';
import { RadioGroupProps } from '@mui/material/RadioGroup'
import { SxProps, Theme } from '@mui/material';
import { i18n } from '@people-first/i18n';

type TypeRadioForm = {
    titleMessage?: string;
    data: any[], // Valores passados devem ser label e value - FormControlLabelProps como referencia
    // data?: FormControlLabelProps[],
    error?: boolean,
    value?: string | number,
    disabled?: boolean,
    required?: boolean,
    name: RadioGroupProps['name']
    defaultValue?: RadioGroupProps['defaultValue']
    onChange?: RadioGroupProps['onChange'], // Valor retornado no objeto value.target.value
    checked?: boolean,
    sx?: SxProps<Theme>
};

const RadioForm = ({
    titleMessage,
    data,
    defaultValue,
    name,
    error,
    required,
    value,
    disabled,
    sx,
    onChange,
}: TypeRadioForm) => {
    const RadioItemsComponent = () => {
        const radios = (data || []).map(({ value, label }, index) => {
            return <FormControlLabel key={index} value={value} label={String(i18n.t(label))} disabled={disabled} control={<Radio />} />
        })
        return (<>{radios}</>)
    }
    return (
        <FormControl
            required={required}
            error={error}
            sx={[
                { width: `100%` },
                ...Array.isArray(sx) ? sx : [sx]
            ]}
        >
            {titleMessage && <FormLabel>{titleMessage}</FormLabel>}
            <RadioGroup
                row
                defaultValue={defaultValue}
                onChange={onChange}
                name={name}
                value={value}
            >
                <RadioItemsComponent />
            </RadioGroup>
        </FormControl>
    );
}

export default RadioForm
export { RadioForm }