import { FormControl } from "./FormControl";
import { AbstractControl } from "./AbstractControl";
import { FormSetValueOptions } from "./FormSetValueOptions";
import { FormArray } from "./FormArray";

export class FormGroup<
  T extends { [key: string]: AbstractControl<unknown, unknown, unknown> },
  Y extends { [key: string]: unknown }
> extends AbstractControl<T, unknown, { [key: string]: Array<Error> }> {

  protected defaultValue: Y = {} as Y;

  constructor(protected controls: T = {} as T) {
    super();

    Object.entries(controls).forEach(([name, control]) => {
      control.parent = this;
      if (control instanceof FormControl) control.setName(name);
    });

    this.defaultValue = this.value;
  }

  public reset(): boolean {
    this.setErros(null);
    const change = Object.values(this.controls)
      .map((control) => control.reset())
      .some(Boolean);

    if (change) this.notify.change(this);
    return change;
  }

  public patchValue(values: Y, extras?: FormSetValueOptions): boolean {
    const change = Object.keys(this.controls)
      .map(name => {
        return (name in values)
          ? this.controls[name].patchValue(values[name], { emitEvent: false })
          : false
      })
      .some(Boolean);
    if (change) this.notify.change(this, extras);
    return change;
  }

  public setValue(value: Y, extras?: FormSetValueOptions): boolean {
    //todo:avaliar se tem todos os campos
    return this.patchValue(value, extras);
  }

  public get valid(): boolean {
    return !this.invalid;
  }

  public get invalid(): boolean {
    return Object.values(this.controls).some((control) => control.invalid);
  }

  public slice(fields: Array<string>): FormGroup<any, any> {
    if (!fields || fields.length === 0) fields = Object.keys(this.controls);
    return fields.reduce((slice: FormGroup<any, any>, name: string) => slice.addControls({ [name]: this.get(name) }), new FormGroup())
  }

  public get(path: string): any {
    const segments = path.split(".");
    const last = segments.length - 1;
    return segments.reduce(
      (c: AbstractControl<any, any, unknown>, name: string, idx: number) => {
        if (c instanceof FormControl) {
          return idx === last ? c : null;
        } else if (c instanceof FormGroup) {
          return c.controls[name];
        } else {
          return null;
        }
      },
      this
    );
  }

  public getControls() {
    return this.controls;
  }

  public addControls(controls: { [key: string]: AbstractControl<unknown, unknown, unknown> }): this {
    // this.controls = { ...this.controls, ...controls }

    Object.entries(controls).forEach(([name, control]) => {
      control.parent = this;
      if (control instanceof FormControl) control.setName(name);
      (this.controls as any)[name] = control;
    });

    return this;
  }

  public removeControls(...controls: Array<string>): this {
    controls.forEach(name => delete this.controls[name])
    return this;
  }

  public get value(): Y {
    return Object.entries(this.controls).reduce(
      (c, [name, control]) => ({
        ...c,
        [name]: control.value,
      }),
      {} as Y
    );
  }

  public get dirty(): boolean {
    return Object.values(this.controls).some(control => control.dirty);
  }

  public get erros() {
    const _erros = Object.entries(this.controls).reduce(
      (c, [name, { erros }]) => (erros ? { ...c, [name]: erros } : c),
      {} as any
    );
    return Object.keys(_erros).length === 0 ? null : _erros;
  }

  public find(query: Function): Array<AbstractControl<any, any, any>> {
    return Object.values(this.controls).reduce(
      (controls, control) => {
        if (control instanceof FormGroup) {
          return [...controls, ...control.find(query)]
        }
        // if (control instanceof FormArray) {
        //   return [...controls, ...control.filter(query)]
        // }
        if (query(control)) {
          return [...controls, control]
        }
        return controls;
      },
      new Array<any>(),
    );
  }
}
