import React from "react"
import { memo, useState } from 'react';
import Snackbar from '../../atom/snackbar/Snackbar';
import Alert from '../../atom/alert/Alert';
import AlertTitle from '../../atom/alert-title/AlertTitle';
import Slide from '../../atom/slide/Slide';

import { CheckCircleRounded, Close, InfoRounded } from '@mui/icons-material';
import { AlertColor, SnackbarProps, AlertProps, SlideProps, IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SxProps, Theme } from "@mui/material";


type IAlertSnackbar = {
    vertical?: 'bottom' | 'top';
    horizontal?: 'left' | 'center' | 'right';
    title?: string;
    content: string | JSX.Element | undefined,
    duration?: number;
    severity?: AlertColor;
    icon?: React.ReactNode;
    closeButton?: boolean;
    endIcon?: React.ReactNode;
    colorButton?: 'inherit' | 'success' | 'info' | 'warning' | 'error' | 'accent_two' | 'black',
    sizeButton?: 'small' | 'medium' | 'large',
    textButton?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    onClose?: AlertProps['onClose'],
    onCloseAlert?: AlertProps['onClose'],
    transitionComponent?: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>,
    sx?: SxProps<Theme>
};

const icons: { [key: string]: any } = {
    'success': <CheckCircleRounded />,
    'info': <InfoRounded />,
}

function slideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

const AlertSnackbar = ({
    vertical = 'top',
    horizontal = 'right',
    duration = 3000,
    title,
    content,
    severity,
    icon,
    closeButton = false,
    sx,
    onClick,
    onClose,
    onCloseAlert,
    transitionComponent
}: IAlertSnackbar) => {
    const [open, setOpen] = useState(true)


    const closeInternal = (event: any) => {
        onClose?.(event);
        setOpen(false);
    }

    return (
        <>
            <Snackbar
                open={open}
                sx={{ alignItems: 'center' }}
                autoHideDuration={duration}
                onClose={closeInternal}
                anchorOrigin={{ vertical, horizontal }}
                TransitionComponent={slideTransition}
            >
                <Alert
                    elevation={0}
                    onClose={onClose}
                    severity={severity}
                    icon={icons[String(severity)] || icon}
                    action={
                        closeButton
                            ? (
                                <IconButton onClick={closeInternal}>
                                    <Close sx={{ color: '#fff' }} />
                                </IconButton>
                            )
                            : null
                    }
                >
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {content}
                </Alert>
            </Snackbar>
        </>
    );
}
export default memo(AlertSnackbar)