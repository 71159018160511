
import { Dialog } from '@people-first/atoms';
import { useAppSelector } from '@people-first/hooks';
import { ContentBreakpoint, HeaderBreakpoint } from '@people-first/organisms';
import { BreakPointTemplate } from '@people-first/templates';

export default function StopBreakpoint() {
    const { blocked } = useAppSelector((state) => state.blockSmallDevices) //Aqui consigo pegar o valor do State isOpen que foi configurado no StopBreakpointSlice.ts
    return (
        <Dialog open={blocked} fullScreen>
            <BreakPointTemplate
                header={<HeaderBreakpoint />}
                contents={< ContentBreakpoint />}
            />
        </Dialog>
    );
}
