import { FileDataSource } from "@people-first/data-sources";
import { FormControl, FormGroup, SelectControl, stringRequiredValidator, stringRequired, ifRequired, FileControl } from "@people-first/molecules";
import { createLoadingDataTypesSuggest } from "@people-first/suggests";
import { tform } from "./LoadingDataCreate.resources";

export function formFactory(value?: any, isNew = true) {

  const type = new SelectControl(value.type || "")
    .setType("select")
    .setLabel(tform("type"))
    .setPlaceholder(tform("type"))
    .setOptions(createLoadingDataTypesSuggest())
    .setDescription("")
    .setRequired()
    .setValidate(ifRequired(stringRequired));

  const name = new FormControl(value.name || "")
    .setLabel(tform("name"))
    .setPlaceholder(tform("name"))
    .setRequired()
    .setValidate(stringRequiredValidator);

  const competence = new FormControl(value.competence || "")
    .setLabel(tform("competence"))
    .setPlaceholder(tform("competence"))
    .setRequired()
    .setValidate(stringRequiredValidator);

  const file = new FileControl(value.file)
    .setType('file-upload')
    .setAccept('.xls, .xlsx')
    .setDisabled(() => type.invalid)
    .setRequired()
    .setValidate(stringRequiredValidator)
    .setUploadHandler((files: FileList) => {
      const file = files.item(0)!;
      return FileDataSource.create(file).then(async (response: any) => {
        await FileDataSource.upload(response.urlToUpload, file);
        name.setValue(response.name);
        return response.id;
      });
    });

  const form = new FormGroup({
    name,
    type,
    competence,
    file,
  });

  if (!isNew) form.addControls({ id: new FormControl(value.id) })

  return form;
}
