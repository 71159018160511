import { api } from "@people-first/api";
import { updateUserGridAction, getUserAction, saveUserAction } from "@people-first/store";
import { createBaseDataSourceByApi } from "./base";
import { DataSource } from "./types";

export const UserDataSource: DataSource = createBaseDataSourceByApi('user', {
    findAction: updateUserGridAction,
    getAction: getUserAction,
    saveAction: saveUserAction,
});
