import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransferList as MoleculeTransferList } from "../../transfer-list/TransferList";
import { TransferListControl } from "../TransferListControl";
import { FormField } from "../types";

export function TransferList({ control }: FormField<TransferListControl<any>>) {
    const { t } = useTranslation()
    const [options, setOptions] = useState<any>({
        left: [],
        right: [],
        ready: false,
    })
    // todo: centralizar ou acompanhar a tela os items de mover
    useEffect(() => {
        control
            .options("")
            .then((values: Array<any>) => {
                const valuesMap = control.value.reduce(
                    (_opts: any, value: any) => ({ ..._opts, [value]: true }),
                    {},
                )
                setOptions(values.reduce(
                    (_opts, option: any) => {
                        ((option.value in valuesMap) ? _opts.right : _opts.left).push(option);
                        return _opts;
                    },
                    {
                        left: [],
                        right: [],
                        ready: true,
                    }
                ))
            });
    }, [false])

    return (
        options.ready
            ? <MoleculeTransferList
                leftData={options.left}
                rightData={options.right}
                leftLabel={t(control.selectionLabel)}
                rightLabel={t(control.selectedLabel)}
                onChange={({ right }) => {
                    control.setValue(right.map(({ value }) => value))
                }}
            />
            : (<></>)
    );
}