import React, { useState, useEffect, } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import { Box, TextField } from '../../atom';

// type UserInputsProps = {
// 	fieldsForm: TypeNewUser;
// 	setFieldsForm: React.Dispatch<React.SetStateAction<TypeNewUser>>;
// 	getValuesNewUser: (prop: keyof TypeNewUser) => (event: React.ChangeEvent<HTMLInputElement>) => void;
// 	allRoles: TypeRole[];
// 	isUpdate?: boolean;
// };

const UserInputs: React.FC<any> = ({ fieldsForm, setFieldsForm, getValuesNewUser, allRoles, isUpdate }: any) => {

	const [allRolesString, setAllRolesString] = useState<string[]>([]);
	const [count, setCount] = useState<number | null>(null);
	const [blockInput, setBlockInput] = useState<boolean>(false)

	const { t } = useTranslation();

	useEffect(() => {
		const arrayName = allRoles.map((item: any) => item.name)
		setAllRolesString(arrayName)
	}, [allRoles])

	function onChangeAutoComplete(event: React.SyntheticEvent, newValue: string | null) {
		const roleObj = allRoles.filter((item: any) => item.name === newValue);
		if (newValue !== null) {
			setFieldsForm({ ...fieldsForm, role: roleObj[0].name, roleId: roleObj[0].id, })
		}
		else {
			setFieldsForm({ ...fieldsForm, role: "", roleId: "" })
		}
	}

	useEffect(() => {
		console.log(count?.toString().length)
	}, [count])


	const handleLimitInput = (e: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>) => {
		console.log('evento handleLimitInput', e)

	}
	return (
		<Box>
			<TextField
				required
				disabled={false}
				variant="outlined"
				label={t("user.user_name")}
				placeholder={t("user.user_name")}
				value={fieldsForm.name}
				onChange={getValuesNewUser('name')}
			/>
			<TextField
				required
				disabled={false}
				variant="outlined"
				label={t("user.user_eid")}
				placeholder={t("user.user_eid")}
				value={fieldsForm.username}
				onChange={getValuesNewUser('username')}
			/>
			{/* <TextField
				required
				disabled={false}
				variant="outlined"
				label={t("user.user_email")}
				placeholder={t("user.user_email")}
				value={fieldsForm.email}
				onChange={getValuesNewUser('email')}
			/> */}
			<TextField
				required
				disabled={isUpdate}
				variant="outlined"
				label={t("user.user_pid")}
				placeholder={t("user.user_pid")}
				type="number"
				value={fieldsForm.pid}
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				onChange={getValuesNewUser('pid')}
			/>
			<Autocomplete
				options={allRolesString}
				// getOptionLabel={(option) => option.name}
				isOptionEqualToValue={(option, value) => option === value}
				onChange={onChangeAutoComplete}
				value={fieldsForm.role || null}
				renderInput={(params: any) => (
					<TextField
						{...params}
						label="Perfil"
						variant="outlined"
						placeholder={t("user.user_profile")}
					/>
				)}
			/>
		</Box>
	);
};

export default UserInputs;
export { UserInputs };
