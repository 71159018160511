import { Box, Grid } from '@people-first/atoms';
import { Breadcrumb } from '@people-first/molecules';
import { useSelector } from 'react-redux';

type GlobalTemplateType = {
    children: JSX.Element;
    paddingRight?: string;
};

const GlobalTemplate = ({ children, paddingRight = '64px' }: GlobalTemplateType) => {

    const breadcrumb = useSelector<any, Array<string>>(({ app }) => app.breadcrumb)

    return (
        <Grid container spacing={2} height={'100%'}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11} paddingRight={paddingRight}>
                <Box width="100%" mt={10} marginBottom={0}>
                    {breadcrumb && <Breadcrumb data={breadcrumb} />}
                    {children}
                </Box>
            </Grid>
        </Grid>
    );
};

export default GlobalTemplate;

export { GlobalTemplate };
