import { AccentureLogo, LoginBackgroundImage } from '@people-first/assets'
import { Box, Grid } from '@people-first/atoms';
type LoginTemplateType = {
    header?: JSX.Element;
    form?: JSX.Element;
    footer?: JSX.Element;
};

export const LoginTemplate = ({ header, form, footer }: LoginTemplateType) => {
    return (
        <>
            <Box
                px={2}
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    backgroundImage: `url(${LoginBackgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={1.5}></Grid>
                    <Grid item xs={3} mt={16}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexWrap="nowrap"
                            width="368px"
                        >
                            {header}
                        </Box>
                        <Box>{form}</Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="368px"
                        >
                            {footer}
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                    alignSelf="flex-end"
                    component="img"
                    width="163.4px"
                    sx={{ marginBottom: '50px', marginRight: '74.6px' }}
                    src={AccentureLogo}
                    alt="Logotipo da aplicação People First"
                />
            </Box>
        </>
    );
};
