
import { i18n } from "@people-first/i18n";
import { useRef, useState } from "react"
import { AbstractControl } from "./AbstractControl";
import { FormGroup } from "./FormGroup";
import { FormView } from "./FormView";
import { SelectControl } from "./SelectControl";

export interface StepFormContext {
    steps: Array<any>;
    form: FormGroup<any, any>;
    control: FormGroup<any, any>;
    active: number;
    namespace: string;
    destroy: Function;
    content: () => JSX.Element;
    isLastStep: boolean;
    isFirstStep: boolean;
    nextStep: () => void;
    prevStep: () => void;
}

const defaultContentComponent = ({ control, context }: any) => (
    <FormView
        key={control.name}
        sx={{ width: '100%', }}
        form={control}
    />
)

export const useStepForm = (factory: () => any): StepFormContext => {
    let [active, setCurrent] = useState<number>(0)
    let [change, setChange] = useState<number>(0)
    let [context, setContext] = useState<any>(undefined)
    let [control, setControl] = useState<any>(undefined)
    let data = useRef({});

    if (!context) {
        const namespace = crypto.randomUUID();
        context = factory();
        context.namespace = namespace;
        context.destroy = () => {
            i18n.removeResourceBundle(i18n.language, namespace);
        }
        context.form = context.form();

        context.form
            .find((control: AbstractControl<any, any, any>) => control instanceof SelectControl)
            .forEach((control: SelectControl) => {
                control.optionsChange(({ items }: any) => {
                    const resources = (items as Array<any>).reduce((current, { label, value }) => ({ ...current, [value]: label }), {})
                    i18n.addResourceBundle(i18n.language, namespace, resources);
                })
            });
        setContext({ ...context });
    }
    const { form } = context;
    const step = context.steps[active];

    console.log("useStepForm", form.value, form.erros);

    if (!control) {
        control = form.slice(step.select || [])
        setChange(change++);
        control.valueChange(() => setChange(change++));
        setControl(control);
    }

    const lastIndex = context.steps.length - 1;

    const contextRef = {
        ...context,
        control,
        active,
        data,
        content: () => (step.content || defaultContentComponent)({
            control,
            context: contextRef,
            namespace: context.namespace
        }),
        isLastStep: active == lastIndex,
        isFirstStep: active == 0,
        nextStep: () => {
            setControl(undefined);
            setCurrent(Math.min(active + 1, lastIndex));
        },
        prevStep: () => {
            setControl(undefined);
            setCurrent(Math.max(0, active - 1));
        },
    };

    return contextRef;
}