import React, { MutableRefObject, useState } from 'react';
import { Box, Text } from '@people-first/atoms';
import { useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';


type UploadFileProps = {
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    label: string;
    value?: string;
    accept?: string;
    description?: string;
    error: any;
    multiple?: boolean;
    tooltipTitle?: string;
    tooltipContent?: string[];
    onClick?: React.MouseEventHandler<HTMLLabelElement>;
    children?: JSX.Element;
    doUpload: (files: any) => Promise<string>;
    onChange: (event: any) => void;
};


const UploadFile: React.FC<UploadFileProps> = ({
    accept,
    doUpload,
    onChange,
    multiple,
    children,
}) => {
    const { t } = useTranslation()
    const inputFileRef = React.useRef<any>();

    const inputFileChangeHandler = (event: any) => {
        doUpload(event.target.files).then((result) => onChange(result))
    }

    return (
        <>
            <input
                ref={inputFileRef}
                style={{ display: 'none' }}
                type="file"
                accept={accept}
                onChange={inputFileChangeHandler}
                multiple={multiple}
                hidden
            />
            <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ border: '1px dashed #ccc', borderRadius: '5px', minHeight: '273px' }}
                onClick={(event: any) => inputFileRef.current?.click()}
            >
                <Box marginRight={"32px"}>
                    <Box display="flex" justifyContent={"center"}>
                        <Text variant='h6'>{t("design.atom.field.file_upload_label")}</Text>
                    </Box>
                    {
                        accept && (
                            <Box display="flex" justifyContent={"center"}>
                                <Text variant='body2'>{t("design.atom.field.file_upload_hint", { accept })}</Text>
                            </Box>
                        )
                    }
                    <Box display="flex" justifyContent={"center"}>
                        <FileUploadIcon />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export { UploadFile };