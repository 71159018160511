import { config } from "@people-first/config";
import { ApiClient as ApiClientGenerated, ApiError, CancelablePromise } from "./client";
import { ApiRequestOptions } from "./client/core/ApiRequestOptions";
import { AxiosHttpRequest } from "./client/core/AxiosHttpRequest";

export * from "./client"

class HttpRequest extends AxiosHttpRequest {
    public events = new EventTarget()

    /**
    * Request method
    * @param options The request options from the service
    * @returns CancelablePromise<T>
    * @throws ApiError
    */
    public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const request = super.request<T>(options);

        request.catch((error) => {
            this.events.dispatchEvent(new CustomEvent("request.error", { detail: error }))
        });

        return request;
    }
}

export class ApiClient extends ApiClientGenerated {
    public onError(fn: (err: ApiError) => void) {
        const { events } = (this.request as HttpRequest);
        function eventHandler(event: any) { fn(event.detail) }
        events.addEventListener("request.error", eventHandler, true)
        return () => events.removeEventListener("request.error", eventHandler, true)
    }
}

export const api = new ApiClient({
    BASE: config.API_URL,
    HEADERS: () => Promise.resolve({
        Autentication: `Bearer ${localStorage.getItem('accessToken')!}`,
    }),
    TOKEN: () => Promise.resolve(localStorage.getItem('accessToken')!),
}, HttpRequest)
