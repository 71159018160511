import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
import { SpacingText } from '../TextTheme';

const { peopleFirst } = tokens;

const RootFormControlLabel: Components<Omit<Theme, 'components'>> = {
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                letterSpacing: SpacingText.spacing(2),
                color: peopleFirst.shades.black.value,
                paddingRight: '45px'
            },
        },
    },
};

export default RootFormControlLabel;
export { RootFormControlLabel };
