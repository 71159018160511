import React from "react";
import { TextField } from "./fields/TextField";
import { DropDownField } from "./fields/DropDown";
import { RadioGroupField } from "./fields/RadioGroup";
import { AutocompleteField } from "./fields/Autocomplete";
import { CheckboxesTagsField } from "./fields/CheckboxesTagsField";
import { AvatarUploadField } from "./fields/AvatarUpload";
import { SwitchField } from "./fields/SwitchField";
import { FileUploadField } from "./fields";
import { ListWitHAutocompleteField } from "./fields/ListWithAutocomplete";
import { TransferList } from "./fields/TransferList";

export const FormFields = (() => {
  const fields = new Map();

  return {
    set: (field: any, ...types: Array<string>) => types.forEach(type => fields.set(type, field)),
    get: (name: string) => fields.get(name),
    render: (control: any) => {
      if (!control) return;

      const Handler = (control.renderComponent)
        ? control.renderComponent
        : fields.get(control.type);

      return Handler ? <Handler control={control}></Handler> : null;
    },
  };
})();

FormFields.set(TextField, "text", "number");
FormFields.set(DropDownField, "select");
FormFields.set(RadioGroupField, "radio");
FormFields.set(AutocompleteField, "autocomplete");
FormFields.set(SwitchField, 'switch');
FormFields.set(CheckboxesTagsField, 'checkboxes-tags')
FormFields.set(AvatarUploadField, 'avatar-upload')
FormFields.set(FileUploadField, 'file-upload')
FormFields.set(ListWitHAutocompleteField, 'list-with-autocomplete')
FormFields.set(TransferList, 'transfer-list')