import { WithPermissionOptions, OneOf, AllOf, AnyOf } from "./interfaces";

export function isOneOf(config: WithPermissionOptions): config is OneOf {
  return Boolean((config as OneOf)?.oneOf);
}

export function isAllOf(config: WithPermissionOptions): config is AllOf {
  return Boolean((config as AllOf)?.allOf);
}

export function isAnyOf(config: WithPermissionOptions): config is AnyOf {
  return Boolean((config as AnyOf)?.anyOf);
}
