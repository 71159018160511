import { Box, Text } from '@people-first/atoms';
import { useTranslation } from 'react-i18next';
import { GlobalTemplate } from '@people-first/templates'
import { LockPersonOutlined } from '@mui/icons-material';

// todo: adicionar pra ficar na pagina inteira (css)
// todo: alterar o enviar emai por uma ação de solicitação de acesso

export const NoAccessPage = () => {
    const { t } = useTranslation();
    const message = t("no_access.content_message").split('\n');
    return (
        <>
            <GlobalTemplate>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={'100%'}
                    margin={'auto'}
                    maxWidth={'420px'}
                    textAlign={'center'}
                >
                    <LockPersonOutlined color={'secondary'} sx={{ fontSize: '8rem' }} />
                    <Text variant='h6'>{t("no_access.content_title")}</Text>
                    {message.map(text => (
                        <Text key={text} variant='body2'>{text}</Text>
                    ))}
                </Box>
            </GlobalTemplate>
        </>
    );
};