import React, { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { ThemeProvider, useTheme, useMediaQuery } from '@mui/material';
import { DefaultTheme } from '@people-first/themes';
import { ForgotPassword } from './app/ui/login/ForgotPassword';
import { ResetPassword } from './app/ui/login/ResetPassword';
import { useAppDispatch } from '@people-first/hooks'
import { blockForSmallDevicesAction, unblockForSmallDevicesAction } from '@people-first/store'
import { PageExtras, Pages, Routes } from '@people-first/pages';
import { DialogLogout } from '@people-first/molecules';
import { withPermission } from '@people-first/shared';
import Login from './app/ui/login/Login';
import StopBreakpoint from './app/ui/breakpoint/StopBreakpoint';

const renderRoutes = (pages: Routes, extra: PageExtras) => {


  return Object.entries(pages).map(([path, { component, laod, withAccess, children }]: any) => {
    let Component = component
    let loader = undefined

    if (component?.component) {
      Component = component?.component

      if (component?.getStaticProps) {
        loader = async ({ params }: any) => component?.getStaticProps({ params })
      }
    }
    // Registra os componentes de páginas carregadas com lazy-loading
    if (laod instanceof Function) {
      const LazyComponent = React.lazy(laod)
      Component = () => (
        <React.Suspense fallback={<>...</>}>
          <LazyComponent />
        </React.Suspense>
      )
    }

    /* Define permissionamento da página caso definido*/
    if (withAccess) {
      Component = withPermission(Component, {
        onDenied: extra?.onAccessDenied,
        ...withAccess,
      });
    }

    return (
      <Route
        key={path}
        path={path}
        loader={loader}
        element={<Component />}
        children={children && renderRoutes(children, extra)}
      />
    );
  })
}

const AppRoutes = ({ pages }: { pages: Pages }) => {

  const theme = useTheme();

  const dispatch = useAppDispatch()

  const belowWindowSize = useMediaQuery(theme.breakpoints.down('md')); //Verifico se o tamanho da janela é menor que 900px

  useEffect(() => { // Com o UseEffect fico observando se houve alteração na variável belowWindowSize se sim, faço a alteração do State
    belowWindowSize
      ? dispatch(blockForSmallDevicesAction()) // se for menor altero o State isOpen para true
      : dispatch(unblockForSmallDevicesAction()) // se for maior altero o State isOpen para false

  }, [belowWindowSize])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {renderRoutes(pages.routes, pages.extra)}
      </>
    )
  );

  return (
    <ThemeProvider theme={DefaultTheme}>
      <DialogLogout />
      <StopBreakpoint />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default AppRoutes;
