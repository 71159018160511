import { IconButtonProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;

type TypeIconButton = {
  props: IconButtonProps;
  style: Interpolation<{
    theme: Theme;
  }>;
};

const IconButtonThemeVariants: TypeIconButton[] = [
  //   // text variants
  {
    props: { disabled: true },

    style: {
      // backgroundColor:peopleFirst.greys['lighten-4'].value,
      '.MuiSvgIcon-root': {
        backgroundColor: 'none',
        border: 'none',
        opacity: 0.3

      }

    },
  },
  {
    props: { className: 'actionsMenu' },

    style: {
      color: "primary",
      backgroundColor: peopleFirst.greys['lighten-4'].value,
      "&:hover": {
        backgroundColor: peopleFirst.custom['accent-two'].value,
        "& .MuiSvgIcon-root": {
          color: peopleFirst.custom.primary.value,
        }
      },

    },
  },
]

export default IconButtonThemeVariants