import React from "react";
import MuiStep, { StepProps, } from '@mui/material/Step';

const Step: React.FC<StepProps> = (props) => {
    return <MuiStep {...props} />;
};

export default Step;
export { Step };



