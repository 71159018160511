import { DetailsList } from '@people-first/organisms';
import { t } from 'i18next';
import { Box, Text } from '@people-first/atoms';
import { toPrivilegeKey } from '@people-first/shared';

const RoleDetail = ({ role }: any) => {
    return (
        <Box style={{ flexDirection: 'column' }}>
            <Text variant="subtitle1">{role.name}</Text>
            <Box>
                <DetailsList
                    title={t('role.summary_title_privileges')}
                    list={role.privileges?.map((content: string) => (
                        { content: toPrivilegeKey(content) }
                    ))}
                />
            </Box>
        </Box>
    );
};

export default RoleDetail;