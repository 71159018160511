import { api } from "@people-first/api";

export const FileDataSource = {
    async create({ name, size, type }: File) {
        return api.file.create({
            requestBody: {
                name,
                size,
                type,
                canAccess: []
            }
        }).then(({ data }: any) => data)
    },
    async upload(url: string, body: any) {
        return await fetch(url, {
            method: 'PUT',
            body
        });
    }
}