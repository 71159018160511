import { Components, Theme } from '@mui/material';

import ButtonThemeVariants from '../../atom/button/ButtonThemeVariants';

const RootButtonTheme: Components<Omit<Theme, 'components'>> = {
    MuiButton: {
        variants: ButtonThemeVariants,
        styleOverrides: {
            root: {
                padding: '0px 16px',
                fontSize: '14px',
                lineHeight: '36px',
            },
        },
    },
};

export default RootButtonTheme;
export { RootButtonTheme };
