import { Link, SxProps, Theme } from '@mui/material';
import MuiBreadcrumb, { BreadcrumbsTypeMap, } from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router';
import Text from '../../atom/text/Text'

type TypeBreadcrumb = {
    data: string[],
    sx?: SxProps<Theme>,
};

const Breadcrumb = ({
    data,
    sx
}: TypeBreadcrumb) => {
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent, path: string) => {
        event.preventDefault();
        path && navigate(path)
    }
    return (
        <MuiBreadcrumb sx={[{ my: '18px' }, ...Array.isArray(sx) ? sx : [sx]]}>
            {data.map(([dataValue, path]: any, index) => {
                return (index !== data.length - 1)
                    ? (
                        <Link
                            key={index + path}
                            underline="none"
                            color="inherit"
                            href={path || null}
                            onClick={(event) => handleClick(event, path)}
                        >
                            {dataValue}
                        </Link>
                    )
                    : (<Link key={index + path} underline="none" href={undefined} > {dataValue}</Link>)
            })}
        </MuiBreadcrumb >
    );
};

export default Breadcrumb;
export { Breadcrumb };