import React, { useState, useEffect } from 'react';
import { ButtonType } from '@people-first/atoms/button/Button';
import { Grid, Text, Button, Box, List, ListItem, ListItemIcon, Checkbox, Paper, } from '../../atom'
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { t } from 'i18next';

type TypeData = {
    value: any;
    label: string;
    checked: boolean;
};

type TypeTransferList = {
    leftData: TypeData[];
    rightData: TypeData[];
    leftLabel?: string;
    rightLabel?: string;
    onChange?: (arg: { left: TypeData[], right: TypeData[] }) => void;
    onCheck?: any;
    parentHeight?: string | number;
};

const ListLabel = ({ label }: { label: string | undefined }) => (
    <Box style={{ paddingTop: '24px', }}>
        <Text className="transferListLabel">{label}</Text>
    </Box>
)

const MoveButton = (props: ButtonType & { icon: any }) => (
    <Button sx={{ my: 0.5 }} variant="outlined" size="small"{...props}>
        {props.icon}
    </Button>
)

const CustomList = ({ items, onSelectionChange }: { items: TypeData[], onSelectionChange: Function }) => (
    <Box style={{ height: '100%', maxHeight: '100%' }}>
        <Box style={{ marginTop: '16px', }}>
            <Paper sx={{ overflow: 'auto', height: /*parentHeight ||*/  '100%', }}>
                <List dense component="div" role="list">
                    {items.map((item: TypeData, index) => {
                        const { value, label, checked = false } = item
                        const itemId = `transfer-list-item-${value}-label`;
                        return (
                            <ListItem
                                key={value}
                                role="listitem"
                                onClick={() => {
                                    onSelectionChange([
                                        ...items.slice(0, index),
                                        { ...item, checked: !checked },
                                        ...items.slice(index + 1)
                                    ])
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': itemId,
                                        }}
                                    />
                                </ListItemIcon>
                                <Text id={itemId} variant="body2">{t<string>(label)}</Text>
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
        </Box>
    </Box >
);

const isChecked = ({ checked }: TypeData) => checked;

const uncheck = (item: TypeData) => ({ ...item, checked: false });
const sortItems = (values: Array<any>) => {
    values.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA === labelB) {
            return 0;
        } else if (labelA < labelB) {
            return -1;
        } else {
            return 1;
        }
    })
    return values;
}

export const TransferList = ({
    leftData,
    rightData,
    leftLabel,
    rightLabel,
    onChange,
}: TypeTransferList) => {
    const [left, setLeft] = useState<TypeData[]>(leftData || []);
    const [right, setRight] = useState<TypeData[]>(rightData || []);

    const moveAllRight = () => {
        setRight(sortItems(
            right.concat(left.map(uncheck))
        ));
        setLeft([]);
    };

    const moveAllLeft = () => {
        setLeft(sortItems(
            left.concat(right.map(uncheck))
        ));
        setRight([]);
    };

    const moveSelection = (source: TypeData[], target: TypeData[]): Array<TypeData[]> => {

        const [uncheckedItems, checkedItems] = source.reduce(
            ([unchecked, checked], item) => {
                (item.checked ? checked : unchecked).push({ ...item, checked: false })
                return [unchecked, checked]
            },
            [new Array(), new Array()]
        );

        return [
            sortItems(uncheckedItems),
            sortItems(target.concat(checkedItems)),
        ]
    }

    const handleCheckedRight = () => {
        const [_left, _right] = moveSelection(left, right);
        setLeft(_left)
        setRight(_right)
    };

    const handleCheckedLeft = () => {
        const [_right, _left] = moveSelection(right, left);
        setLeft(_left)
        setRight(_right)
    }

    const setSortedLeft = (items: Array<any>) => setLeft(sortItems(items))
    const setSortedRight = (items: Array<any>) => setRight(sortItems(items))

    useEffect(() => { onChange?.({ left, right }); }, [left.length, right.length])

    useEffect(() => { setSortedRight(rightData); }, [rightData])

    useEffect(() => { setSortedLeft(leftData) }, [leftData])

    return (
        <Grid
            container
            columnSpacing={0}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ width: '100%', flexGrow: 1 }}
        >
            <Grid item xs={5} container direction="column">
                <ListLabel label={leftLabel} />
                <CustomList items={left} onSelectionChange={setSortedLeft} />
            </Grid>
            <Grid item xs={2}>
                <ListLabel label={''} />
                <Grid container direction="column" alignItems="center">
                    <MoveButton
                        onClick={moveAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                        icon={<KeyboardDoubleArrowRight />}
                    />
                    <MoveButton
                        onClick={handleCheckedRight}
                        disabled={!left.some(isChecked)}
                        aria-label="move selected right"
                        icon={<KeyboardArrowRight />}
                    />
                    <MoveButton
                        onClick={handleCheckedLeft}
                        disabled={!right.some(isChecked)}
                        aria-label="move selected left"
                        icon={<KeyboardArrowLeft />}
                    />
                    <MoveButton
                        onClick={moveAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                        icon={<KeyboardDoubleArrowLeft />}
                    />
                </Grid>
            </Grid>
            <Grid item xs={5} container direction="column">
                <ListLabel label={rightLabel} />
                <CustomList items={right} onSelectionChange={setSortedRight} />
            </Grid>
        </Grid>
    );
}

export default TransferList;