import { createAction } from "@reduxjs/toolkit";


type BreadcrumbLabel = string & Partial<{ meta: string }>
type BreadcrumbRoute = string & Partial<{ meta: string }>

export type BreadcrumbEntry = [BreadcrumbLabel, BreadcrumbRoute];

export const setPageBreadcrumb = createAction<Array<BreadcrumbEntry>>("setPageBreadcrumb")

export const pushNotificationAction = createAction<any>("pushNotificationAction")

export const removeNotificationAction = createAction<string>("removeNotificationAction")

export const setMainMenuAction = createAction<any>("setMainMenuAction")

export const setActiveMenuAction = createAction<any>("setActiveMenuAction")

export const toggleMainMenuAction = createAction("toggleMainMenuAction")