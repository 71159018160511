import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootTooltipTheme: Components<Omit<Theme, 'components'>> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: peopleFirst.greys.grey.value,
        backgroundColor: peopleFirst.shades.white.value,
        border: `1px solid ${peopleFirst.greys.grey.value}`,
        padding: '15px',
        display: 'flex',
        textAlign: 'center',
        width: '269px',
        borderRadius: '4px'
      }
    },
  },
};

export default RootTooltipTheme;
export { RootTooltipTheme };
