
import { useTranslation } from 'react-i18next'
import { PeopleFirstLogo } from '@people-first/assets'
import { Box, Text } from '../../atom';


const HeaderLogin = () => {
    const { t } = useTranslation()
    return (
        <>
            <Text variant="body1" color="secondary" marginBottom="0.375rem">
                {t('login.welcome')}
            </Text>

            <Box
                width='288px'
                component="img"
                sx={{ marginBottom: '1.625rem' }}
                src={PeopleFirstLogo}
                alt={t("logotype.alt_text")}
            />

            <Text variant="body1" color="secondary" width='260px' marginBottom="2.125rem">
                {t('login.header_our_platform')}
            </Text>

            <Text variant="subtitle1" color="secondary" marginBottom="1.625rem">
                {t('login.header_your_login')}
            </Text>
        </>
    )
};
export default HeaderLogin;
export { HeaderLogin };
