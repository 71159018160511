import { Suggest } from "src/app/shared";
import { MenuDataSource } from "@people-first/data-sources";
import { i18n } from "@people-first/i18n";

export const MainMenuSuggest = new Suggest(async (term: string) => {
    return MenuDataSource.find({ query: {}, page: 0, itemsPerPage: 10 })
        .catch(() => {
            return { items: [], resultSizeEstimate: 0, }
        })
        .then(({ items, resultSizeEstimate }) => {
            return {
                items: items.map((item: any) => ({ label: i18n.t(item.name), value: item.id })),
                resultSizeEstimate,
            }
        });
}, {
    fetch: "once"
});
