import { useTranslation } from 'react-i18next';
import { Text, Box, Paper, Checkbox, List, ListItem, ListItemIcon, } from '../../atom';
import { tokens } from '@people-first/design/tokens';

type TypeDetailsList = {
    title?: string;
    list?: any;
    children?: JSX.Element;
    parentKeyTranslation?: string;
    displayField?: any;
    displayParenthesesField?: any;
};

const lighten2 = tokens.peopleFirst.greys['lighten-2'].value

export default function DetailsList({ title, list = [] }: TypeDetailsList) {
    const { t } = useTranslation()
    return (
        <Box sx={{ marginTop: '32px', }}>
            <Text variant="body2">{title}</Text>
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    border: `solid 1px ${lighten2}`,
                    marginTop: '16px',
                }}
            >
                <List>
                    {list.map((item: any) => (
                        <ListItem dense key={item.id || item.content}>
                            <ListItemIcon>
                                <Checkbox disabled checked tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Text variant="body2">{t(item.content)}</Text>
                                <Text variant="caption" color="secondary">{item.description}</Text>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
}
