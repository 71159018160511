import { api } from "@people-first/api";
import { Suggest, toPrivilegeKey } from "@people-first/shared";

export const createPrivilegeSuggest = () => {
    return new Suggest(
        async (term: string) => {
            const options = {
                q: "",
                fields: "name",
                page: 0,
                itemsPerPage: 20,
            };

            return api.role.getPrivileges(options)
                .then(({ privileges }) => ({ items: privileges, resultSizeEstimate: privileges.length }))
                .catch(() => ({ items: [], resultSizeEstimate: 0 }))
                .then(({ items, resultSizeEstimate }) => ({
                    items: items.map((value: any) => ({
                        label: toPrivilegeKey(value),
                        value
                    })),
                    resultSizeEstimate
                }));
        }, { fetch: "once" }
    );
}
