import React, { MutableRefObject, useState } from 'react';
import Avatar from '../../atom/avatar/Avatar';
import IconButton from '../../atom/icon-button/IconButton';
import { Box, DynamicIcon } from '@people-first/atoms';
import { tokens } from '@people-first/design/tokens';

import { BottomBackgroundAvatarImage } from '@people-first/assets';
import { UploadAvatarDescription } from './UploadAvatarDescription';

type UploadAvatarProps = {
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    // infoTextTitle: string;
    // infoText: string;
    // iconColor: string;
    // widthTextPx: string;
    label: string;
    value?: string;
    description?: string;
    error: any;
    tooltipTitle?: string;
    tooltipContent?: string[];
    // photoState: string;
    onClick?: React.MouseEventHandler<HTMLLabelElement>;
    //     onUpload?: (event: React.SyntheticEvent & { currentTarget: HTMLInputElement }) => void;
    children?: JSX.Element;
    doUpload: (files: any) => Promise<string>;
    onChange: (event: any) => void;
};

const UploadAvatar: React.FC<UploadAvatarProps> = ({
    // onUpload,
    // photoState,
    // readOnly,
    value,
    label,
    description,
    doUpload,
    onChange,
    children,
    tooltipTitle,
}) => {
    const inputFileRef = React.useRef<any>();
    const [imageSrc, setImageSrc] = useState(value)

    const upload = (event: any) => {
        doUpload(event.target.files).then((url) => {
            setImageSrc(url);
            onChange(url);
        })
    }

    const { peopleFirst } = tokens;
    return (
        <>
            <Box display="flex" alignItems={"center"}>
                <Box marginRight={"32px"}>
                    <input
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={upload}
                        hidden
                    />
                    <IconButton
                        component="label"
                        onClick={(event: any) => inputFileRef.current?.click()}
                    >
                        <Avatar
                            style={{
                                height: '145px',
                                width: '145px',
                                zIndex: 2,
                                position: 'relative',
                                borderRadius: '100%',
                                backgroundColor: peopleFirst.greys.grey.value
                            }}
                            src={imageSrc}
                        >
                            <DynamicIcon iconName='PersonOutlined' sx={{ color: '#fff', width: '80.17px', height: '50.33px' }} />
                        </Avatar>
                        <Box
                            component="img"
                            src={BottomBackgroundAvatarImage}
                            sx={{
                                zIndex: 3,
                                width: '146px',
                                height: '142px',
                                position: 'absolute',
                                top: '10px',
                            }}
                        />
                    </IconButton>
                </Box>
                <UploadAvatarDescription
                    title={label}
                    description={description}
                />
            </Box>
        </>
    );
};

export default UploadAvatar;
export { UploadAvatar };

{/* {!readOnly ? (
                
            ) : (
                <IconButton component="label">
                    <Avatar
                        style={{
                            height: '145px',
                            width: '145px',
                            zIndex: 2,
                            position: 'relative',
                            borderRadius: '100%',
                            backgroundColor: peopleFirst.greys.grey.value
                        }}
                        src={photoState}
                    >
                        <DynamicIcon iconName='PersonOutlined' sx={{ color: '#fff', width: '80.17px', height: '50.33px' }} />
                    </Avatar>
                </IconButton>
            )} */}