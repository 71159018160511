import { Suggest } from "src/app/shared";
import { createOrganizationHandlerByQuery } from "./organization.suggest";

const getOrganizationsBy = createOrganizationHandlerByQuery(
    (term) => [['parentId', 'equals', term]]
);

export const createChildrenOrgSuggest = () => {
    return new Suggest(
        (parentId: string) => getOrganizationsBy(parentId).then((response) => {

            response.items = response.items.map(({ id, label, value }) => ({
                id,
                label,
                value,
            }))

            return response;
        }),
        { fetch: "once" }
    );
}