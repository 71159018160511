import { FormControl } from "../FormControl";
import MuiTextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { InputContainer } from "./InputContainer";


export function TextField({ control }: { control: FormControl }) {
  const { i18nNS = "" } = control.extras;
  const { t } = useTranslation([i18nNS as string]);

  const { erros } = control;
  const hasError = control.dirty && Boolean(erros);

  // const helperText = hasError ? (erros ? t(erros.join(", ") || "") : "") : "";
  // console.log(hasError, erros, helperText);

  const helperText = t(control.description);
  return (
    <InputContainer>
      <MuiTextField
        required={control.required}
        key={control.name}
        type={control.type}
        name={control.name}
        sx={{ width: '100%' }}
        error={hasError}
        helperText={helperText}
        variant="outlined"
        label={t(control.label)}
        placeholder={t(control.placeholder)}
        value={control.value || ""}
        onChange={(event) => control.setValue(event.target.value)}
        disabled={control.disabled}
      />
    </InputContainer>
  );
};
