import { createReducer, } from '@reduxjs/toolkit';
import { cleanLoginErrorAction, loginAction, logoutAction } from '../actions';

const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

type TypeUserInfo = {
  // access_token: string | null,
  // data: any | null,
  // statusCode: string | null,
  created_at?: string,
  deleted_at?: string,
  email?: string,
  id: string | null,
  is_active?: boolean,
  name?: string,
  org_level_access?: string | number,
  password_changed_at?: string,
  password_reset_token?: string,
  password_reset_token_expiration?: string,
  picture?: string,
  row_version?: string | number,
  pid?: string | number,
  status?: boolean,
  updated_at?: string,
  username: string,
  privileges: Array<string>,
}

type TypeUserState = {
  loading: boolean,
  userInfo: TypeUserInfo,
  userToken: string | null, // interface string or null
  error: string | null, // interface string or null
  success: boolean,
  // status: 'idle' | 'loading' | 'failed';
}

const initialState: TypeUserState = {
  loading: false,
  userInfo: {
    username: "",
    id: null,
    privileges: []
  }, // User object
  userToken, // Access token
  error: null,
  success: false, // for monitoring the registration process.
}


export const loginReducer = createReducer<TypeUserState>(initialState, {
  [logoutAction.type]: (state) => {
    localStorage.removeItem('accessToken');
    state.loading = false;
    state.userInfo = { username: "", id: "", privileges: [] };
    state.userToken = null;
    state.error = null;
  },
  [cleanLoginErrorAction.type]: (state) => {
    state.error = null;
  },
  [loginAction.pending.type]: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  [loginAction.fulfilled.type]: (state, { payload }) => {
    const { access_token, user } = payload;
    localStorage.setItem('accessToken', access_token);
    state.loading = false;
    state.userInfo = user;
    state.userToken = access_token;
  },
  [loginAction.rejected.type]: (state, { payload }) => {
    state.loading = false;
    state.error = JSON.stringify(payload);
  }
});