import { Box, Grid, Text } from '@people-first/atoms';
import GlobalTemplate from '../global/GlobalTemplate';
import { DisplayLoading, FormGroup, useForm } from '@people-first/molecules';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import React, { useState } from 'react';
import { DataSource } from 'src/app/data-source';

export type FormTemplateType = {
    children: JSX.Element;
    title?: string;
    goBackButton?: JSX.Element;
    saveButton?: JSX.Element;
    isLoading?: boolean;
    width: string; // sempre ajustar de acordo com a largura dos inputs
    // todo: tornar obrigatório
    formFactory?: () => FormGroup<any, any>;
    onBackNavigation?: () => void;
    dataSource?: DataSource<unknown>
    afterSubmit?: () => void
};

export const FormTemplateContext = React.createContext<any>(null);

const FormTemplate = ({
    formFactory,
    title,
    goBackButton,
    saveButton,
    width,
    children,
    onBackNavigation,
    afterSubmit,
    dataSource,
}: FormTemplateType) => {
    const { t } = useTranslation();
    const { form } = useForm(formFactory!)
    const context = { form };
    const [isLoading, setIsLoading] = useState(false);

    const backButton = onBackNavigation && <Button sx={{ marginTop: 0 }} startIcon={<ArrowBack />} onClick={onBackNavigation}>{t('button.go_back')}</Button>;
    const submitForm = () => {
        setIsLoading(true);
        dataSource?.save!(form.value)
            .then(() => afterSubmit!())
            .finally(() => setIsLoading(false))
    }

    return (
        <GlobalTemplate>
            {isLoading ? (
                <DisplayLoading />
            ) : (
                <>
                    <FormTemplateContext.Provider value={context} >

                        <Box display="flex" flexDirection="column" width={width} mb="110px">
                            <Box display="flex" flexDirection="column" width={width} zIndex={1}>
                                {title && (
                                    <Box mt='32px'>
                                        <Text variant="h5">{title}</Text>
                                    </Box>
                                )}
                                {children}
                            </Box>
                        </Box>
                        <Grid
                            item
                            xs={11}
                            width="100%"
                            display="flex"
                            position="fixed"
                            justifyContent={backButton ? "space-between" : "end"}
                            alignItems="flex-start"
                            paddingRight="64px"
                            paddingBottom="56px"
                            paddingTop="16px"
                            sx={{
                                top: 'auto',
                                bottom: 0,
                                backgroundColor: '#fff',
                                zIndex: 2,
                            }}
                        >
                            {backButton}
                            <LoadingButton
                                // loading={isLoadingButton}
                                sx={{ marginTop: 0 }}
                                disabled={!(form.dirty && form.valid)}
                                variant='contained'
                                onClick={submitForm}>{t('button.save')}</LoadingButton>
                        </Grid>
                    </FormTemplateContext.Provider>
                </>
            )}
        </GlobalTemplate>
    );
};

export default FormTemplate;
