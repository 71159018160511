/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgotPasswordDto } from '../models/ForgotPasswordDto';
import type { LoginDto } from '../models/LoginDto';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns string
     * @throws ApiError
     */
    public appControllerGetHello(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health_check',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public login({
        requestBody,
    }: {
        requestBody: LoginDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public forgotPassword({
        requestBody,
    }: {
        requestBody: ForgotPasswordDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public resetPassword({
        resetToken,
        requestBody,
    }: {
        resetToken: string,
        requestBody: ResetPasswordDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/reset-password/{resetToken}',
            path: {
                'resetToken': resetToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
