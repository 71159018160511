import { useTranslation } from 'react-i18next'
/**todo: mover isso pra dentro de molecules */
import { useAppDispatch, useAppSelector } from '@people-first/hooks'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Text } from '../../atom';
import { closeLogoutDialog, logoutAction } from '@people-first/store'

const DialogLogout = () => {
    const { t } = useTranslation()
    const { isOpen } = useAppSelector((state) => state.dialogLogout)

    const dispatch = useAppDispatch()

    const handleLogout = () => {
        dispatch(closeLogoutDialog())
        dispatch(logoutAction())
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitle>{t('dialog.logout_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Text
                        sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '26px',
                            letterSpacing: '0.5px',
                        }}
                        variant='body1'> {t('dialog.logout_message')}</Text>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    marginTop: '0px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '26px',
                    letterSpacing: '0.15px'
                }}
                    variant="text" size="medium" disableRipple onClick={handleLogout}
                >
                    {t('dialog.login_button')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogLogout;
export { DialogLogout };

// https://mui.com/material-ui/api/dialog/

