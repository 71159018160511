import React from "react";
import MuiStepper, { StepperProps, } from '@mui/material/Stepper';

const Stepper: React.FC<StepperProps> = (props) => {
    return <MuiStepper {...props} />;
};

export default Stepper;
export { Stepper };



