import { pages } from "@people-first/pages";

export const menu = [
    {
        ...pages.get("page.home"),
        icon: 'HomeOutlined',
    },
    { type: 'divider' },
    // {
    //     ...pages.get("diversity.a"),
    //     icon: 'Diversity1',
    //     text: "Diversidade",
    //     // children: [
    //     //     {
    //     //         ...pages.get("page.diversity.retention"),
    //     //         // text: "Colaboradores",
    //     //         // path: "/home",
    //     //     },
    //     //     {
    //     //         ...pages.get("page.diversity.retention"),
    //     //         // text: "Relatórios",
    //     //         // path: "/home",
    //     //     }
    //     // ]
    // },
    // {
    //     // text: "Job Rotation",
    //     ...pages.get("page.diversity.retention"),
    //     icon: 'ChangeCircleOutlined',
    //     // children: [
    //     //     {
    //     //         text: "Consolidado",
    //     //         path: "/home",
    //     //     },
    //     //     {
    //     //         text: "Lista Ativa",
    //     //         path: "/home",
    //     //     },
    //     //     {
    //     //         text: "Lista Geral",
    //     //         path: "/home",
    //     //     }
    //     // ]
    // },
    // {
    //     ...pages.get("page.diversity.retention"),
    //     icon: 'PersonOffOutlined',
    //     text: "Leavers",
    // },
    // {
    //     icon: 'CampaignOutlined',
    //     text: "Pesquisas",
    //     children: [
    //         {
    //             ...pages.get("page.retention"),
    //             // text: "Engajamento",
    //         }
    //     ]
    // },
    // {
    //     ...pages.get("page.retention"),
    //     icon: 'ThumbUpAltOutlined',
    //     // text: "Retention",
    //     // path: "/home",
    // },
    { type: 'divider', },
    {
        ...pages.get("page.loading_data"),
        icon: 'UploadFileOutlined',
        theme: "system",
    },
    {
        title: 'general.configurations',
        icon: 'SettingsOutlined',
        theme: "system",
        children: [
            {
                ...pages.get("page.organization"),
                theme: "system",
            },
            {
                ...pages.get('page.user'),
                theme: "system",
            },
            {
                ...pages.get('page.role'),
                theme: "system",
            },
        ]
    }
];