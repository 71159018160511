import { api } from "@people-first/api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handlerErrorRespose } from "../utils";

type TypeUserData = {
    EID: string,
    password: string,
};

export const logoutAction = createAction("logoutAction")

export const cleanLoginErrorAction = createAction("cleanLoginError")

export const loginAction = createAsyncThunk(
    'user/login',
    async ({ EID: username, password }: TypeUserData, { rejectWithValue }) => {
        try {
            return api.auth.login({ requestBody: { username, password } }).catch(err => {
                handlerErrorRespose(err)
                throw err;
            })
        } catch (error: any) {
            return rejectWithValue(error?.response?.status)
        }
    }
)