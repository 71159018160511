import { LoadingDataDataSource } from "src/app/data-source";
import { DataGridModel, dataGridPaginationSearch } from "@people-first/organisms";
import { i18n } from "@people-first/i18n";
import { tcol } from "./LoadingDataCreate.resources";
import { ACL } from "./LoadingData.resources";


export function DataLoadDatagridFactory(): DataGridModel {
  const options = {
    sort: { name: 'asc' },
  };

  return new DataGridModel(LoadingDataDataSource, options)
    .persistPagination(dataGridPaginationSearch('p'))
    .setColumns([
      {
        field: "type",
        headerName: `${i18n.t(tcol("type"))}`,
        sortingOrder: ["asc", "desc"],
        flex: 1.1,
        type: 'text.body2',
      },
      {
        field: "name",
        headerName: `${i18n.t(tcol("name"))}`,
        flex: 0.8,
        sortingOrder: ["asc", "desc"],
        type: "text.body2"
      },
      {
        field: "competence",
        headerName: `${i18n.t(tcol("competence"))}`,
        flex: 0.8,
        sortingOrder: ["asc", "desc"],
        type: "text.body2"
      },
      {
        field: "loadingDate",
        headerName: `${i18n.t(tcol("loading_date"))}`,
        flex: 0.8,
        sortingOrder: ["asc", "desc"],
        type: "text.body2"
      },
      {
        field: "size",
        headerName: `${i18n.t(tcol("size"))}`,
        flex: 0.8,
        sortingOrder: ["asc", "desc"],
        type: "text.body2"
      },
      {
        field: "actions",
        type: "actions",
        headerName: `${i18n.t(tcol("actions"))}`,
        flex: .2,
        actions: [
          {
            type: "btn.icon",
            icon: "FileDownload",
            emit: "download",
            canAccess: ACL.download
          },
        ]
      }
    ])
}