import { createReducer } from '@reduxjs/toolkit';
import { getRoleAction, saveRoleAction, updateRoleGridAction } from '../actions';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const roleReducer = createReducer<TypeDialogLogout>(initialState, {
  [getRoleAction.fulfilled.type]: (state) => ({ ...state, blocked: true }),
  [saveRoleAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [updateRoleGridAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
})