import React from 'react';
import { useTranslation } from "react-i18next";

import { Box, DynamicIcon, IconButton, Text, Tooltip } from '../../atom';

type InfoTextProps = {
    title: string;
    description?: string;
    iconColor?: string;
    widthPx?: string;
    tooltipTitle?: string;
    tooltipContent?: string[];
};
const UploadAvatarDescription: React.FC<InfoTextProps> = ({
    title,
    description,
    iconColor,
    tooltipContent,
    tooltipTitle,
}) => {
    const { t } = useTranslation();
    return (
        <Box width={'100%'} paddingRight='-100px'>
            <Box display="flex" marginBottom="8px" alignItems='center' >
                <Text variant="h6" fontWeight={400} marginRight={'32px'}>{t(title)}</Text>
                {
                    tooltipTitle
                        ? (
                            <Tooltip
                                color="primary"
                                placement="right"
                                arrow
                                title={
                                    <Box display="flex" flexDirection="column" alignItems="baseline">
                                        <Text variant="body2" mb="8px" color="primary" fontWeight="400"                            >
                                            {/* {t('user.title_tootltip_photo')} */}
                                            {tooltipTitle}
                                        </Text>

                                        {tooltipContent &&

                                            tooltipContent.map((item: string) => {
                                                return <Text variant="body2">{item}</Text>
                                            })

                                        }
                                        {/* <Text variant="body2">{t('user.message1_tootltip_photo')}</Text>
                            <Text variant="body2" >{t('user.message2_tootltip_photo')}</Text> */}
                                    </Box>
                                }
                            >
                                <div>
                                    <IconButton>
                                        <DynamicIcon iconName='InfoOutlined' color='primary' />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        )
                        : null
                }
            </Box>
            <Text
                sx={{ overflowWrap: 'break-word' }}
                variant="body2"
                letterSpacing='0.25px'
                lineHeight='20px'
                width={'500px'}
                fontSize='14px'>
                {t(description!)}
            </Text>
        </Box>
    );
};

export default UploadAvatarDescription;
export { UploadAvatarDescription };
