import Text from '../../atom/text/Text';
import Box from '../../atom/box/Box';
import { PeopleFirstLogo } from '@people-first/assets'
import { useTranslation } from 'react-i18next'
export default function HeaderBreakpoint() {
  const { t } = useTranslation()
  return (
    <Box >
      <Box display='flex' flexDirection='column' width='213px'>
        <Text variant='body1' mb='10px'>{t('breakpoint.experience_management')}</Text>
        <Box
          width='213px'
          component="img"
          sx={{ marginBottom: '1.625rem' }}
          src={PeopleFirstLogo}
          alt={t("logotype.alt_text")}
        />
      </Box>
    </Box>
  );
}
