import { ChipProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;

type TypeChip = {
    props: ChipProps;
    style: Interpolation<{
        theme: Theme;
    }>;
};

const ChipThemeVariants: TypeChip[] = [
    {
        props: {
            variant: 'filled',
            className: 'active'
        },
        style: {
            fontFamily: 'Graphik',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: peopleFirst.shades.white.value,
            height: '32px',
            width: '60px',
            backgroundColor: peopleFirst.custom.success.value
        },
    },
    {
        props: {
            variant: 'filled',
            className: 'inactive',
        },
        style: {
            borderRAdius: '16px',
            fontFamily: 'Graphik',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: peopleFirst.shades.black.value,
            height: '32px',
            width: '72px',
            backgroundColor: peopleFirst.greys['lighten-3'].value
        },
    },
];

export default ChipThemeVariants;
export { ChipThemeVariants };
