/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLoadingDatumDto } from '../models/CreateLoadingDatumDto';
import type { GetLoadingDatumDto } from '../models/GetLoadingDatumDto';
import type { UpdateLoadingDatumDto } from '../models/UpdateLoadingDatumDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoadingDataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create new menu
     * @returns GetLoadingDatumDto
     * @returns any
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateLoadingDatumDto,
    }): CancelablePromise<GetLoadingDatumDto | any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/loading-data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List all menus
     * @returns GetLoadingDatumDto
     * @throws ApiError
     */
    public list({
        q,
        format,
        page,
        itemsPerPage,
        sort,
        fields,
    }: {
        q?: string,
        /**
         * ex:selection
         */
        format?: string,
        /**
         * Número da página. Default é 1
         */
        page?: number,
        /**
         * Quantidade de items retornados em uma consulta. Default é 10
         */
        itemsPerPage?: number,
        sort?: string,
        fields?: string,
    }): CancelablePromise<GetLoadingDatumDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/loading-data',
            query: {
                'q': q,
                'format': format,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
                'fields': fields,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retorna um menu identificado pelo id
     * @returns GetLoadingDatumDto
     * @throws ApiError
     */
    public get({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetLoadingDatumDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/loading-data/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Atualiza um menu
     * @returns GetLoadingDatumDto
     * @throws ApiError
     */
    public update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateLoadingDatumDto,
    }): CancelablePromise<GetLoadingDatumDto> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/loading-data/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove um menu
     * @returns any
     * @throws ApiError
     */
    public delete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/loading-data/{id}',
            path: {
                'id': id,
            },
        });
    }

}
