

export class VariantEntry<T extends { [key: string]: any }> {
    constructor(protected options: T) { }

    public get(path: string, fallback?: any) {
        return this.options[path] || fallback
    }
}

export class ContextVariant<T extends { [key: string]: any }> {
    protected variants: { [key: string]: VariantEntry<T> } = {};

    constructor(variants: { [key: string]: T }) {
        Object.entries(variants).forEach(([id, variant]) => {
            this.variants[id] = new VariantEntry<T>(variant);
        })
    }

    public get(path: string | Array<string>, fallback?: any) {
        if (typeof path === "string") path = [path];
        for (const _path of path) {
            const variant = this.variants[_path]
            if (variant) return variant;
        }
        return fallback;
    }
}