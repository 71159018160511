import { ButtonWithPermission } from '@people-first/atoms';
import { ListPageTemplate } from '@people-first/templates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataGrid, useDataGrid } from '@people-first/organisms';
import { RoleDatagridFactory } from './GridRole.model';
import { useBreadcrumb } from '@people-first/hooks';
import { toggleRoleStatus } from '@people-first/store';
import { confirmDialogConfigRegistry } from '@people-first/shared';
import { useConfirm } from '@people-first/molecules';
import { ACL } from './Role.resources';

const GridRole = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ConfirmDialog, confirm] = useConfirm();
    const { datagrid } = useDataGrid(() => {

        const toggleStatusHandler = async ({ detail }: any) => {
            const entity = { ...detail.row };
            return confirm(confirmDialogConfigRegistry.get(`${entity.status}`)()).then(
                () => toggleRoleStatus(entity)
            )
        }
        const editHandler = async ({ detail }: any) => navigate(`/role/${detail.row.id}`);
        const detailHandler = async ({ detail }: any) => navigate(`/role/details/${detail.row.id}`);

        return RoleDatagridFactory()
            .on("row.click", detailHandler)
            .on("edit", editHandler)
            .on("toggle.status", toggleStatusHandler)
            .refresh()
    });

    useBreadcrumb([
        [t('general.configurations'), ""],
        [t('general.role'), "/role"],
    ]);

    return (
        <>
            <ListPageTemplate
                button={
                    <ButtonWithPermission
                        require={ACL.create}
                        onClick={() => navigate('/role/new')}
                        variant="contained"
                    >
                        {t<string>("role.new_role")}
                    </ButtonWithPermission>
                }
                datagrid={<DataGrid model={datagrid} />}
            />
            <ConfirmDialog />
        </>
    );
};

export default GridRole;