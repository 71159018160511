import { UserDataSource } from "src/app/data-source";
import { DataGridModel, dataGridPaginationSearch } from "@people-first/organisms";
import { i18n } from "@people-first/i18n";
import { ACL } from "./User.resources";

export function UserDatagridFactory(): DataGridModel {

    const options = { sort: { name: 'asc' }, };

    return new DataGridModel(UserDataSource, options)
        .persistPagination(dataGridPaginationSearch('p'))
        .setColumns([
            {
                field: ' ',
                type: 'avatar',
                flex: 0.24,
                headerName: '',
                // todo: adicionar essas props na interface
                // filterable: false,
                // sortable: false,
                // disableColumnMenu: true,
            },
            {
                field: 'username',
                type: 'text.body2',
                headerName: `${i18n.t('user.column_username')}`,
                flex: 1.5,
                sortingOrder: ['asc', 'desc'],
            },
            {
                field: 'name',
                type: 'text.body2',
                headerName: `${i18n.t('user.column_name')}`,
                sortingOrder: ['asc', 'desc'],
                flex: 1.5
            },
            {
                field: 'role',
                type: 'text.body2',
                headerName: `${i18n.t('user.column_role')}`,
                sortingOrder: ['asc', 'desc'],
                flex: 1,
            },
            {
                field: 'org_level_access',
                type: 'text.body2',
                headerName: `${i18n.t('user.column_org_level_access')}`,
                flex: 1,
                sortingOrder: ['asc', 'desc'],
            },
            {
                field: 'status',
                type: 'status',
                headerName: `${i18n.t('resource.column_status')}`,
                flex: 0.8,
                sortingOrder: ['asc', 'desc'],
                valueOptions: [{ label: i18n.t('chip.active'), value: true }, { label: i18n.t('chip.inactive'), value: false }],
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: `${i18n.t('resource.column_actions')}`,
                flex: 1,
                actions: [
                    {
                        type: "btn.icon",
                        icon: "EditOutlined",
                        emit: "edit",
                        canAccess: ACL.edit,
                    },
                    {
                        type: "btn.status.toggle",
                        icon: (row: any) => row.status ? "NoAccountsOutlined" : "AccountCircleOutlined",
                        emit: "toggle.status",
                        canAccess: ACL.edit,
                    }
                ]
            },
        ])
}