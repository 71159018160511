import { FormControl, FormGroup, stringRequiredValidator } from "@people-first/molecules";

export function formLoginFactory() {

  const EID = new FormControl("")
    .setRequired()
    .setValidate(stringRequiredValidator);

  const password = new FormControl("")
    .setType('password')
    .setRequired()
    .setValidate(stringRequiredValidator);

  const displayPassword = new FormControl(false);

  const form = new FormGroup({
    EID,
    password,
    displayPassword,
  });

  return form;
}
