import React from "react";
import MuiText, { TypographyTypeMap } from "@mui/material/Typography";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const Text: OverridableComponent<TypographyTypeMap<{}, "span">> = (props: any) => {
    return <MuiText component={'span'} {...props} />;
};

export default Text;
export { Text };
