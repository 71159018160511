import * as React from 'react';
import { memo, useState } from 'react';

import { Box, DialogProps, SxProps, Theme, } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import Button from '../../atom/button/Button'
import Dialog from '../../atom/dialog/Dialog'
import Text from '../../atom/text/Text';
import DialogTitle from '../../atom/dialog-title/DialogTitle'
import DialogContentText from '../../atom/dialog-content-text/DialogContentText'
import DialogContent from '../../atom/dialog-content/DialogContent'
import DialogActions from '../../atom/dialog-actions/DialogActions'
import { ButtonProps } from "@mui/material/Button";

type TypeDialogAlert = {
    titleMessage?: string;
    contentMessage?: string | JSX.Element;
    isOpen: boolean;
    actions?: ButtonProps[],
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    onClose?: DialogProps['onClose'],
    transitionComponent?: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>,
    sx?: SxProps<Theme>
};


const DialogActionsComponent = ({ actions }: any) => {
    return (
        <DialogActions>
            {actions.map((action: any) => (
                <Button key={action.label} sx={{ marginTop: '0px' }} variant="text" size="medium" disableRipple {...action}>
                    {action.label}
                </Button>
            ))}
        </DialogActions>
    )
}

const DialogAlert = ({
    isOpen = false,
    titleMessage,
    contentMessage,
    actions,
    sx,
    onClose,
}: TypeDialogAlert) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            {titleMessage ? (<DialogTitle>{titleMessage}</DialogTitle>) : null}
            <DialogContent >
                {typeof contentMessage == 'string'
                    ? (
                        <DialogContentText sx={{ overflowWrap: 'break-word' }}>
                            {contentMessage}
                        </DialogContentText>
                    )
                    : contentMessage
                }
            </DialogContent>
            {actions ? <DialogActionsComponent actions={actions} /> : null}
        </Dialog >
    );
}

export default DialogAlert;

export function useConfirm(): [(() => JSX.Element | null), (options: any) => Promise<void>] {
    const [state, setState] = useState({
        active: false,
        title: "",
        content: "",
        actions: []
    })
    return [
        //dialog component 
        () => {
            const { active, title, content, actions } = state;
            return active
                ? (
                    <DialogAlert
                        isOpen={true}
                        titleMessage={title}
                        contentMessage={content}
                        actions={actions}
                    />
                )
                : null;
        },
        //confirm callback
        (options) => new Promise((resolve, reject) => {
            let { actions = [] } = options;
            const resolvers: { [key: string]: Function } = {
                "reject": reject,
                "accept": resolve,
            }

            actions = actions.map((action: any) => {
                return {
                    ...action, onClick: () => {
                        action.onClick?.();
                        setState({ ...state, active: false })
                        resolvers[action.target]!()
                    }
                }
            })

            setState({ ...options, actions, active: true })
        })
    ]
}


export { DialogAlert };