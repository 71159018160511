// todo: mover pra pasta correta
import axios, { } from 'axios'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router'
import * as yup from "yup";
import { Buffer } from 'buffer'

type TypeHandleImage = {
    event: React.SyntheticEvent & { currentTarget: HTMLInputElement },
    setPhotoFile: Function,
    setPhoto: Function,
    setAlertValue: Function,
    setIsLoadingButton?: Function,
}

export const HandleImage = ({ event, setPhotoFile, setPhoto, setIsLoadingButton, setAlertValue, }: TypeHandleImage) => {
    const { t } = useTranslation()

    const { currentTarget } = event

    if (setIsLoadingButton) setIsLoadingButton(true)

    const img: File = currentTarget.files![0]
    setPhotoFile(img)

    const reader = new FileReader()

    reader.onload = function (event) {
        const img64 = event.target!.result
        const image = new Image()

        if (typeof img64 === "string") {
            image.src = img64
        }

        const onloadImage = () => {
            if (image.width < 1080) {
                setPhoto(image.src)
            } else {
                setPhoto('')
                setAlertValue({
                    isOpen: true,
                    title: t("alert.error_title_unspecific"),
                    message: t("profile.error_message_upload_photo"),
                    severity: "error",
                })
            }
        }
        image.onload = onloadImage
    }
    if (img)
        reader.readAsDataURL(img)
    else return null
}

export function useStaticProps() {
    return useLoaderData()
}

/**@deprecated */
export const yupResolve = (rule: yup.BaseSchema) => {
    return (value: unknown): Error | string | null => {
        try {
            rule.validateSync(value);
            return null;
        } catch (error: unknown) {
            return (error as yup.ValidationError).errors.join("");
        }
    };
};


export const encodeQuery = (query: any): string => {
    return Buffer.from(JSON.stringify(query)).toString('base64')
}

export const decodeQuery = (query: string): any => {
    return JSON.parse(Buffer.from(query, 'base64').toString())
}

export const toPrivilegeKey = (privilege: string) => {
    return `privileges.${privilege.replace(':', '.')}`;
}