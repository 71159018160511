import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootInputAdornment: Components<Omit<Theme, 'components'>> = {
    MuiInputAdornment: {
        variants: [
            {
                props: { variant: 'outlined' },
                style: {
                    color: peopleFirst.shades.black.value,
                },
            },
        ],
    },
};

export default RootInputAdornment;
export { RootInputAdornment };
