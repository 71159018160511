import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
import TextFieldVariants from '../../atom/text/TextThemeVariants'

const { peopleFirst } = tokens;

const RootTypography: Components<Omit<Theme, 'components'>> = {
    MuiTypography: {
        variants: TextFieldVariants,
        styleOverrides: {},
    },
};

export default RootTypography;
export { RootTypography };
