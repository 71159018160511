import { AlertProps, Interpolation, Theme } from '@mui/material';
import { tokens } from '@people-first/design/tokens';

const { peopleFirst } = tokens;

type TypeAlert = {
    props: AlertProps;
    style: Interpolation<{ theme: Theme; }>;
};

const AlertThemeVariants: TypeAlert[] = [
    {
        props: { variant: 'filled', severity: 'warning' },
        style: {
            color: peopleFirst.shades.black.value,
            '& 	.MuiAlertTitle-root': {
                color: peopleFirst.shades.black.value,
            },
        },
    },
    {
        props: { variant: 'filled', severity: 'error' },
        style: {
            color: peopleFirst.shades.black.value,
            backgroundColor: peopleFirst.custom.error.value,
            '& 	.MuiAlertTitle-root': {
                color: peopleFirst.shades.black.value,
            },
        },
    },
];

export default AlertThemeVariants;
export { AlertThemeVariants };
