import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
const { peopleFirst } = tokens;

const RootAlertTheme: Components<Omit<Theme, 'components'>> = {
    MuiAlertTitle: {
        styleOverrides: {
            root: {
                color: peopleFirst.shades.white.value,
                fontWeight: 400,
            },
        },
    },
};

export default RootAlertTheme;
export { RootAlertTheme };
