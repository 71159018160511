import { booleanRequiredValidator, FormControl, FormGroup, SelectControl, stringRequiredValidator, TransferListControl } from "@people-first/molecules";
import { toPrivilegeKey } from "@people-first/shared";
import { createPrivilegeSuggest } from "@people-first/suggests";

export function formFactory(value?: any) {
    const name = new FormControl(value.name || "")
        .setLabel("role.form_name")
        .setPlaceholder("role.form_name")
        .setValidate(stringRequiredValidator)
        .setRequired()

    const status = new FormControl(typeof value.status === "boolean" ? value.status : true)
        .setType('switch')
        .setLabel("role.form_status")
        .setValidate(booleanRequiredValidator)
        .setRequired()

    const privileges = new TransferListControl(value.privileges || [])

        .setLabel("role.form_privileges")
        .setPlaceholder("role.form_privileges_placeholder")
        .setSelectionLabel("role.form_privileges_selection")
        .setSelectedLabel("role.form_privileges_selected")
        .setOptions(createPrivilegeSuggest())
        .setValidate((value: any) => Boolean(value.length) ? null : "array_empty")
        .setRequired();

    const form = new FormGroup({
        name,
        status,
        privileges,
    });

    if (value.id) form.addControls({ id: new FormControl(value.id) });

    return form;
}