import { Button } from '@people-first/atoms';
import { ListPageTemplate } from '@people-first/templates';
import { useNavigate } from 'react-router-dom';
import { DataGrid, useDataGrid } from '@people-first/organisms';
import { DataLoadDatagridFactory } from './LoadingDataGrid.model';
import { useBreadcrumb } from '@people-first/hooks';
import { t } from 'i18next';
import { tbread } from './LoadingDataCreate.resources';
import { withPermission } from '@people-first/shared';
import { ACL } from './LoadingData.resources';

const LoadingDataButton = withPermission(Button, ACL.create)

const DataLoadPage = () => {
  const navigate = useNavigate();
  const { datagrid } = useDataGrid(() => {
    return DataLoadDatagridFactory()
      .on("download", async () => void 0)
      .refresh()
  });

  useBreadcrumb([
    [t(tbread("grid")), "/loading-data"],
  ])

  return (
    <>
      <ListPageTemplate
        button={
          <LoadingDataButton onClick={() => navigate("/loading-data/create")} variant="contained">
            {t<string>(tbread("new_loading"))}
          </LoadingDataButton>
        }
        datagrid={<DataGrid model={datagrid} />}
      />
    </>
  );
};

export default DataLoadPage
