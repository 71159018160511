import React from "react";
import { FormControl } from "./FormControl";
import { FormFields } from "./FormFields";
import { FormGroup } from "./FormGroup";
import { FormContext } from "./FormContext";
import { FormArray } from "./FormArray";
import { Box } from "@people-first/atoms";
import { SxProps, Theme } from "@mui/material";

const rendered = (form: FormGroup<any, any>) => Object.values(form.getControls()).map((c) => {
  if (c instanceof FormControl || c instanceof FormArray) return (
    <span key={c.name}>{FormFields.render(c)}</span>
  );
  else return null;
});

export function FormView({ form, children, sx }: { form: FormGroup<any, any>; children?: any; sx?: SxProps<Theme> }) {
  return (
    <FormContext.Provider value={form}>
      <Box sx={sx}>
        {children ? children : rendered(form)}
      </Box>
    </FormContext.Provider>
  );
}