import { useTranslation } from 'react-i18next';
import Box from '../../atom/box/Box';
import Chip from '../../atom/chip/Chip';

type ChipStatusProps = {
    status: boolean
}

const statusMap = new Map<string, any>([
    ['true', {
        className: "active",
        label: 'chip.active',
    }],
    ['false', {
        className: "inactive",
        label: 'chip.inactive',
    }],
    ['undefined', {
        className: "",
        label: '',
    }]
])


const ChipStatus = ({ status }: ChipStatusProps) => {
    const { t } = useTranslation();
    const _status = String(status);
    const { label, className } = statusMap.get(_status);
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
            >
                {_status === 'undefined'
                    ? null
                    : <Chip
                        variant="filled"
                        className={className}
                        label={t(label)}
                    />
                }
            </Box>
        </>
    );
};
export { ChipStatus }
export default ChipStatus
