// import { useRef, useEffect } from "react";

import { FormGroup } from "./FormGroup";
import { useState } from "react"

export const useForm = <T = any,>(factory: () => FormGroup<any, any>): { form: FormGroup<any, any> } => {
    let [change, setChange] = useState<number>(0)
    let [form, setForm] = useState<FormGroup<any, any> | undefined>(undefined)

    if (!form) {
        form = factory();
        setForm(form);
        setChange(change++) // todo: sem isso ocorre um bug de inicialização do form
        form.valueChange(() => setChange(change++));
    }

    return { form };
}