import { Components, Theme } from '@mui/material';
import tokens from '../tokens.json';
import { SpacingText } from '../TextTheme';

const { peopleFirst } = tokens;

const RootFormLabel: Components<Omit<Theme, 'components'>> = {
    MuiFormLabel: {
        styleOverrides: {
            root: {
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                letterSpacing: SpacingText.spacing(2),
                color: peopleFirst.greys['darken-2'].value,
                paddingBottom: '5px'
            },
        },
    },
};

export default RootFormLabel;
export { RootFormLabel };
