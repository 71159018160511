import { Suggest } from "src/app/shared";
import { OrganizarionDataSource } from "@people-first/data-sources";

interface OrganizationOptions {
    label: string,
    value: string,
    id: string,
    caption?: string,
}

type DataSourceResultPromise<T> = (term: string) => Promise<{
    items: Array<T>;
    resultSizeEstimate: number;
}>

export function createOrganizationHandlerByQuery(queryFactory: (term: string) => any): DataSourceResultPromise<OrganizationOptions> {
    return async (term: string) => {
        const query = { items: queryFactory(term) };
        const options = {
            query,
            fields: "id,name",
            format: 'org.select',
            sort: { 'name': 'asc' },
            page: 0,
            itemsPerPage: 1000,
        };
        return OrganizarionDataSource.find(options)
            .catch(() => {
                return { items: [], resultSizeEstimate: 0, }
            })
            .then(({ items, resultSizeEstimate }) => {
                return {
                    items: items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                        id: item.id,
                        caption: item.path.reverse().map(({ name }: any) => name).join(' > '),
                    })),
                    resultSizeEstimate,
                }
            });
    }
}

export const createOrganizationgSuggest = () => {
    return new Suggest(
        createOrganizationHandlerByQuery(
            (term) => [['name', 'contains', term]],
        ),
        { fetch: "once" }
    );
}