import { createTheme, ThemeOptions } from '@mui/material/styles';
import tokens from './tokens.json';

const { peopleFirst } = tokens;
const customPalette = createTheme().palette;
const theme: ThemeOptions = {
    palette: {
        primary: {
            main: peopleFirst.custom.primary.value,
            contrastText: peopleFirst.shades.white.value,
        },
        secondary: {
            main: peopleFirst.custom.secondary.value,
            contrastText: peopleFirst.greys.grey.value,
        },
        error: {
            main: peopleFirst.custom.error.value,
        },
        info: {
            main: peopleFirst.custom.info.value,
        },
        success: {
            main: peopleFirst.custom.success.value,
        },
        warning: {
            main: peopleFirst.custom.warning.value,
        },
        accent_one: customPalette.augmentColor({
            color: {
                main: peopleFirst.custom['accent-one'].value,
            },
        }),
        accent_two: customPalette.augmentColor({
            color: {
                main: peopleFirst.custom['accent-two'].value,
            },
        }),
        accent_three: customPalette.augmentColor({
            color: {
                main: peopleFirst.custom['accent-three'].value,
            },
        }),
        black: customPalette.augmentColor({
            color: {
                main: peopleFirst.shades.black.value,
            },
        }),
        lighten_1: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['lighten-1'].value,
            },
        }),
        lighten_2: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['lighten-2'].value,
            },
        }),
        lighten_3: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['lighten-3'].value,
            },
        }),
        lighten_4: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['lighten-4'].value,
            },
        }),
        lighten_5: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['lighten-5'].value,
            },
        }),
        grey_1: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys.grey.value,
            },
        }),
        darken_1: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['darken-1'].value,
            },
        }),
        darken_2: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['darken-2'].value,
            },
        }),
        darken_3: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['darken-3'].value,
            },
        }),
        darken_4: customPalette.augmentColor({
            color: {
                main: peopleFirst.greys['darken-4'].value,
            },
        }),
    },
};

export default theme;
export { theme };
