import { encodeQuery } from "@people-first/shared";
import { store } from "@people-first/store";
import { DataSource } from "./types";

const operatorMap = new Map<string, string>([
    ['contains', '$contains'],
    ['equals', '$eq'],
    ['startsWith', '$startsWith'],
    ['endsWith', '$endsWith'],
    ['isEmpty', '$empty'],
    ['startsWith', '$startsWith'],
    ['isNotEmpty', '!$empty'],
    ['is', "$anyOf"],
    ['not', "$anyOf"],
]);

export const evalRespose = (dispatchResult: any) => {
    return dispatchResult.then(({ payload }: any) => payload)
}

export const createBaseDataSourceByApi = (
    name: string, {
        findAction,
        getAction,
        saveAction,
    }: any
): DataSource<any> => {
    return {
        async remove() { },
        find({ query, fields, page, itemsPerPage, sort, format, ...params }) {
            let _query;

            if (query?.items?.length) {
                const merged = query.items.reduce((m: any, [prop, operator, value]: any) => {
                    return {
                        ...m,
                        [prop]: { [operatorMap.get(operator)!]: value }
                    }
                }, {});
                _query = encodeQuery(merged)
            }

            const _sort = sort ? Object.entries(sort).map(([prop, value]) => value === 'desc' ? `-${prop}` : prop).join(',') : '';

            return evalRespose(
                store.dispatch(findAction({
                    page,
                    itemsPerPage,
                    fields,
                    format,
                    sort: _sort,
                    q: _query,
                    params,
                }))
            );
        },

        get({ id, ...params }: any) {
            return evalRespose(
                store.dispatch(getAction({ id, ...params }))
            );
        },

        save(entity: any): Promise<any> {
            return evalRespose(
                store.dispatch(saveAction({ entity }))
            );
        }
    }
}