import { api } from "@people-first/api";
import { createEntityApiAction } from "../utils";

export const [
    updateLoadingDataGridAction,
    saveLoadingDataAction,
    getLoadingDataAction,
] = createEntityApiAction(
    'LoadingData',
    api.loadingData,
    {
        alerts: {
            save: [
                "alert.success_message_generic",
                "menu.alert_content_new",
                "alert.success_message_update",
            ]
        }
    }
)
