import { createReducer } from '@reduxjs/toolkit';
import { getUserAction, saveUserAction, toggleUserStatusAction, updateUserGridAction } from '../actions';

export type TypeDialogLogout = {
  blocked: boolean,
}

const initialState: TypeDialogLogout = {
  blocked: false
}

export const userReducer = createReducer<TypeDialogLogout>(initialState, {
  [getUserAction.fulfilled.type]: (state) => ({ ...state, blocked: true }),
  [saveUserAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [updateUserGridAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
  [toggleUserStatusAction.fulfilled.type]: (state) => ({ ...state, blocked: false }),
})